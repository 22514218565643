import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../../../styledComponent/Inputs/Input";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import LoadingBox from "../../../DialogBox/LoadingBox";
import Select from "../../../../styledComponent/Inputs/Select";
import { RiDeleteBin6Fill } from "react-icons/ri";
import styled from 'styled-components';

export default function Income44AD({
  formsData,
  businessType,
  handleCallback,
  optionData,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [businessNatureOption, setBusinessNatureOption] = useState([]);
  const [activeBusinessType, setActiveBusinessType] = useState("");
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    grossRevenueCashNonDigital: Yup.number().required(
      strings.validation_message.required
    ),
    grossRevenueOnlineDigital: Yup.number().required(
      strings.validation_message.required
    ),
    profitReceivedCash: Yup.number().required(
      strings.validation_message.required
    ),
    profitReceivedOnline: Yup.number().required(
      strings.validation_message.required
    ),
    professionalIncome44AD: Yup.array().of(
      Yup.object().shape({
        businessType: Yup.string().required(
          strings.validation_message.required
        ),
        natureOfBusiness: Yup.string().required(
          strings.validation_message.required
        ),
        nameOfBusinessCompany: Yup.string().required(
          strings.validation_message.required
        ),
      })
    ),
  });

  useEffect(() => {
    if (activeBusinessType) {
      setBusinessNatureOption(
        optionData?.businessType.filter((i) => i.id == activeBusinessType)[0]
          .subCategory || []
      );
    }
  }, [activeBusinessType]);

  useEffect(() => {
    setLoader(true);
    setFormData({
      itrId: ItrData.id,
      grossRevenueCashNonDigital: formsData?.grossRevenueCashNonDigital || "",
      grossRevenueOnlineDigital: formsData?.grossRevenueOnlineDigital || "",
      profitReceivedCash: formsData?.profitReceivedCash || "",
      profitReceivedOnline: formsData?.profitReceivedOnline || "",
      professionalIncome44AD: formsData?.professionalIncome44AD?.map((i) => ({
        businessType: i.businessType?.id || i.businessType || "",
        natureOfBusiness: i.natureOfBusiness?.id || i.natureOfBusiness || "",
        nameOfBusinessCompany: i.nameOfBusinessCompany || "",
        description: i.description || "",
      })) || [
        {
          businessType: "",
          natureOfBusiness: "",
          nameOfBusinessCompany: "",
          description: "",
        },
      ],
    });
    setLoader(false);
  }, [formsData]);

  console.log("formData ======>", formData);
  return (
    <Root>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleCallback(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <div>
            <StyleFormBox>
              <h4>
                {strings.income.provide_details_of_profession_or_business}
              </h4>
              <p>{strings.income.first_select_profession_type}</p>
              <FieldArray name="professionalIncome44AD">
                {({ insert, remove, push }) => (
                  <div>
                    {values?.professionalIncome44AD?.map((item, index) => {
                      return (
                        <StyleFormBox key={index}>
                          <button
                            className="delete_button mb-2 block ml-auto"
                            onClick={() => remove(index)}
                          >
                            <RiDeleteBin6Fill />
                          </button>

                          <div className="flex_input my-2">
                            <label>{strings.income.business_type} *</label>
                            <Select
                              name={`professionalIncome44AD.${index}.businessType`}
                              error={
                                errors?.professionalIncome44AD &&
                                errors?.professionalIncome44AD[index]
                                  ?.businessType
                              }
                              value={item?.businessType}
                              onChange={(e) => {
                                setActiveBusinessType(e.target.value);
                                handleChange(e);
                              }}
                            >
                              <option value="" disabled>
                                {strings.label.choose_option}
                              </option>
                              {optionData?.businessType?.map((i, ix) => (
                                <option value={i.id} key={ix}>
                                  {i.name}
                                </option>
                              ))}
                            </Select>
                          </div>

                          <div className="flex_input my-2">
                            <label>{strings.income.nature_of_business} *</label>
                            <Select
                              name={`professionalIncome44AD.${index}.natureOfBusiness`}
                              error={
                                errors?.professionalIncome44AD &&
                                errors?.professionalIncome44AD[index]
                                  ?.natureOfBusiness
                              }
                              value={item?.natureOfBusiness || ""}
                              onChange={handleChange}
                            >
                              <option value="">
                                {strings.label.choose_option}
                              </option>
                             
                              {optionData?.businessType
                                ?.filter((i) => i.id == item?.businessType)[0]
                                ?.subCategory?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                            </Select>
                          </div>

                          <div className="flex_input my-2">
                            <label>
                              {strings.income.name_business_company} *
                            </label>
                            <Input
                              name={`professionalIncome44AD.${index}.nameOfBusinessCompany`}
                              value={item?.nameOfBusinessCompany || ""}
                              error={
                                errors?.professionalIncome44AD &&
                                errors?.professionalIncome44AD[index]
                                  ?.nameOfBusinessCompany
                              }
                              onChange={handleChange}
                            />
                          </div>

                          <div className="flex_input mt-4">
                            <label>{strings.label.description}</label>
                            <Input
                              inputType={"message"}
                              name={`professionalIncome44AD.${index}.description`}
                              value={item?.description || ""}
                              onChange={handleChange}
                            />
                          </div>
                        </StyleFormBox>
                      );
                    })}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            businessType: "",
                            natureOfBusiness: "",
                            nameOfBusinessCompany: "Drugs Smuggling Pvt. Ltd",
                            description: "We are best in the illegal business.",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </div>
                )}
              </FieldArray>

              <hr className="my-4" />
              <h4>{strings.income.total_gross_revenue_sale}</h4>

              <div className="flex_input my-2">
                <label>{strings.income.revenue_recived_via_online} </label>
                <div>
                  <Input
                    inputType={"currency"}
                    name={"grossRevenueOnlineDigital"}
                    error={errors.grossRevenueOnlineDigital}
                    value={values.grossRevenueOnlineDigital}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input my-2">
                <label>{strings.income.revenue_received_via_cash} </label>
                <div>
                  <Input
                    inputType={"currency"}
                    name={"grossRevenueCashNonDigital"}
                    error={errors.grossRevenueCashNonDigital}
                    value={values.grossRevenueCashNonDigital}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input my-2 ">
                <h4>{strings.income.total_gross_revenue}</h4>
                <h4 className="" style={{ flex: 0 }}>
                  {Number(values.grossRevenueCashNonDigital) +
                    Number(values.grossRevenueOnlineDigital) +
                    0}
                </h4>
              </div>
              <hr className="my-4" />

              <h4>{strings.income.total_income_profit_your_calculation}</h4>

              <div className="flex_input my-2">
                <label>{strings.income.from_online_digital_modes} </label>
                <div>
                  <Input
                    inputType={"currency"}
                    name={"profitReceivedOnline"}
                    error={errors.profitReceivedOnline}
                    value={values.profitReceivedOnline}
                    onChange={handleChange}
                  />
                  <span className="note_message">
                    {strings.income.should_at_least_6_per}
                  </span>
                </div>
              </div>

              <div className="flex_input my-2">
                <label>{strings.income.revenue_received_via_cash} </label>
                <div>
                  <Input
                    inputType={"currency"}
                    name={"profitReceivedCash"}
                    error={errors.profitReceivedCash}
                    value={values.profitReceivedCash}
                    onChange={handleChange}
                  />
                  <span className="note_message">
                    {strings.message.should_at_least_8per_gross_revenue}
                  </span>
                </div>
              </div>

              <div className="flex_input my-2 ">
                <h4>{strings.balance_sheet.total_income}</h4>
                <h4 className="" style={{ flex: 0 }}>
                  {Number(values.profitReceivedOnline) +
                    Number(values.profitReceivedCash) +
                    0}
                </h4>
              </div>
            </StyleFormBox>

            <button className="primary_button my-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </Root>
  );
}
const Root = styled.section`
.error.curr {
    bottom: -18px;
}`
