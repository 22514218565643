import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Formik } from "formik";
import Input from "../../../styledComponent/Inputs/Input";
import * as Yup from "yup";
import Select from "../../../styledComponent/Inputs/Select";
import { getCitiesByIdAction } from "../../../redux/platformSetting/action";
import LoadingBox from "../../DialogBox/LoadingBox";
import Phone from "../../../styledComponent/Inputs/Phone";
import { toast } from "react-toastify";
import { formatAadhaarEnrolmentNo } from "../../../utils/helpers";
import AccordianBox from "../../../styledComponent/Form/AccordianBox";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import {
  getPersonalInfoAction,
  setPersonalInfoAction,
} from "../../../redux/itr/action";

export default function PersonalInfo({ handleNext }) {
  const [formData, setFormData] = useState({});
  const [citiesList, setCitiesList] = useState([]);
  const [activeState, setActiveState] = useState(1);
  const [empCategorys, setEmpCategorys] = useState([]);
  const [assessmentYear, setAssessmentYear] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addBankBox, setAddBankBox] = useState(false);

  const { strings, theme, states } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    assessmentYear: Yup.string().required(strings.validation_message.required),
    firstName: Yup.string().required(strings.validation_message.required),
    lastName: Yup.string().required(strings.validation_message.required),
    DOB: Yup.string().required(strings.validation_message.required),
    empCategory: Yup.string().required(strings.validation_message.required),
    email: Yup.string()
      .required(strings.validation_message.required)
      .email(strings.validation_message.invalid_email),
    mobileNo: Yup.string().required(strings.validation_message.required),
    aadhaarCardNo: Yup.string()
      .required("Aadhaar card number is required")
      .matches(
        /^[2-9]{1}[0-9]{3}-[0-9]{4}-[0-9]{4}$/,
        "Invalid Aadhaar card number format"
      )
      .max(28, "Aadhaar card number must be at most 16 characters long"),
    PAN: Yup.string()
      .required(strings.validation_message.required)
      .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, strings.validation_message.invalid_pan),
    address: Yup.object().shape({
      flatDoorNo: Yup.string().required(strings.validation_message.required),
      area: Yup.string().required(strings.validation_message.required),
      city: Yup.string().required(strings.validation_message.required),
      state: Yup.string().required(strings.validation_message.required),
      pinCode: Yup.string().required(strings.validation_message.required).matches(
        /^[1-9][0-9]{5}$/, strings.validation_message.invalid_pin
      ),
      emailAddress: Yup.string().email(
        strings.validation_message.invalid_email
      ),
    }),
  });

  const handleFormSubmit = async (data) => {
    let updateData = {
      ...data,
      mobileNo: data.mobileNo?.slice(data.countryCodeMobile.length),
      itrId: ItrData.id,
      address: {
        ...data.address,
        mobileNo: data.address.mobileNo?.slice(
          data.address?.countryCodeMobile?.length
        ),
      },
    };
    if (updateData?.aadhaarCardNo?.length > 12) {
      delete updateData.aadhaarEnrollmentNo;
    }
    setLoading(true);
    dispatch(
      setPersonalInfoAction(updateData, (e) => {
        if (e.status === 200) {
          setLoading(false);
          toast.success("successfully upload data");
          handleNext();
        } else {
          setLoading(false);
        }
      })
    );
  };

  // API : Get citi by state id
  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };

  //  API : Get api for personal info
  const getPersonalInfoApi = () => {
    setLoading(true);
    dispatch(
      getPersonalInfoAction(ItrData.id, (e) => {
        let info = e?.data?.personalInfo;
        setFormData({
          assessmentYear: info?.assessmentYear || "",
          firstName: info?.firstName || "",
          middleName: info?.middleName || "",
          lastName: info?.lastName || "",
          PAN: info?.PAN || "",
          DOB: info?.DOB || "",
          empCategory: info?.empCategory?.id || "",
          aadhaarEnrollmentNo: info?.aadhaarEnrollmentNo || "",
          aadhaarCardNo: info?.aadhaarCardNo || "",
          email: info?.email || "",
          countryCodeMobile: info?.countryCodeMobile || "+91",
          mobileNo: (info?.countryCodeMobile || "+91") + (info?.mobileNo || ""),
          address: {
            flatDoorNo: info?.address?.flatDoorNo || "",
            premiseName: info?.address?.premiseName || "",
            street: info?.address?.street || "",
            area: info?.address?.area || "",
            city: info?.address?.city?.id,
            state: info?.address?.state?.id || 1,
            country: "101",
            pinCode: info?.address?.pinCode || "",
            countryCodeMobile: info?.address?.countryCodeMobile || "+91",
            mobileNo: info?.address?.mobileNo
              ? info?.address?.countryCodeMobile + info?.address?.mobileNo
              : "",
            emailAddress: info?.address?.emailAddress || "",
            landlineNumber: info?.address?.landlineNumber || "",
            stdCodeLandlineNumber: info?.address?.stdCodeLandlineNumber || "",
          },
        });

        if (e.status === 200) {
          setAssessmentYear(e.data.defaultAssessmentYear);
          setEmpCategorys(e.data.defaultEmpCategory);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    getPersonalInfoApi();
  }, []);

  console.log("ItrData---------m---", ItrData);
  return (
    <Root>
      <LoadingBox open={loading} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues, setFieldValue }) => (
          <div>
            <h4 className="mb-5" style={{ color: theme.primary_txt }}>
              {strings.tax.assessment_year} *
            </h4>

            <StyleFormBox>
              <Select
                name={"assessmentYear"}
                error={errors?.assessmentYear}
                value={values?.assessmentYear}
                onChange={handleChange}
              >
                <option value="" disabled>
                  {strings.label.choose_option}
                </option>
                {assessmentYear?.map((i, ix) => (
                  <option value={i.id} key={ix}>
                    {i.name}
                  </option>
                ))}
              </Select>
            </StyleFormBox>

            {/* Permanent Information */}
            <AccordianBox
              title={strings.tax.permanent_information}
              description={
                strings.tax.please_provide_all_info_as_per_your_govt_id
              }
            >
              <div className="flex_input">
                <div>
                  <label>{strings.label.first_name} *</label>
                  <Input
                    name={"firstName"}
                    placeholder={strings.label.first_name}
                    error={errors.firstName}
                    value={values.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.label.middle_name}</label>
                  <Input
                    name={"middleName"}
                    placeholder={strings.label.middle_name}
                    error={errors.middleName}
                    value={values.middleName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.label.last_name} *</label>
                  <Input
                    name={"lastName"}
                    placeholder={strings.label.last_name}
                    error={errors.lastName}
                    value={values.lastName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.label.date_of_birth} *</label>
                  <Input
                    name={"DOB"}
                    type={"date"}
                    error={errors.DOB}
                    value={values.DOB}
                    format={"YYYY-MM-DD"}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <label>{strings.label.nature_of_employment} *</label>
                <Select
                  name={"empCategory"}
                  error={errors.empCategory}
                  value={values.empCategory}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {empCategorys?.map((i, ix) => (
                    <option value={i.id} key={ix}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </div>
            </AccordianBox>

            {/* Identification & Contact details  */}
            <AccordianBox
              title={strings.tax.identification_and_contact_Details}
              description={
                strings.tax
                  .to_efile_your_returns_please_provide_your_aadhaar_pan
              }
            >
              <div className="flex_input">
                <div>
                  <label>{strings.label.aadhar_number} * </label>
                  <Input
                    inputType={"aadhaar"}
                    name={"aadhaarCardNo"}
                    placeholder={strings.placeholder.enter_12_digit}
                    error={errors.aadhaarCardNo}
                    value={values.aadhaarCardNo}
                    onChange={(e) => {
                      if (e.target.value.length <= 14) {
                        handleChange(e);
                      }
                    }}
                  />
                </div>
                <span className="p-1 mt-6" style={{ flex: "0" }}>
                  Or
                </span>
                <div>
                  <label>{strings.label.aadhaar_enrollement_no}</label>
                  <Input
                    name={"aadhaarEnrollmentNo"}
                    placeholder={strings.placeholder.enter_28_digit}
                    error={errors.aadhaarEnrollmentNo}
                    // value={values.aadhaarEnrollmentNo}
                    value={formatAadhaarEnrolmentNo(
                      values.aadhaarEnrollmentNo || ""
                    )}
                    onChange={(e) => {
                      if (e.target.value.length <= 28) {
                        handleChange(e);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.label.pan} *</label>
                  <Input
                    name={"PAN"}
                    className={"uppercase"}
                    placeholder={strings.placeholder.enter_10_digit}
                    error={errors.PAN}
                    value={values.PAN}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setFieldValue("PAN", e.target.value.toUpperCase());
                      }
                    }}
                  />
                </div>
                <div>
                  <label>{strings.label.email} *</label>
                  <Input
                    name={"email"}
                    placeholder={strings.placeholder.email_address}
                    error={errors.email}
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.label.phone_number} *</label>
                  <Input
                    inputType={"phone"}
                    name={"mobileNo"}
                    placeholder={strings.placeholder.enter_10_digit}
                    error={errors.mobileNo}
                    value={values.mobileNo}
                    onChange={(phoneNumber, country) =>
                      setValues({
                        ...values,
                        mobileNo: phoneNumber.replace(/\D/g, ""),
                        countryCodeMobile: country.dialCode?.replace(/\D/g, ""),
                      })
                    }
                  />
                  {/* <Phone
                      name={"mobileNo"}
                      placeholder={strings.placeholder.enter_10_digit}
                      error={errors.mobileNo}
                      value={values.mobileNo}
                      countryCode={values.countryCodeMobile}
                      onChange={(phoneNumber, country) =>
                        setValues({
                          ...values,
                          mobileNo: phoneNumber.replace(/\D/g, ""),
                          countryCodeMobile: country.dialCode.replace(
                            /\D/g,
                            ""
                          ),
                        })
                      }
                    /> */}
                </div>
                <div></div>
              </div>

              <h4 className="mt-3">{strings.tax.additional_information}</h4>
              <p>{strings.tax.leave_empty_if_you_dont_have}</p>

              <div className="flex_input">
                <div>
                  <label>{strings.label.secandary_mobile_number}</label>

                  <Phone
                    name={"address.mobileNo"}
                    placeholder={strings.placeholder.enter_10_digit}
                    error={errors.address && errors.address.mobileNo}
                    value={values.address && values.address.mobileNo}
                    countryCode={values.countryCodeMobile}
                    onChange={(phoneNumber, country) =>
                      setValues({
                        ...values,
                        address: {
                          ...values.address,
                          mobileNo: phoneNumber.replace(/\D/g, ""),
                          countryCodeMobile: country.dialCode?.replace(
                            /\D/g,
                            ""
                          ),
                        },
                      })
                    }
                  />
                </div>
                <div>
                  <label>{strings.label.secandary_email_address}</label>

                  <Input
                    name={"address.emailAddress"}
                    placeholder={strings.placeholder.email_address}
                    error={errors.address && errors.address.emailAddress}
                    value={values.address && values.address.emailAddress}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.label.landline_number} *</label>

                  <Phone
                    name={"address.landlineNumber"}
                    placeholder={strings.placeholder.enter_10_digit}
                    error={errors.address && errors.address.landlineNumber}
                    value={values.address && values.address.landlineNumber}
                    countryCode={values.countryCodeMobile}
                    onChange={(phoneNumber, country) => {
                      handleChange({
                        target: {
                          name: "address.landlineNumber",
                          value: phoneNumber,
                        },
                      });
                    }}
                  />
                </div>
                <div></div>
              </div>
            </AccordianBox>

            {/* Form Identification & Contact details */}
            <AccordianBox
              title={strings.tax.your_address}
              description={
                strings.tax
                  .you_can_provide_either_your_current_address_or_permanent_address
              }
            >
              <div className="flex_input">
                <div>
                  <label>
                    {strings.address.flat} / {strings.address.door_no} *
                  </label>
                  <Input
                    name={"address.flatDoorNo"}
                    placeholder={`${strings.address.flat} / ${strings.address.door_no}`}
                    error={errors.address && errors.address.flatDoorNo}
                    value={values.address && values.address.flatDoorNo}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.address.premise_name}</label>
                  <Input
                    name={"address.premiseName"}
                    placeholder={strings.address.premise_name}
                    error={errors.address && errors.address.premiseName}
                    value={values.address && values.address.premiseName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>
                    {strings.address.road} / {strings.address.street}
                  </label>
                  <Input
                    name={"address.street"}
                    placeholder={`${strings.address.road} / ${strings.address.street}`}
                    error={errors.address && errors.address.street}
                    value={values.address && values.address.street}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.address.area_locality} *</label>
                  <Input
                    name={"address.area"}
                    placeholder={strings.address.area_locality}
                    error={errors.address && errors.address.area}
                    value={values.address && values.address.area}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.address.pincode} *</label>
                  <Input
                    name={"address.pinCode"}
                    type={"number"}
                    placeholder={strings.address.pincode}
                    error={errors.address && errors.address.pinCode}
                    value={values.address && values.address.pinCode}
                    onChange={(e) => {
                      if (e.target.value.length < 7) {
                        handleChange(e);
                      }
                    }}
                  />
                </div>
                <div>
                  <label>{strings.address.state} *</label>
                  <Select
                    name="address.state"
                    error={errors.address && errors.address.state}
                    value={values.address && values.address.state}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        address: {
                          ...values.address,
                          state: e.target.value,
                          city: "",
                        },
                      });
                      setActiveState(Number(e.target.value));
                    }}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {states?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.address.city} *</label>
                  <Select
                    name={"address.city"}
                    error={errors.address && errors.address.city}
                    value={values.address && values.address.city}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>
                    {citiesList?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div></div>
              </div>
            </AccordianBox>
            {/* Your Address */}

            {/* Form Link your PAN*/}

            {/* <AccordianBox
              title={strings.tax.pan_details}
              description={strings.tax.pan_linking_is_mandatory_requirement}
            >
              <div className="flex_input">
                <div>{strings.label.name}</div>
                <div>Rahul Singh</div>
              </div>
              <div className="flex_input">
                <div>{strings.label.date_of_birth}</div>
                <div>27/01/2000</div>
              </div>
              <div className="flex_input">
                <div>{strings.label.pan_number}</div>
                <div>DRPUTB755865GJ</div>
              </div>
              <div className="flex_input">
                <div>{strings.label.father_name}</div>
                <div>Mohan Lal</div>
              </div>
            </AccordianBox> */}

            {/* Add Bank Details section */}
            {/* <StyleFormBox>
              <div className="flex-1 flex items-center justify-between">
                <h4 style={{ color: theme.primary_txt }}>
                  {strings.tax.add_bank_details}
                </h4>

                <button
                  className="add_button"
                  onClick={() => {
                    setAddBankBox(true);
                  }}
                >
                  {strings.button.add}
                </button>
              </div>
            </StyleFormBox> */}

            <button className="primary_button mt-5" onClick={handleSubmit}>
              {strings.button.save_and_continue}
            </button>
          </div>
        )}
      </Formik>

      {/* dialog box for adding bank details */}
      {/* <AddBankDetails
        open={addBankBox}
        handleClose={() => {
          setAddBankBox(false);
        }}
        handleCallback={() => {}}
      /> */}

      {/* <LoadingBox open={loading} />sss */}
    </Root>
  );
}
const Root = styled.section``;
