import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import FormDialogBox from "../../../../DialogBox/FormDialogBox";
import RemunerationPartnershipFirms from "./RemunerationPartnershipFirms";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import AccountMaintained from "./AccountMaintained";
import SpeculativeIncome from "./SpeculativeIncome";
import ScheduleBp from "./ScheduleBp";
import AdditionProfitLossAccount from "./AdditionProfitLossAccount";
import AuditInformation from "./AuditInformation";
import TradingConcern from "./TradingConcern";
import ManufactureRowMaterials from "./ManufactureRowMaterials";
import { toast } from "react-toastify";
import {
  deleteAuditorDetailsAction,
  deleteBooksAccountNotMaintained,
  deleteManufacturingConcernAction,
  deleteRemunerationAction,
  deleteScheduleBP,
  deleteScheduleOIAction,
  deleteSpeculativeIncome,
  deleteTradingConcernAction,
  getProfessionalBusiness3,
} from "../../../../../redux/itr/action";
import { addOpacity } from "../../../../../utils/helpers";
import ScheduleOI from "./ScheduleOI";
import LoadingBox from "../../../../DialogBox/LoadingBox";

export default function OtherBusinessIncome() {
  const [incomeData, setIncomeData] = useState({});
  const [formData, setFormData] = useState({});
  const { ItrData } = useSelector((s) => s.itr);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [remunerationForm, setRemunerationForm] = useState(false);
  const [accountMaintainedForm, setAccountMaintainedForm] = useState(false);
  const [profitLossForm, setProfitLossForm] = useState(false);
  const [speculativeForm, setSpeculativeForm] = useState(false);
  const [scheduleBpForm, setScheduleBpForm] = useState(false);
  const [scheduleOIForm, setScheduleOIForm] = useState(false);
  const [auditInformationForm, setAuditInformationBox] = useState(false);
  const [tradingConcernForm, setTradingConcernForm] = useState(false);
  const [manufactureRowMaterialForm, setManufactureRowMaterialForm] =
    useState(false);
  const [manufactureFinishedProductForm, setManufactureFinishedProductForm] =
    useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);

  const dispatch = useDispatch();

  const getProfessionalIncomeApi = async () => {
    setLoading(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness3(parm, (e) => {
        if (e.status == 200) {
          const { professionalBusiness, ...data } = e.data;
          setIncomeData(data);
          setFormData(professionalBusiness);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteProfessionalIncomeDataApi = async (id, type) => {
    setLoading(true);
    if (type == "remuneration") {
      dispatch(
        deleteRemunerationAction({ id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              remuneration: formData.remuneration.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "booksOfAccountNotMaintained") {
      dispatch(
        deleteBooksAccountNotMaintained({ itrId: ItrData.id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              booksOfAccountNotMaintained:
                formData.booksOfAccountNotMaintained.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "speculative") {
      dispatch(
        deleteSpeculativeIncome({ itrId: ItrData.id, id: id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              speculative: formData.speculative.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "scheduleBP") {
      dispatch(
        deleteScheduleBP({ itrId: ItrData.id, id: id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              scheduleBP: formData.scheduleBP.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "auditorDetails") {
      dispatch(
        deleteAuditorDetailsAction({ id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              auditorDetails: formData.auditorDetails.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "tradingConcern") {
      dispatch(
        deleteTradingConcernAction({ id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              tradingConcern: formData.tradingConcern.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "manufacturingConcern") {
      dispatch(
        deleteManufacturingConcernAction({ id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              manufacturingConcern: formData.manufacturingConcern.filter(
                (i) => i.id != id
              ),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "scheduleOI") {
      dispatch(
        deleteScheduleOIAction({ id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              scheduleOI: {},
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    }
  };

  useEffect(() => {
    getProfessionalIncomeApi();
  }, []);

  return (
    <div>
       <LoadingBox open={loading} />
      {/* ---------- Remuneration from Partnership Firms ---------- */}
      <StyleFormBox>
        <div className="flex items-center justify-between">
          <h4>{strings.income.remuneration_from_partnership_firms}</h4>
          <button
            className="add_button"
            onClick={() => setRemunerationForm(true)}
          >
            {strings.button.add}
          </button>
        </div>
        <p>{strings.income.partner_in_firm_remuneration_details}</p>
        <hr />
        <div className="mt-4">
          {formData?.remuneration?.map((item) => (
            <div
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <p>Name of Firm : {item?.nameOfPartnershipFirm}</p>
                <p>Pan of Firm : {item?.panOfPartnershipFirm}</p>
                <p>Remuneration : {item?.remunerationFromFirm}</p>
              </div>

              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    setRemunerationForm(true);
                    setEditData(item);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(item?.id, "remuneration")
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          ))}
        </div>
      </StyleFormBox>

      {/* ---------- Books of Account are Not Maintained ---------- */}
      <StyleFormBox>
        <div className="flex items-center justify-between">
          <h4>{strings.income.book_of_account_not_maintained}</h4>
          <button
            className="add_button"
            onClick={() => setAccountMaintainedForm(true)}
          >
            {strings.button.add}
          </button>
        </div>
        <p>{strings.income.business_gross_receipt_less_than_5_lakhs}</p>

        <hr />
        <div className="mt-4">
          {formData?.booksOfAccountNotMaintained?.map((item) => (
            <div
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <p>
                  Regular Business (Books of Accounts NOT maintained) :{" "}
                  {item?.totalProfit}
                </p>
              </div>

              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    setAccountMaintainedForm(true);
                    setEditData(item);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(
                      item?.id,
                      "booksOfAccountNotMaintained"
                    )
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          ))}
        </div>
      </StyleFormBox>

      {/* ---------- Speculative Income-------- */}
      <StyleFormBox>
        <div className="flex items-center justify-between">
          <h4>{strings.income.speculative_income}</h4>

          <button
            className="add_button"
            onClick={() =>
              formData?.speculative.length > 0
                ? toast.error("You cant add more than one income")
                : setSpeculativeForm(true)
            }
          >
            {strings.button.add}
          </button>
        </div>
        <p>{strings.income.section_for_intraday_trading}</p>

        <hr />
        <div className="mt-4">
          {formData?.speculative?.map((item) => (
            <div
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <p>
                  Speculation Business (for e.g. Intraday trading, etc.) :{" "}
                  {Number(item?.grossProfit) - Number(item?.expenses)}
                </p>
              </div>

              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    setSpeculativeForm(true);
                    setEditData(item);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(item?.id, "speculative")
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          ))}
        </div>
      </StyleFormBox>

      {/* ---------- Schedule BP --------- */}
      <StyleFormBox>
        <h4>{strings.income.financial_statements_schedules}</h4>
        <hr className="mb-3" />
        <div className="flex items-center justify-between mt-4">
          <h4>{strings.income.schedule_bp}</h4>
          <div className="text-right">
            <button
              className="add_item_button"
              onClick={() => setProfitLossForm(!profitLossForm)}
            >
              {profitLossForm
                ? " Close Other Sections"
                : " Open Other Sections"}
            </button>
            <button
              className="add_button"
              onClick={() => setScheduleBpForm(true)}
            >
              {strings.button.add}
            </button>
          </div>
        </div>
        <p>
          {strings.income.adjustment_income_from_sources_other_than_business}
        </p>

        <div className="mt-4">
          {formData?.scheduleBP?.map((item) => (
            <div
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <p>
                  {" "}
                  Speculation Business (for e.g. Intraday trading, etc.) :{" "}
                  {item?.totalProfitAndGainsOfBusiness}
                </p>
              </div>

              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    setScheduleBpForm(true);
                    setEditData(item);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(item?.id, "scheduleBP")
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          ))}
        </div>

        {profitLossForm && (
          <>
            <h3> Other Sections</h3>
            <AdditionProfitLossAccount />
          </>
        )}
        {/* -------- Schedule BP --------- */}
        <hr />
        <div className="flex items-center justify-between my-2">
          <h4>{strings.income.schedule_oi}</h4>

          <button
            className="add_button"
            onClick={() => setScheduleOIForm(true)}
          >
            {formData?.scheduleOI?.id
              ? strings.button.edit
              : strings.button.add}
          </button>
        </div>
        <p>{strings.income.section_includes_addition_pl_in_schedule}</p>

        {/* {formData?.scheduleOI && (
          <div
            className="flex gap-2 p-3 my-3 align-center justify-between"
            style={{
              background: addOpacity(theme.primary_bg, 0.2),
              borderRadius: "10px",
            }}
          >
            <div>
              <p>Schedule OI (Other Information) </p>
            </div>

            <div className="action_buttons">
              <button
                className="delete_btn"
                onClick={() =>
                  deleteProfessionalIncomeDataApi(
                    formData?.scheduleOI?.id,
                    "scheduleOI"
                  )
                }
              >
                <RiDeleteBin6Fill />
              </button>
            </div>
          </div>
        )} */}
      </StyleFormBox>

      {/* ---------- Audit Information ------ */}
      <StyleFormBox>
        <h4>{strings.income.audit_information}</h4>
        <hr className="mb-3" />
        <div className="flex items-center justify-between">
          <h4>{strings.income.auditor_details}</h4>

          <button
            className="add_button"
            onClick={() => {
              setAuditInformationBox(!auditInformationForm);
              if (formData?.auditorDetails?.length > 0) {
                setEditData(formData?.auditorDetails[0]);
              }
            }}
          >
            {auditInformationForm
              ? "Close"
              : formData?.auditorDetails?.length > 0
              ? strings.button.edit
              : strings.button.add}
          </button>
        </div>
        <p>{strings.income.applicable_audit_book_of_accounts}</p>

        <hr />

        <div className="mt-4">
          {formData?.auditorDetails?.map((item) => (
            <div
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <p>Name of Auditor : {item?.nameOfAuditor}</p>
              </div>

              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    setAuditInformationBox(true);
                    setEditData(item);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(item?.id, "auditorDetails")
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          ))}
        </div>

        {auditInformationForm && (
          <>
            <AuditInformation
              data={editData}
              handleCallback={(e) => {
                setAuditInformationBox(false);
                setFormData((prev) => ({
                  ...prev,
                  auditorDetails: [e],
                }));
              }}
            />
          </>
        )}
      </StyleFormBox>

      {/* ----------- Quantitative Details -------- */}
      <StyleFormBox>
        <h4>{strings.income.quantitative_details}</h4>
        <hr className="mb-3" />

        {/* -------- Details of Trading Concern --------  */}
        <StyleFormBox>
          <div className="flex items-center justify-between my-4">
            <h4>{strings.income.details_of_trading_concern}</h4>

            <button
              className="add_button"
              onClick={() => setTradingConcernForm(true)}
            >
              {strings.button.add}
            </button>
          </div>

          {formData?.tradingConcern?.map((item) => (
            <div
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <p>Name of Firm : {item?.name}</p>
              </div>

              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    setTradingConcernForm(true);
                    setEditData(item);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(item?.id, "tradingConcern")
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          ))}
        </StyleFormBox>

        {/* ------- Details of Manufacturing Concern (Raw Materials) ---------  */}
        <StyleFormBox>
          <div className="flex items-center justify-between my-4">
            <h4>
              {strings.income.details_manufacturing_concern_raw_materials}
            </h4>
            <button
              className="add_button"
              onClick={() => setManufactureRowMaterialForm(true)}
            >
              {strings.button.add}
            </button>
          </div>

          {formData?.manufacturingConcern
            ?.filter((i) => i.isFinishedProduct == false)
            .map((item) => (
              <div
                className="flex gap-2 p-3 my-3 align-center justify-between"
                style={{
                  background: addOpacity(theme.primary_bg, 0.2),
                  borderRadius: "10px",
                }}
              >
                <div>
                  <p>Name of Firm : {item?.name}</p>
                </div>

                <div className="action_buttons">
                  <button
                    className="edit_btn"
                    onClick={() => {
                      setManufactureRowMaterialForm(true);
                      setEditData(item);
                    }}
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    className="delete_btn"
                    onClick={() =>
                      deleteProfessionalIncomeDataApi(
                        item?.id,
                        "manufacturingConcern"
                      )
                    }
                  >
                    <RiDeleteBin6Fill />
                  </button>
                </div>
              </div>
            ))}
        </StyleFormBox>

        {/* -------- Details of Manufacturing Concern (Finished Products)  ------- */}
        <StyleFormBox>
          <div className="flex items-center justify-between my-4">
            <h4>
              {strings.income.details_manufacturing_concern_finished_products}
            </h4>

            <button
              className="add_button"
              onClick={() => setManufactureFinishedProductForm(true)}
            >
              {strings.button.add}
            </button>
          </div>

          {formData?.manufacturingConcern
            ?.filter((i) => i.isFinishedProduct == true)
            ?.map((item) => (
              <div
                className="flex gap-2 p-3 my-3 align-center justify-between"
                style={{
                  background: addOpacity(theme.primary_bg, 0.2),
                  borderRadius: "10px",
                }}
              >
                <div>
                  <p>Name of Firm : {item?.name}</p>
                </div>

                <div className="action_buttons">
                  <button
                    className="edit_btn"
                    onClick={() => {
                      setManufactureFinishedProductForm(true);
                      setEditData(item);
                    }}
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    className="delete_btn"
                    onClick={() =>
                      deleteProfessionalIncomeDataApi(
                        item?.id,
                        "manufacturingConcern"
                      )
                    }
                  >
                    <RiDeleteBin6Fill />
                  </button>
                </div>
              </div>
            ))}
        </StyleFormBox>
      </StyleFormBox>

      {/* ----------- Remuneration from Partnership Firms */}
      <FormDialogBox
        open={remunerationForm}
        formTitle={strings.income.remuneration_from_partnership_firms}
        handleClose={() => {
          setRemunerationForm(false);
          setEditData({});
        }}
      >
        <RemunerationPartnershipFirms
          data={editData}
          handleCallback={(type, e) => {
            const updatedRemuneration =
              type === "edit"
                ? formData.remuneration.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...formData.remuneration, e];

            setFormData((prev) => ({
              ...prev,
              remuneration: updatedRemuneration,
            }));

            setRemunerationForm(false);
          }}
        />
      </FormDialogBox>

      {/* ----------- Books of Account are Not Maintained ---------- */}
      <FormDialogBox
        open={accountMaintainedForm}
        formTitle={strings.income.book_of_account_not_maintained}
        handleClose={() => {
          setAccountMaintainedForm(false);
          setEditData({});
        }}
      >
        <AccountMaintained
          data={editData}
          businessType={incomeData?.businessType}
          handleCallback={(type, e) => {
            const updatedBooksOfAccountNotMaintained =
              type === "edit"
                ? formData.booksOfAccountNotMaintained.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...formData.booksOfAccountNotMaintained, e];

            setFormData((prev) => ({
              ...prev,
              booksOfAccountNotMaintained: updatedBooksOfAccountNotMaintained,
            }));
            setAccountMaintainedForm(false);
          }}
        />
      </FormDialogBox>

      {/* ----------- Speculative Income   -------- */}
      <FormDialogBox
        open={speculativeForm}
        formTitle={strings.income.speculative_income}
        handleClose={() => {
          setSpeculativeForm(false);
          setEditData({});
        }}
      >
        <SpeculativeIncome
          data={editData}
          businessType={incomeData?.businessType}
          handleCallback={(type, e) => {
            const updatedSpeculative =
              type === "edit"
                ? formData.speculative.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...formData.speculative, e];

            setFormData((prev) => ({
              ...prev,
              speculative: updatedSpeculative,
            }));
            setSpeculativeForm(false);
          }}
        />
      </FormDialogBox>

      {/* ----------- Speculative Income > Other section  -------- */}
      <FormDialogBox
        open={scheduleBpForm}
        formTitle={strings.income.schedule_bp}
        handleClose={() => {
          setScheduleBpForm(false);
          setEditData({});
        }}
      >
        <ScheduleBp
          data={editData}
          handleCallback={(type, e) => {
            const updatedScheduleBP =
              type === "edit"
                ? formData.scheduleBP.map((item) =>
                    item.id === e[0].id ? e[0] : item
                  )
                : [...formData.scheduleBP, e];

            setFormData((prev) => ({
              ...prev,
              scheduleBP: updatedScheduleBP,
            }));
            setEditData({});
            setScheduleBpForm(false);
          }}
        />
      </FormDialogBox>

      {/* ---------- Schedule OI (Other Information) -------- */}
      <FormDialogBox
        open={scheduleOIForm}
        formTitle={strings.income.schedule_oi}
        handleClose={() => {
          setScheduleOIForm(false);
        }}
      >
        <ScheduleOI handleCallback={() => setScheduleOIForm(false)} />
      </FormDialogBox>

      {/* --------- Details of Trading Concern ---------- */}
      <FormDialogBox
        open={tradingConcernForm}
        formTitle={strings.income.trading_concern}
        handleClose={() => {
          setTradingConcernForm(false);
          setEditData({});
        }}
      >
        <TradingConcern
          unitCategory={incomeData?.unitCategory}
          data={editData}
          handleCallback={(type, e) => {
            const updatedtradingConcern =
              type === "edit"
                ? formData.tradingConcern.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...formData.tradingConcern, e];

            setFormData((prev) => ({
              ...prev,
              tradingConcern: updatedtradingConcern,
            }));
            setEditData({});
            setTradingConcernForm(false);
          }}
        />
      </FormDialogBox>

      {/* ----------- Details of Manufacturing Concern (Raw Materials) ------------ */}
      <FormDialogBox
        open={manufactureRowMaterialForm}
        formTitle={strings.income.manufacture_raw_materials}
        handleClose={() => {
          setManufactureRowMaterialForm(false);
          setEditData({});
        }}
      >
        <ManufactureRowMaterials
          unitCategory={incomeData?.unitCategory}
          isFinishedProduct={false}
          data={editData}
          handleCallback={(type, e) => {
            const updatedmanufacturingConcern =
              type === "edit"
                ? formData.manufacturingConcern.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...formData.manufacturingConcern, e];

            setFormData((prev) => ({
              ...prev,
              manufacturingConcern: updatedmanufacturingConcern,
            }));
            setManufactureRowMaterialForm(false);
            setEditData({});
          }}
        />
      </FormDialogBox>

      {/* ------------- Details of Manufacturing Concern (Finished Products)  ---------------- */}
      <FormDialogBox
        open={manufactureFinishedProductForm}
        formTitle={strings.income.manufacture_finished_product}
        handleClose={() => {
          setManufactureFinishedProductForm(false);
          setEditData({});
        }}
      >
        <ManufactureRowMaterials
          isFinishedProduct={true}
          unitCategory={incomeData?.unitCategory}
          data={editData}
          handleCallback={(type, e) => {
            const updatedmanufacturingConcern =
              type === "edit"
                ? formData.manufacturingConcern.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...formData.manufacturingConcern, e];

            setFormData((prev) => ({
              ...prev,
              manufacturingConcern: updatedmanufacturingConcern,
            }));
            setEditData({});
            setManufactureFinishedProductForm(false);
          }}
        />
      </FormDialogBox>
    </div>
  );
}