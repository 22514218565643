import "./App.css";
import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Home from "./page/Home";
import SignIn from "./page/SignIn";
import { useSelector } from "react-redux";
import WebLayout from "./component/Layout";
import RegisterUser from "./page/RegisterUser";
import styled from "styled-components";
import Profile from "./page/Profile";
import PasswordReset from "./page/PasswordReset";
import Questions from "./page/Questions";
import PanVerify from "./component/LogIn/PanVerify";
import IRT1Form from "./component/ITR1/IRT1Form";
import AddDocuments from "./component/Form/AddDocuments";
import TermConditions from "./page/TermConditions";
import AboutUs from "./page/AboutUs";
import TicketsChatBox from "./page/TicketsChatBox";
import Settings from "./component/Profile/Settings";
import ContactUs from "./page/ContactUs";
import Faq from "./page/Faq";
import PrivacyPolicy from "./page/PrivacyPolicy";
import Glossary from "./page/Glossary/Glossary";
import GlossaryById from "./page/Glossary/GlossaryById";
import IRT2Form from "./component/ITR2/IRT2Form";
import Service from "./page/Service";
import { addOpacity } from "./utils/helpers";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateTickets from "./page/CreateTickets";
import Details from "./page/Details";
import Resources from "./page/Resources";
import Newsletter from "./page/Newsletter";
import TaxOptimizer from "./page/Taxoptimizer";
import Upload16 from "./page/UploadForm16";
import Terms from "./page/Terms";
import TaxTool from "./page/Taxtools";
import FileItrFlow from "./page/FileItrFlow";
import GratuityCalculator from "./page/GratuityCalculator";
import HraCalculator from "./page/HraCalculator";
import Youritrform from "./page/IfscCode";
import IncomeTaxCalculator from "./page/IncomeTaxCalculator";
import Accommodation from "./page/Accommodation";
import EmiCalculator from "./page/Emicalculator";
import PpfCalculator from "./page/PpfCalculator";
import RentRecepit from "./page/RentRecepit";
import Taxestimator from "./page/Taxestimator";
import PrivateRoutes from "./component/PrivateRoutes";
import MyDashboard from "./page/MyDashboard";
import IRT3Form from "./component/ITR3";
import ProfessionalIncome from "./component/CommonScreens/GrossIncome/ProfessionalIncome/ProfessionalIncome";
import DetailBalanceSheet from "./component/CommonScreens/GrossIncome/ProfessionalIncome/DetailBalanceSheet";
import ITR4Form from "./component/ITR4/ITR4Form";
import ITR5Form from "./component/ITR5/ITR5Form";
import Subscription from "./page/Subscription";
import DetailBalanceSheet5 from "./component/CommonScreens/GrossIncome/ProfessionalIncome/DetailBalanceSheet5";
import ITR6Form from "./component/ITR6/ITR6Form";
import DetailBalanceSheet6 from "./component/CommonScreens/GrossIncome/ProfessionalIncome/DetailBalanceSheet6";

function App() {
  const { theme, themeMode } = useSelector((s) => s.platformSetting);

  const customToastStyle = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    pauseOnHover: false,
    theme: themeMode == "light" ? "light" : "dark",
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
  };

  const navigate = useNavigate();

  useEffect(() => {
    navigate(window.location.pathname, { replace: true });
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div style={{ background: theme.tertiary_bg }}>
      <Root theme={theme}>
        <WebLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<MyDashboard />} />
            <Route path="/login" element={<SignIn />} />

            <Route path="/add-documents" element={<AddDocuments />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/questions" element={<Questions />} />
            <Route path="/pan-verify" element={<PanVerify />} />
            <Route path="/register" element={<RegisterUser />} />
            <Route path="/terms-conditions" element={<TermConditions />} />
            <Route path="/about-us" element={<AboutUs />} />

            {/* <Route path="/settings" element={<Settings />} /> */}
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/service" element={<Service />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/ticket" element={<CreateTickets />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/glossary" element={<Glossary />} />
            <Route path="/glossary/:id" element={<GlossaryById />} />
            <Route path="/ticket-chat/:id" element={<TicketsChatBox />} />


            {/* New pages  */}
            <Route path="/details" element={<Details />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/newsletter" element={<Newsletter />} />
            <Route path="/taxoptimizer" element={<TaxOptimizer />} />

            <Route path="/terms" element={<Terms />} />
            <Route path="/tax-tool" element={<TaxTool />} />
            <Route
              path="/gratuity-calculator"
              element={<GratuityCalculator />}
            />
            <Route path="/hra-calculator" element={<HraCalculator />} />
            <Route path="/your-itr_form" element={<Youritrform />} />
            <Route
              path="/income-tax-calculator"
              element={<IncomeTaxCalculator />}
            />

            <Route path="/accommodation" element={<Accommodation />} />
            <Route path="/emicalculator" element={<EmiCalculator />} />
            <Route path="/ppfcalculator" element={<PpfCalculator />} />
            <Route path="/rent-recepit" element={<RentRecepit />} />
            <Route path="/taxestimator" element={<Taxestimator />} />

            <Route element={<PrivateRoutes />}>
              <Route path="/profile" element={<Profile />} />

              <Route path="/upload-form-16" element={<Upload16 />} />
              <Route path="/filing/itr/:active_tab" element={<FileItrFlow />} />

              {/* Itr forms Links */}

              <Route
                path="/form/filing/ITR/grossincome/professionalincome"
                element={<ProfessionalIncome />}
              />

              <Route
                path="/form/filing/ITR_1/:active_tab"
                element={<IRT1Form />}
              />
              <Route
                path="/form/filing/ITR_2/:active_tab"
                element={<IRT2Form />}
              />

              <Route
                path="/form/filing/ITR_3/:active_tab"
                element={<IRT3Form />}
              />

              <Route
                path="/form/filing/ITR_3/grossincome/professionalincome/detailBalanceSheet"
                element={<DetailBalanceSheet />}
              />
              <Route
                path="/form/filing/ITR_5/grossincome/professionalincome/detailBalanceSheet5"
                element={<DetailBalanceSheet5 />}
              />
              <Route
                path="/form/filing/ITR_6/grossincome/professionalincome/detailBalanceSheet"
                element={<DetailBalanceSheet6 />}
              />

              <Route
                path="/form/filing/ITR_4/:active_tab"
                element={<ITR4Form />}
              />
              <Route
                path="/form/filing/ITR_5/:active_tab"
                element={<ITR5Form />}
              />

              <Route
                path="/form/filing/ITR_6/:active_tab"
                element={<ITR6Form />}
              />
            </Route>

            {/* New screen test route */}
            <Route path="/subscription" element={<Subscription />} />
          </Routes>
        </WebLayout>
      </Root>
      <ToastContainer {...customToastStyle} />
    </div>
  );
}

export default App;
const Root = styled.section`
  color: ${(p) => p.theme.primary_txt};
  .max_limit {
    color: ${(p) => p.theme.primary_bg};
    font-size: 16px;
    font-weight: 500;
  }
  .flex_input {
    display: flex;
    gap: 10px;
    align-items: center;
    @media screen and (max-width: 500px) {
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
    > * {
      flex: 1;
    }
    &.save_amount {
      background-color: ${(p) => p.theme.secandary_bg};
      padding: 10px;
      margin-top: 10px;
      border-radius: 10px;
      justify-content: space-between;
      > *:first-child {
        flex: 2;
      }
      > *:last-child {
        flex: 1;
      }
    }
  }
  .edit_btn {
    color: ${(p) => p.theme.primary_bg};
  }
  .delete_btn {
    color: #d03838;
  }
  a {
    font-size: 14px;
  }
  .action_buttons {
    display: flex;
    font-size: 22px;
    gap: 6px;
    button:first-child {
      padding-right: 8px;
      border-right: 1px solid ${(p) => p.theme.border_clr};
    }
  }
  .note_message {
    color: ${(p) => p.theme.primary_bg};
    opacity: 0.6;
    font-size: 12px;
  }

  label {
    display: block;
    font-size: 14px;
    color: ${(p) => p.theme.primary_txt};
    font-weight: 500;
    margin: 10px 0px;
  }
  input,
  select {
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.border_clr};
    color: ${(p) => p.theme.primary_txt};
    background: ${(p) => p.theme.secandary_bg};
    margin-bottom: 10px;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(50%);
    font-size: 20px;
  }
  input[type="radio"] {
    width: fit-content;
    margin: 0px 4px;
  }
  input::placeholder {
    color: ${(p) => p.theme.primary_txt};
    font-weight: 400;
    font-size: 14px;
    opacity: 0.4;
  }
  .primary_button {
    width: 100%;
    background-color: ${(p) => p.theme.primary_bg};
    color: ${(p) => p.theme.secandary_txt};
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    font-weight: 500;
    &:disabled {
      background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.6)};
      color: ${(p) => p.theme.secandary_txt};
      cursor: not-allowed;
    }
  }
  .secandary_button {
    width: 100%;
    background-color: transparent;
    color: ${(p) => p.theme.primary_bg};
    font-weight: 600;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid ${(p) => p.theme.primary_bg};
    cursor: pointer;
  }
  .add_button {
    background-color: ${(p) => p.theme.primary_bg};
    color: ${(p) => p.theme.secandary_txt};
    border-radius: 50px;
    padding: 4px 14px;
  }
  .add_item_button {
    color: ${(p) => p.theme.primary_bg};
    padding: 4px 14px;
    font-weight: 600;
    background: none;
    border: none;
  }
  .delete_button {
    flex: 0;
    color: #fc5050;
    font-size: 22px;
  }

  .dropdown_arrow {
    background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.5)};
    border-radius: 50%;
    padding: 6px;
    border: none;
    font-size: 16px;
    transition: all 0.5s;
  }
  .dropdown_arrow.active {
    transform: rotate(180deg);
  }
  .skeleton_box {
    background-color: ${(p) => p.theme.secandary_bg};
    border-radius: 10px;
    border: 1px solid ${(p) => p.theme.border_clr};
    > hr {
      border: 1px solid ${(p) => p.theme.border_clr};
    }
  }
  .skeleton {
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin: 10px;
    background-color: ${(p) => p.theme.tertiary_bg};
    border-radius: 6px;
  }
  .skeleton:after {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      ${(p) => p.theme.tertiary_bg},
      ${(p) => p.theme.secandary_bg},
      ${(p) => p.theme.tertiary_bg}
    );
    transform: translateX(-100%);
    animation-name: loading-skeleton;
    animation-direction: normal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  @keyframes loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }
`;
