import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import styled from "styled-components";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import Select from "../../../../styledComponent/Inputs/Select";
import Input from "../../../../styledComponent/Inputs/Input";
import Phone from "../../../../styledComponent/Inputs/Phone";
import * as Yup from "yup";

import { Formik } from "formik";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import {
  delBadDebtsAction,
  postBadDebtsAction,
  updateBadDebtsAction,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";

export default function BadDebts({ badData, setBadDebtPop, changing }) {
  const { strings, theme, states } = useSelector((s) => s.platformSetting);
  const [citiesList, setCitiesList] = useState([]);
  const [activeState, setActiveState] = useState(1);
  const [loading, setLoading] = useState(false);
  const { ItrData } = useSelector((s) => s.itr);
  const [formData, setFormData] = useState({});
  const [badDebt, setBadDebt] = useState([]);
  const [showAdd, setShowAdd] = useState(false);

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Invalid name")
      .required(strings.validation_message.required),
    amount: Yup.number()
      .typeError("Invalid Amount")
      .required(strings.validation_message.required),
    address: Yup.object().shape({
      flatDoorNo: Yup.string().required(strings.validation_message.required),
      premiseName: Yup.string().required(strings.validation_message.required),
      street: Yup.string().required(strings.validation_message.required),
      area: Yup.string().required(strings.validation_message.required),
      city: Yup.string().required(strings.validation_message.required),
      state: Yup.string().required(strings.validation_message.required),
      country: Yup.string().required(strings.validation_message.required),
      pinCode: Yup.string().required(strings.validation_message.required).matches(
        /^[1-9][0-9]{5}$/, strings.validation_message.invalid_pin
      ),
    }),
  });

  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
        setLoading(false);
      })
    );
  };

  const handleFormSubmit = async (data) => {
    setLoading(true);
    if (data.id) {
      dispatch(
        updateBadDebtsAction(
          {
            id: data.id,
            name: data.name,
            amount: data.amount,
            detailBalanceSheet: data.detailBalanceSheet,
            address: {
              id: data.address.id,
              flatDoorNo: data.address.flatDoorNo,
              premiseName: data.address.premiseName,
              street: data.address.street,
              area: data.address.area,
              city: data.address.city,
              state: data.address.state,
              country: data.address.country,
              pinCode: data.address.pinCode,
            },
          },
          (e) => {
            if (e.status == 200) {
              const newData = badDebt.map((items) => {
                if (items.id == data.id) {
                  return data;
                } else {
                  return items;
                }
              });
              setBadDebt(newData);
              setLoading(false);
              toast.success("Updated Successfully");
            } else {
              toast.error("Something went wrong");
              setLoading(false);
            }
          }
        )
      );
    } else {
      const sendData = { ...data, detailBalanceSheet: badData.detailId };
      dispatch(
        postBadDebtsAction(sendData, (e) => {
          if (e.status == 200) {
            setBadDebt([...badDebt, { ...data, id: e?.data?.id }]);
            setLoading(false);
          } else {
            setLoading(false);
            toast.error("Something went wrong");
          }
        })
      );
    }
    setShowAdd(false);
  };

  const handleDelete = (index, id) => {
    setLoading(true);
    dispatch(
      delBadDebtsAction({ id }, (e) => {
        if (e.status === 200) {
          setShowAdd(false);
          setBadDebt((pre) => {
            return pre.filter((items, idx) => idx != index);
          });
          setLoading(false);
          toast.success("Deleted Successfully");
        }
      })
    );
  };

  useEffect(() => {
    setShowAdd(false)
    setBadDebt(badData?.data);
  }, [badData, changing]);

  useEffect(()=>{
    setLoading(true);
    getCities();
  }, [activeState])

  console.log("6666ttttt", showAdd, badDebt?.length);

  return (
    <Root>
      <LoadingBox open={loading} />

      {badDebt?.length === 0 || showAdd ? (
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleFormSubmit(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues }) => (
            <>
              <StyleFormBox>
                <h4 className="mb-5 mt-5" style={{ color: theme.primary_txt }}>
                  {strings.detail_balance_sheet.fillDetails}*
                </h4>
                <div className="flex_input">
                  <div>
                    <label>{strings.detail_balance_sheet.name} *</label>
                    <Input
                      name={"name"}
                      placeholder={`${strings.detail_balance_sheet.name}*`}
                      error={errors.name}
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label>{strings.detail_balance_sheet.amount} *</label>
                    <Input
                      name={"amount"}
                      placeholder={strings.detail_balance_sheet.amount}
                      error={errors.amount}
                      value={values.amount}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="flex_input">
                  <div>
                    <label>{strings.detail_balance_sheet.flatDoorBlock}</label>
                    <Input
                      name={"address.flatDoorNo"}
                      placeholder={`${strings.detail_balance_sheet.flatDoorBlock}`}
                      error={errors.address && errors.address.flatDoorNo}
                      value={values.address && values.address.flatDoorNo}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label>{strings.detail_balance_sheet.premiseName}</label>
                    <p style={{ marginTop: "-15px" }}>
                      {strings.detail_balance_sheet.optionalMsg}
                    </p>
                    <Input
                      name={"address.premiseName"}
                      placeholder={strings.address.premise_name}
                      error={errors.address && errors.address.premiseName}
                      value={values.address && values.address.premiseName}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex_input">
                  <div>
                    <label>{strings.detail_balance_sheet.roadStreet}</label>
                    <p style={{ marginTop: "-15px" }}>
                      {strings.detail_balance_sheet.optionalMsg}
                    </p>
                    <Input
                      name={"address.street"}
                      placeholder={`${strings.detail_balance_sheet.roadStreet}`}
                      error={errors.address && errors.address.street}
                      value={values.address && values.address.street}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label>{strings.detail_balance_sheet.areaLocality}</label>
                    <Input
                      name={"address.area"}
                      placeholder={strings.detail_balance_sheet.areaLocality}
                      error={errors.address && errors.address.area}
                      value={values.address && values.address.area}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex_input">
                  <div>
                    <label>{strings.detail_balance_sheet.pincode}</label>
                    <Input
                      type={"number"}
                      name={"address.pinCode"}
                      placeholder={strings.detail_balance_sheet.pincode}
                      error={errors.address && errors.address.pinCode}
                      value={values.address && values.address.pinCode}
                      onChange={(e) => {
                        if (e.target.value.length < 7) {
                          handleChange(e);
                        }
                      }}
                    />
                  </div>

                  <div>
                    <label>{strings.detail_balance_sheet.country}</label>
                    <Select
                      name="address.country"
                      error={errors.address && errors.address.country}
                      value={values.address && values.address.country}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          address: {
                            ...values.address,
                            country: e.target.value,
                          },
                        });
                        setActiveState(Number(e.target.value));
                      }}
                    >
                      <option value="">{strings.label.choose_option}</option>
                      <option value={101}>
                        {strings.detail_balance_sheet.india}
                      </option>
                    </Select>
                  </div>
                </div>

                <div className="flex_input">
                  <div>
                    <label>{strings.detail_balance_sheet.state}</label>
                    <Select
                      name="address.state"
                      error={errors.address && errors.address.state}
                      value={values.address && values.address.state}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          address: {
                            ...values.address,
                            state: e.target.value,
                          city:""
                          },
                        });
                        setActiveState(Number(e.target.value));
                      }}
                    >
                      <option value="">{strings.label.choose_option}</option>
                      {states?.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <label>{strings.detail_balance_sheet.townCity}</label>
                    <Select
                      name={"address.city"}
                      error={errors.address && errors.address.city}
                      value={values.address && values.address.city}
                      onChange={handleChange}
                    >
                      <option value="">{strings.label.choose_option}</option>
                      {citiesList?.map((i, ix) => (
                        <option value={i.id} key={ix}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="flex gap-4">
                  <button
                    className="secandary_button mt-5"
                    onClick={() => {
                      handleSubmit();
                    }}
                    type="submit"
                  >
                    {strings.button.save}
                  </button>
                  <button
                    className="primary_button mt-5"
                    type="submit"
                    onClick={() => {
                      handleSubmit();
                      setBadDebtPop(false);
                    }}
                  >
                    {strings.button.save_and_close}
                  </button>
                </div>
              </StyleFormBox>
            </>
          )}
        </Formik>
      ) : (
        <>
          <div>
            {badDebt &&
              badDebt?.map((items, ix) => {
                let state = states.find(
                  (data) => data.id == items.address.state
                );
                let city = citiesList.find(
                  (data) => data.id == items.address.city
                );
                return (
                  <StyleFormBox>
                    <div className="edit_box">
                      <h2
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "600",
                        }}
                      >
                        {items.address.flatDoorNo}, {city?.name}, {state?.name},
                        India, {items.address.pinCode}
                      </h2>
                      <div className="action_buttons">
                        <button
                          className="edit_btn"
                          onClick={() => {
                            setShowAdd(true);
                            setFormData(items);
                          }}
                        >
                          <FaRegEdit />
                        </button>
                        <button
                          className="delete_btn"
                          onClick={() => {
                            handleDelete(ix, items.id);
                          }}
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    </div>
                    <label>
                      {strings.detail_balance_sheet.name}: {items?.name}
                    </label>
                    <label>
                      {strings.detail_balance_sheet.amount}: {items?.amount}
                    </label>
                  </StyleFormBox>
                );
              })}
          </div>
          <button
            className="primary_button mt-5"
            type="submit"
            onClick={() => {
              setFormData({});
              setShowAdd(true);
            }}
          >
            {strings.button.add}
          </button>
        </>
      )}
    </Root>
  );
}
const Root = styled.section`
  .edit_box {
    display: flex;
    justify-content: space-between;
  }
`;
