import React, { useEffect, useState } from "react";
import Input from "../../../../styledComponent/Inputs/Input";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteScheduleFDAction,
  getScheduleFDAction,
  saveScheduleFDAction,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import { addOpacity, removeEmptyKeys } from "../../../../utils/helpers";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FormDialogBox from "../../../DialogBox/FormDialogBox";

export default function ScheduleFD() {
  const [formData, setFormData] = useState({});
  const [formBox, setFormBox] = useState(false);
  const [loader, setLoader] = useState(true);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const handleFormSubmit = (obj) => {
    setLoader(true);
    delete obj.id;
    dispatch(
      saveScheduleFDAction(obj, (e) => {
        if (e.status == 200) {
          let data = e.data;
          setFormData({
            itrId: ItrData.id,
            id: data?.id || "0",
            paymentCapitalAccount: data?.paymentCapitalAccount || "",
            paymentReveueAccount: data?.paymentReveueAccount || "",
            receiptsCapitalAccount: data?.receiptsCapitalAccount || "",
            receiptsRevenueAccount: data?.receiptsRevenueAccount || "",
          });
          toast.success(`Successfully Added data`);
          setFormBox(false);
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const handleDeleteData = () => {
    setLoader(true);
    dispatch(
      deleteScheduleFDAction({ itrId: ItrData.id }, (e) => {
        if (e.status == 200) {
          setFormData({});
          toast.success(`Successfully Delete data`);
          setFormBox(false);
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const getScheduleFDData = () => {
    setLoader(true);
    dispatch(
      getScheduleFDAction(ItrData.id, (e) => {
        if (e.status == 200) {
          let data = e.data;
          setFormData({
            itrId: ItrData.id,
            id: data?.id || "0",
            paymentCapitalAccount: data?.paymentCapitalAccount || "",
            paymentReveueAccount: data?.paymentReveueAccount || "",
            receiptsCapitalAccount: data?.receiptsCapitalAccount || "",
            receiptsRevenueAccount: data?.receiptsRevenueAccount || "",
          });
        } else {
          toast.error(e.message);
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    getScheduleFDData();
  }, []);

  return (
    <div>
      <LoadingBox open={loader} />

      {formData.id > 0 ? (
        <div
          className="flex gap-2 p-3 my-3 align-center justify-between"
          style={{
            background: addOpacity(theme.primary_bg, 0.2),
            borderRadius: "10px",
          }}
        >
          <div>
            <p>
              Payments Made On Capital Account :{" "}
              {formData?.paymentCapitalAccount}
            </p>
            <p>
              Payments Made On Capital Account :{" "}
              {formData?.paymentReveueAccount}
            </p>
            <p>
              Payments Made On Capital Account :{" "}
              {formData?.receiptsCapitalAccount}
            </p>
            <p>
              Payments Made On Capital Account :{" "}
              {formData?.receiptsRevenueAccount}
            </p>
          </div>

          <div className="action_buttons">
            <button
              className="edit_btn"
              onClick={() => {
                setFormBox(true);
              }}
            >
              <FaRegEdit />
            </button>
            <button className="delete_btn" onClick={() => handleDeleteData()}>
              <RiDeleteBin6Fill />
            </button>
          </div>
        </div>
      ) : (
        <div>
          <button
            className="primary_button mt-4"
            onClick={() => setFormBox(true)}
          >
            Add Schedule FD
          </button>
        </div>
      )}
      <FormDialogBox
        open={formBox}
        formTitle={
          "Schedule FD: Break-up of payments/receipts in Foreign currency"
        }
        handleClose={() => {
          setFormBox(false);
        }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            handleFormSubmit(removeEmptyKeys(values));
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues }) => (
            <div className="mt-4">
              <div className="flex_input">
                <label>Payments Made On Capital Account</label>
                <Input
                  type={"number"}
                  name={"paymentCapitalAccount"}
                  error={errors.paymentCapitalAccount}
                  value={values.paymentCapitalAccount}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input">
                <label>Payments Made On Revenue Account</label>
                <Input
                  type={"number"}
                  name={"paymentReveueAccount"}
                  error={errors.paymentReveueAccount}
                  value={values.paymentReveueAccount}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input">
                <label>Receipts On Capital Account</label>
                <Input
                  type={"number"}
                  name={"receiptsCapitalAccount"}
                  error={errors.receiptsCapitalAccount}
                  value={values.receiptsCapitalAccount}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input">
                <label>Receipts On Revenue Account</label>
                <Input
                  type={"number"}
                  name={"receiptsRevenueAccount"}
                  error={errors.receiptsRevenueAccount}
                  value={values.receiptsRevenueAccount}
                  onChange={handleChange}
                />
              </div>

              <button className="primary_button my-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
      </FormDialogBox>
    </div>
  );
}
