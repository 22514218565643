import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { BiRupee } from "react-icons/bi";

export default function CurrencyField({ error, value, handleChange }) {
  const { theme } = useSelector((state) => state.platformSetting);
  const [verifyNum, setVerifyNum] = useState(true);

  return (
    <Root theme={theme}>
      <div className="input_field">
        <span>
          <BiRupee />
        </span>
        <input
          type="number"
          placeholder=""
          value={value}
          onChange={handleChange}
          onWheel={(e) => e.target.blur()}
        />
      </div>
      <p className="error">{typeof value !== "number" ? error : ""}</p>
    </Root>
  );
}
const Root = styled.div`
  width: 100%;
  position: relative;
  .input_field {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.border_clr};
    span {
      padding: 10px;
      font-size: 28px;
      background: ${(p) => addOpacity(p.theme.primary_bg, 0.2)};
      border-radius: 12px 0px 0px 12px;
      color: ${(p) => p.theme.primary_txt};
    }

    input {
      padding: 12px;
      border-radius: 0px 12px 12px 0px;
      border: none;
      width: 100%;
      color: ${(p) => p.theme.primary_txt};
      background-color: transparent;
      margin-bottom: 0px !important;
      &::placeholder {
        color: ${(p) => p.theme.primary_txt};
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
`;
