import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Formik } from "formik";
import StyleFormBox from "../styledComponent/Form/StyleFormBox";
import * as Yup from "yup";
import Input from "../styledComponent/Inputs/Input";
import { contactUsAction } from "../redux/profile/action";
import LoadingBox from "../component/DialogBox/LoadingBox";

export default function ContactUs() {
  const [loader, setLoader] = useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(strings.validation_message.first_name),
    message: Yup.string().required(strings.validation_message.message),
    email: Yup.string()
      .email(strings.validation_message.invalid_email)
      .required(strings.validation_message.email),
    mobileNumber: Yup.string().required(
      strings.validation_message.phone_number
    ),
  });

  const handleSubmitForm = (form, { resetForm }) => {
    let updateForm = {
      ...form,
      mobileNumber: form.mobileNumber.slice(form.mobileCode.length),
    };
    setLoader(true);
    dispatch(
      contactUsAction(updateForm, (e) => {
        if (e.status == 200) {
          toast.success(e.data);
          resetForm();
        }
        setLoader(false);
      })
    );
  };

  return (
    <Root theme={theme}>
      <LoadingBox open={loader} />

      <StyleFormBox>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            mobileCode: "",
            mobileNumber: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmitForm(values, { resetForm });
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues }) => (
            <form onSubmit={handleSubmit}>
              <h4>{strings.profile.contact_us}</h4>
              <div className="flex justify-between gap-3">
                <div className="w-full">
                  <label>{strings.label.first_name}</label>
                  <Input
                    name={"firstName"}
                    placeholder={strings.label.first_name}
                    error={errors.firstName}
                    value={values.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <label>{strings.label.last_name}</label>
                  <Input
                    name={"lastName"}
                    placeholder={strings.label.last_name}
                    error={errors.lastName}
                    value={values.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex justify-between gap-3">
                <div className="w-full">
                  <label>{strings.label.email}</label>
                  <Input
                    name={"email"}
                    placeholder={strings.label.email}
                    error={errors.email}
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <label>{strings.label.phone_number}</label>
                  <Input
                    inputType={"phone"}
                    name={"mobileNumber"}
                    error={errors.mobileNumber}
                    value={values.mobileNumber}
                    onChange={(phoneNumber, country) =>
                      setValues({
                        ...values,
                        mobileNumber: phoneNumber.replace(/\D/g, ""),
                        mobileCode: country.dialCode.replace(/\D/g, ""),
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full">
                <label>{strings.label.your_message}</label>

                <Input
                  name={"message"}
                  inputType={"message"}
                  placeholder={strings.placeholder.type_your_text}
                  error={errors.message}
                  value={values.message}
                  onChange={handleChange}
                />
              </div>

              <button className="primary_button mt-5" type="submit">
                {strings.button.submit}
              </button>
            </form>
          )}
        </Formik>
      </StyleFormBox>
    </Root>
  );
  
}
const Root = styled.section`
  margin: 30px auto 0px;
  max-width: 800px;
  .image_select {
    background-color: ${(p) => p.theme.secandary_bg};
    border: 8px solid ${(p) => p.theme.tertiary_bg};
  }
`;
