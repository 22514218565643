import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../styledComponent/Inputs/Input";
import { Formik } from "formik";
import { removeEmptyKeys } from "../../../../utils/helpers";
import * as Yup from "yup";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { toast } from "react-toastify";
import { saveLiabilitiesAndAssetsAction } from "../../../../redux/itr/action";
import styled from 'styled-components';

export default function FinancialParticular({ formsData, handleClose }) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);

  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    assets: Yup.object().shape({
      uncollectedReceivables: Yup.string().required(
        strings.validation_message.required
      ),
      totalInventoryValue: Yup.number().required(
        strings.validation_message.required
      ),
      cashInHand: Yup.number().required(strings.validation_message.required),
      // fixedAssets: Yup.number().required(strings.validation_message.required),
      // closingBalanceWithBanks: Yup.number().required(
      //   strings.validation_message.required
      // ),
      // loansAndAdvances: Yup.number().required(
      //   strings.validation_message.required
      // ),
      // otherAssets: Yup.number().required(strings.validation_message.required),
    }),
    liabilities: Yup.object().shape({
      unsettledPayables: Yup.number().required(
        strings.validation_message.required
      ),
      // outstandingPrincipalSecuredLoans: Yup.number().required(
      //   strings.validation_message.required
      // ),
      // advances: Yup.number().required(strings.validation_message.required),
      // capitalInvestment: Yup.number().required(
      //   strings.validation_message.required
      // ),
      // otherLiabilities: Yup.number().required(
      //   strings.validation_message.required
      // ),
    }),
  });

  const submitForm = async (data) => {
    const newData = {...data, assets: removeEmptyKeys(data?.assets), liabilities: removeEmptyKeys(data?.liabilities)}
    setLoader(true);
    dispatch(
      saveLiabilitiesAndAssetsAction(newData, (e) => {
        if (e.status == 200) {
          toast.success('Successfully saved data');
          handleClose();
        }
        setLoader(false);
      })
    );
  };

  const getLiabilitiesAndAssetsApi = async (data) => {
    setLoader(true);
    dispatch(
      saveLiabilitiesAndAssetsAction(data, (e) => {
        console.log("saveLiabilitiesAndAssetsAction =========>", e.data);
        if (e.status == 200) {
          handleClose();
          setLoader(false);
        } else {
          toast.error(e.message);
          setLoader(false);
        }
      })
    );
  };
  
  useEffect(() => {
    setLoader(true);
    setFormData({
      itrId: ItrData.id,
      assets: {
        uncollectedReceivables: formsData?.assets?.uncollectedReceivables || "",
        totalInventoryValue: formsData?.assets?.totalInventoryValue || "",
        cashInHand: formsData?.assets?.cashInHand || "",
        fixedAssets: formsData?.assets?.fixedAssets || "",
        closingBalanceWithBanks:
          formsData?.assets?.closingBalanceWithBanks || "",
        loansAndAdvances: formsData?.assets?.loansAndAdvances || "",
        otherAssets: formsData?.assets?.otherAssets || "",
      },
      liabilities: {
        unsettledPayables: formsData?.liabilities?.unsettledPayables || "",
        outstandingPrincipalSecuredLoans:
          formsData?.liabilities?.outstandingPrincipalSecuredLoans || "",
        advances: formsData?.liabilities?.advances || "",
        capitalInvestment: formsData?.liabilities?.capitalInvestment || "",
        otherLiabilities: formsData?.liabilities?.otherLiabilities || "",
      },
    });
    setLoader(false);
  }, [formsData]);

  useEffect(()=>{
    // getLiabilitiesAndAssetsApi()
  },[])
  return (
    <Root>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          submitForm(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <StyleFormBox>
            <h4>{strings.income.add_financial_particulars_of_business}</h4>
            <p>1. {strings.income.exclude_personal_assets_liabilities}</p>
            <p>
              2.{" "}
              {
                strings.income
                  .include_business_profession_related_assets_liabilities
              }
            </p>
            <p>
              3. {strings.income.business_has_no_inventory_payables_receivables}
            </p>
            <p>4. {strings.income.multiple_businesses_combine_assets}</p>
            <hr />
            <h4 className="mt-4">{strings.income.add_details_liabilities}</h4>
            <div className="flex_input my-2 ">
              <label>{strings.income.unsettled_payables} *</label>
              <div>
                <Input
                  name={`liabilities.unsettledPayables`}
                  inputType={"currency"}
                  value={
                    values?.liabilities &&
                    values?.liabilities?.unsettledPayables
                  }
                  error={
                    errors?.liabilities &&
                    errors?.liabilities?.unsettledPayables
                  }
                  onChange={handleChange}
                />
                <span className="note_message">
                  {strings.message.ammount_to_be_paid_}
                </span>
              </div>
            </div>

            <div className="flex_input my-2 ">
              <label>
                {strings.income.outstanding_principal_secured_loans}
              </label>
              <div>
                <Input
                  name={`liabilities.outstandingPrincipalSecuredLoans`}
                  inputType={"currency"}
                  value={
                    values?.liabilities &&
                    values?.liabilities?.outstandingPrincipalSecuredLoans
                  }
                  error={
                    errors?.liabilities &&
                    errors?.liabilities?.outstandingPrincipalSecuredLoans
                  }
                  onChange={handleChange}
                />
                <span className="note_message">
                  {strings.message.loans_backed_by_collateral}
                </span>
              </div>
            </div>

            <div className="flex_input my-2 ">
              <label>{strings.income.advances}</label>
              <div>
                <Input
                  name={`liabilities.advances`}
                  inputType={"currency"}
                  value={values?.liabilities && values?.liabilities?.advances}
                  error={errors?.liabilities && errors?.liabilities?.advances}
                  onChange={handleChange}
                />
                <span className="note_message">
                  {strings.message.amounts_received_in_advance}
                </span>
              </div>
            </div>

            <div className="flex_input my-2 ">
              <label>{strings.balance_sheet.capital_investment}</label>
              <div>
                <Input
                  name={`liabilities.capitalInvestment`}
                  inputType={"currency"}
                  value={
                    values?.liabilities &&
                    values?.liabilities?.capitalInvestment
                  }
                  error={
                    errors?.liabilities &&
                    errors?.liabilities?.capitalInvestment
                  }
                  onChange={handleChange}
                />
                <span className="note_message">
                  {strings.message.captial_provided_by_business_partners}
                </span>
              </div>
            </div>

            <div className="flex_input my-2 ">
              <label>{strings.income.other_liabilities}</label>
              <Input
                name={`liabilities.otherLiabilities`}
                inputType={"currency"}
                value={
                  values?.liabilities && values?.liabilities?.otherLiabilities
                }
                error={
                  errors?.liabilities && errors?.liabilities?.otherLiabilities
                }
                onChange={handleChange}
              />
            </div>
            <div className="flex_input justify-between">
              <h4>{strings.income.total_liabilities}</h4>
              <h4 className="text-right">
                {strings.label.rupee}{" "}
                {values?.liabilities?.unsettledPayables +
                  values?.liabilities?.outstandingPrincipalSecuredLoans +
                  values?.liabilities?.advances +
                  values?.liabilities?.capitalInvestment +
                  values?.liabilities?.otherLiabilities}
              </h4>
            </div>

            <hr />
            <h4>{strings.income.add_details_of_assets}</h4>

            <div className="flex_input my-2 ">
              <label>{strings.income.uncollected_receivables} *</label>
              <div>
                <Input
                  name={`assets.uncollectedReceivables`}
                  inputType={"currency"}
                  value={
                    values?.assets && values?.assets?.uncollectedReceivables
                  }
                  error={
                    errors?.assets && errors?.assets?.uncollectedReceivables
                  }
                  onChange={handleChange}
                />
                <span className="note_message">
                  {strings.message.amount_collected_from_customers_clients}
                </span>
              </div>
            </div>

            <div className="flex_input my-2 ">
              <label>{strings.income.total_inventory_value} *</label>
              <div>
                <Input
                  name={`assets.totalInventoryValue`}
                  inputType={"currency"}
                  value={values?.assets && values?.assets?.totalInventoryValue}
                  error={errors?.assets && errors?.assets?.totalInventoryValue}
                  onChange={handleChange}
                />
                <span className="note_message">
                  {strings.message.total_worth_goods_held_for_sale}
                </span>
              </div>
            </div>

            <div className="flex_input my-2 ">
              <label>{strings.income.cash_in_hand} *</label>
              <div>
                <Input
                  name={`assets.cashInHand`}
                  inputType={"currency"}
                  value={values?.assets && values?.assets?.cashInHand}
                  error={errors?.assets && errors?.assets?.cashInHand}
                  onChange={handleChange}
                />
                <span className="note_message">
                  {strings.message.total_cash_readily_available}
                </span>
              </div>
            </div>

            <div className="flex_input my-2 ">
              <label>{strings.income.fixed_assets}</label>
              <Input
                name={`assets.fixedAssets`}
                inputType={"currency"}
                value={values?.assets && values?.assets?.fixedAssets}
                error={errors?.assets && errors?.assets?.fixedAssets}
                onChange={handleChange}
              />
            </div>

            <div className="flex_input my-2 ">
              <label>{strings.income.closing_balance_with_banks}</label>
              <div>
                <Input
                  name={`assets.closingBalanceWithBanks`}
                  inputType={"currency"}
                  value={
                    values?.assets && values?.assets?.closingBalanceWithBanks
                  }
                  error={
                    errors?.assets && errors?.assets?.closingBalanceWithBanks
                  }
                  onChange={handleChange}
                />
                <span className="note_message">
                  {strings.message.amount_help_bank_accounts}
                </span>
              </div>
            </div>

            <div className="flex_input my-2 ">
              <label>{strings.income.loans_and_advances}</label>
              <Input
                name={`assets.loansAndAdvances`}
                inputType={"currency"}
                value={values?.assets && values?.assets?.loansAndAdvances}
                error={errors?.assets && errors?.assets?.loansAndAdvances}
                onChange={handleChange}
              />
            </div>

            <div className="flex_input my-2 ">
              <label>{strings.income.other_assets}</label>
              <Input
                name={`assets.otherAssets`}
                inputType={"currency"}
                value={values?.assets && values?.assets?.otherAssets}
                error={errors?.assets && errors?.assets?.otherAssets}
                onChange={handleChange}
              />
            </div>

            <div className="flex_input justify-between">
              <h4>{strings.income.total_assets}</h4>
              <h4 className="text-right">
                {" "}
                {strings.label.rupee}{" "}
                {values?.assets?.uncollectedReceivables +
                  values?.assets?.totalInventoryValue +
                  values?.assets?.cashInHand +
                  values?.assets?.fixedAssets +
                  values?.assets?.closingBalanceWithBanks +
                  values?.assets?.loansAndAdvances +
                  values?.assets?.otherAssets}
              </h4>
            </div>
            <button
              className="primary_button my-4"
              onClick={() => handleSubmit()}
            >
              {strings.button.save_and_close}
            </button>
          </StyleFormBox>
        )}
      </Formik>
    </Root>
  );
}
const Root = styled.section`
.error.curr {
    bottom: -18px;
}`