import React, { useEffect, useState } from "react";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Section44AE from "./Section44AE";
import {
  deleteaddGSTDetailsAction,
  deleteAuditorDetailsAction,
  deleteBooksAccountNotMaintained,
  deleteManufacturingConcernAction,
  deleteRemunerationAction,
  deleteScheduleBP,
  deleteScheduleOIAction,
  deleteSpeculativeIncome,
  deleteTradingConcernAction,
  getProfessionalBusiness,
  getProfessionalBusiness3,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import LoadingBox from "../../../DialogBox/LoadingBox";
import RegularBusiness from "./RegularBusiness";
import { addOpacity } from "../../../../utils/helpers";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import AdditionProfitLossAccount from "./OtherBusinessIncome/AdditionProfitLossAccount";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import AddGst from "./AddGst";
import AuditInformation from "./OtherBusinessIncome/AuditInformation";
import ScheduleBp from "./OtherBusinessIncome/ScheduleBp";
import ScheduleOI from "./OtherBusinessIncome/ScheduleOI";
import TradingConcern from "./OtherBusinessIncome/TradingConcern";
import ManufactureRowMaterials from "./OtherBusinessIncome/ManufactureRowMaterials";
import ScheduleFD from "./ScheduleFD";
import { FaAngleDown } from "react-icons/fa6";
import ScheduleSH1 from "./ScheduleSH1";
import ScheduleAL1 from "./ScheduleAL1";

export default function ProfessionalIncomeITR6({ gstData }) {
  const [optionData, setOptionData] = useState({});
  const [incomeData, setIncomeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [gstFormData, setGstFormData] = useState([]);
  const [editFormData, setEditFormData] = useState({});
  const [gstFormBox, setGstFormBox] = useState(false);
  const [profitLossForm, setProfitLossForm] = useState(false);
  const [scheduleBpForm, setScheduleBpForm] = useState(false);
  const [scheduleOIForm, setScheduleOIForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [editData, setEditData] = useState({});
  const [auditInformationBox, setAuditInformationBox] = useState(false);
  const [scheduleFdBox, setScheduleFdBox] = useState(false);
  const [scheduleSH1Box, setScheduleSH1Box] = useState(false);
  const [scheduleAL1Box, setScheduleAL1Box] = useState(false);
  const [tradingConcernForm, setTradingConcernForm] = useState(false);
  const [manufactureRowMaterialForm, setManufactureRowMaterialForm] =
    useState(false);
  const [manufactureFinishedProductForm, setManufactureFinishedProductForm] =
    useState(false);

  const { ItrData } = useSelector((s) => s.itr);
  const { strings, theme } = useSelector((s) => s.platformSetting);

  const dispatch = useDispatch();

  const getProfessionalBusinessApi = async () => {
    let parm = `?itrId=${ItrData.id}`;
    setLoading(true);
    dispatch(
      getProfessionalBusiness(parm, (e) => {
        if (e.status == 200) {
          setOptionData((prev) => ({ ...prev, ...e.data }));
          // if (ItrData.form !== "ITR_3") {
          //   setPropertyObj(e?.data?.businessIncomeType?.slice(0, 3));
          // }
          // setActiveOption(e?.data?.businessIncomeType?.slice(0, 3)[0].id);
        } else {
          toast.error(e.message);
        }
        setLoading(false);
      })
    );
  };
  const getProfessionalIncomeApi = async () => {
    setLoading(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness3(parm, (e) => {
        if (e.status == 200) {
          const { professionalBusiness, ...data } = e.data;
          setIncomeData(data);
          setFormData(professionalBusiness);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };
  const deleteProfessionalIncomeDataApi = async (id, type) => {
    setLoading(true);

    if (type == "remuneration") {
      dispatch(
        deleteRemunerationAction({ id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              remuneration: formData.remuneration.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "booksOfAccountNotMaintained") {
      dispatch(
        deleteBooksAccountNotMaintained({ itrId: ItrData.id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              booksOfAccountNotMaintained:
                formData.booksOfAccountNotMaintained.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "speculative") {
      dispatch(
        deleteSpeculativeIncome({ itrId: ItrData.id, id: id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              speculative: formData.speculative.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "scheduleBP") {
      dispatch(
        deleteScheduleBP({ itrId: ItrData.id, id: id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              scheduleBP: formData.scheduleBP.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "auditorDetails") {
      dispatch(
        deleteAuditorDetailsAction({ id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              auditorDetails: formData.auditorDetails.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "tradingConcern") {
      dispatch(
        deleteTradingConcernAction({ id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              tradingConcern: formData.tradingConcern.filter((i) => i.id != id),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "manufacturingConcern") {
      dispatch(
        deleteManufacturingConcernAction({ id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              manufacturingConcern: formData.manufacturingConcern.filter(
                (i) => i.id != id
              ),
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "scheduleOI") {
      dispatch(
        deleteScheduleOIAction({ id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              scheduleOI: {},
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "gstDetails") {
      dispatch(
        deleteaddGSTDetailsAction({ id }, (e) => {
          if (e.status == 200) {
            setGstFormData(gstFormData.filter((i) => i.id != id));
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    }
  };

  useEffect(() => gstData && setGstFormData(gstData), [gstData]);
  useEffect(() => {
    getProfessionalBusinessApi();
    getProfessionalIncomeApi();
  }, []);

  return (
    <div>
      <LoadingBox open={loading} />

      <AccordianBox
        title={"Business & Professional"}
        description={strings.income.stocks_mutual_funds_land_bonds_jewellery}
      >
        <AccordianBox
          title={"Add a Business"}
          description={
            "Presumptive Income Under Section 44AE (For Goods Carrier)"
          }
          initial={false}
        >
          <Section44AE
            data={optionData?.businessProfessionIncome?.businessDetail44AE}
            businessCode={optionData?.businessCodes}
            ownershipStatus={optionData?.ownershipStatus}
          />
        </AccordianBox>

        <AccordianBox
          title={"Balance Sheet with Profit & Loss Account"}
          initial={false}
        >
          <RegularBusiness
            data={optionData?.professionalBusiness}
            businessType={optionData?.businessType}
          />
        </AccordianBox>

        <h4 className="mt-4">Financial Statements & Schedules</h4>

        {/* --------------- Add GST Details --------- */}
        <StyleFormBox>
          <div className="flex items-center justify-between">
            <h4>{strings.income.add_gst_details}</h4>
            <button
              className="add_button"
              onClick={() => {
                gstFormData.length > 0 && setEditFormData(gstFormData);
                setGstFormBox(true);
              }}
            >
              {gstFormData.length > 0
                ? `${strings.button.add} / ${strings.button.edit}`
                : strings.button.add}
            </button>
          </div>

          {gstFormData?.map((item) => (
            <div
              key={item.id}
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <p>GST Number : {item.gstnNumber}</p>
                <p>Gross Receipts : {item.grossReceipts}</p>
              </div>

              <div className="action_buttons">
                {/* <button
                  className="edit_btn"
                  onClick={() => {
                    setGstFormBox(true);
                    setEditFormData(gstFormData);
                  }}
                >
                  <FaRegEdit />
                </button> */}
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(item?.id, "gstDetails")
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          ))}
        </StyleFormBox>

        {/* ---------- Schedule BP --------- */}
        <StyleFormBox>
          {/* <h4>{strings.income.financial_statements_schedules}</h4>
  <hr className="mb-3" /> */}
          <div className="flex items-center justify-between mt-4">
            <h4>{strings.income.schedule_bp}</h4>
            <div className="text-right">
              <button
                className="add_item_button"
                onClick={() => setProfitLossForm(!profitLossForm)}
              >
                {profitLossForm
                  ? " Close Other Sections"
                  : " Open Other Sections"}
              </button>
              <button
                className="add_button"
                onClick={() => setScheduleBpForm(true)}
              >
                {strings.button.add}
              </button>
            </div>
          </div>
          <p>
            {strings.income.adjustment_income_from_sources_other_than_business}
          </p>

          <div className="mt-4">
            {formData?.scheduleBP?.map((item) => (
              <div
                className="flex gap-2 p-3 my-3 align-center justify-between"
                style={{
                  background: addOpacity(theme.primary_bg, 0.2),
                  borderRadius: "10px",
                }}
              >
                <div>
                  <p>
                    {" "}
                    Speculation Business (for e.g. Intraday trading, etc.) :{" "}
                    {item?.totalProfitAndGainsOfBusiness}
                  </p>
                </div>

                <div className="action_buttons">
                  <button
                    className="edit_btn"
                    onClick={() => {
                      setScheduleBpForm(true);
                      setEditData(item);
                    }}
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    className="delete_btn"
                    onClick={() =>
                      deleteProfessionalIncomeDataApi(item?.id, "scheduleBP")
                    }
                  >
                    <RiDeleteBin6Fill />
                  </button>
                </div>
              </div>
            ))}
          </div>

          {profitLossForm && (
            <>
              <h3> Other Sections</h3>
              <AdditionProfitLossAccount />
            </>
          )}
          {/* -------- Schedule BP --------- */}
          <hr />
          <div className="flex items-center justify-between my-2">
            <h4>{strings.income.schedule_oi}</h4>

            <button
              className="add_button"
              onClick={() => setScheduleOIForm(true)}
            >
              {formData?.scheduleOI?.id
                ? strings.button.edit
                : strings.button.add}
            </button>
          </div>
          <p>{strings.income.section_includes_addition_pl_in_schedule}</p>

          {/* {formData?.scheduleOI && (
            <div
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <p>Schedule OI (Other Information) </p>
              </div>
  
              <div className="action_buttons">
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(
                      formData?.scheduleOI?.id,
                      "scheduleOI"
                    )
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          )} */}
        </StyleFormBox>

        {/* ---------- Audit Information ------ */}
        <h4 className="my-4">{strings.income.audit_information}</h4>
        <StyleFormBox>
          <div className="flex items-center justify-between">
            <h4>{strings.income.auditor_details}</h4>
            <button
              className="add_button"
              onClick={() => {
                setAuditInformationBox(!auditInformationBox);
                if (formData?.auditorDetails?.length > 0) {
                  setEditData(formData?.auditorDetails[0]);
                }
              }}
            >
              {auditInformationBox
                ? "Close"
                : formData?.auditorDetails?.length > 0
                ? strings.button.edit
                : strings.button.add}
            </button>
          </div>
          <p>{strings.income.applicable_audit_book_of_accounts}</p>

          <hr />

          <div className="mt-4">
            {formData?.auditorDetails?.map((item) => (
              <div
                key={item.id}
                className="flex gap-2 p-3 my-3 align-center justify-between"
                style={{
                  background: addOpacity(theme.primary_bg, 0.2),
                  borderRadius: "10px",
                }}
              >
                <div>
                  <p>Name of Auditor : {item?.nameOfAuditor}</p>
                </div>

                <div className="action_buttons">
                  <button
                    className="edit_btn"
                    onClick={() => {
                      setAuditInformationBox(true);
                      setEditData(item);
                    }}
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    className="delete_btn"
                    onClick={() =>
                      deleteProfessionalIncomeDataApi(
                        item?.id,
                        "auditorDetails"
                      )
                    }
                  >
                    <RiDeleteBin6Fill />
                  </button>
                </div>
              </div>
            ))}
          </div>

          {auditInformationBox && (
            <>
              <AuditInformation
                data={editData}
                handleCallback={(e) => {
                  setAuditInformationBox(false);
                  setFormData((prev) => ({
                    ...prev,
                    auditorDetails: [e],
                  }));
                }}
              />
            </>
          )}
        </StyleFormBox>

        {/* ------- Schedule FD ---------- */}
        <StyleFormBox>
          <div className="flex items-center justify-between">
            <div>
              <h4>Schedule FD</h4>
              <p>
                Break-up of payments/receipts in foreign currency (to be filled
                up by the assessee who is not liable to get accounts audited u/s
                44AB).
              </p>
            </div>

            <button
              onClick={() => {
                setScheduleFdBox(true);
              }}
              style={{ color: theme.primary_txt }}
              className={
                scheduleFdBox == true
                  ? "dropdown_arrow active"
                  : "dropdown_arrow"
              }
            >
              <FaAngleDown />
            </button>
          </div>

          {scheduleFdBox ? <ScheduleFD /> : ""}
        </StyleFormBox>

        {/* --------  Schedule SH-1  ----------*/}
        <StyleFormBox>
          <div className="flex items-center justify-between">
            <div>
              <h4>Schedule SH-1</h4>
              <p>
                Shareholding of unlisted company other than a startup (Not for
                publically listed companies)
              </p>
            </div>

            <button
              onClick={() => {
                setScheduleSH1Box(true);
              }}
              style={{ color: theme.primary_txt }}
              className={
                scheduleSH1Box == true
                  ? "dropdown_arrow active"
                  : "dropdown_arrow"
              }
            >
              <FaAngleDown />
            </button>
          </div>

          {scheduleSH1Box ? <ScheduleSH1 /> : ""}
        </StyleFormBox>

        {/* --------- Schedule AL-1 ------------- */}
        <StyleFormBox>
          <div className="flex items-center justify-between">
            <div>
              <h4>Schedule AL-1</h4>
              <p>
                Asset and liability of an unlisted company other than startup
              </p>
            </div>

            <button
              onClick={() => {
                setScheduleAL1Box(true);
              }}
              style={{ color: theme.primary_txt }}
              className={
                scheduleAL1Box == true
                  ? "dropdown_arrow active"
                  : "dropdown_arrow"
              }
            >
              <FaAngleDown />
            </button>
          </div>

          {scheduleAL1Box ? <ScheduleAL1 /> : ""}
        </StyleFormBox>

        {/* ----------- Quantitative Details -------- */}
        <StyleFormBox>
          <h4>{strings.income.quantitative_details}</h4>
          <hr className="mb-3" />

          {/* -------- Details of Trading Concern --------  */}
          <StyleFormBox>
            <div className="flex items-center justify-between my-4">
              <h4>{strings.income.details_of_trading_concern}</h4>

              <button
                className="add_button"
                onClick={() => setTradingConcernForm(true)}
              >
                {strings.button.add}
              </button>
            </div>

            {formData?.tradingConcern?.map((item) => (
              <div
                className="flex gap-2 p-3 my-3 align-center justify-between"
                style={{
                  background: addOpacity(theme.primary_bg, 0.2),
                  borderRadius: "10px",
                }}
              >
                <div>
                  <p>Name of Firm : {item?.name}</p>
                </div>

                <div className="action_buttons">
                  <button
                    className="edit_btn"
                    onClick={() => {
                      setTradingConcernForm(true);
                      setEditData(item);
                    }}
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    className="delete_btn"
                    onClick={() =>
                      deleteProfessionalIncomeDataApi(
                        item?.id,
                        "tradingConcern"
                      )
                    }
                  >
                    <RiDeleteBin6Fill />
                  </button>
                </div>
              </div>
            ))}
          </StyleFormBox>

          {/* ------- Details of Manufacturing Concern (Raw Materials) ---------  */}
          <StyleFormBox>
            <div className="flex items-center justify-between my-4">
              <h4>
                {strings.income.details_manufacturing_concern_raw_materials}
              </h4>
              <button
                className="add_button"
                onClick={() => setManufactureRowMaterialForm(true)}
              >
                {strings.button.add}
              </button>
            </div>

            {formData?.manufacturingConcern
              ?.filter((i) => i.isFinishedProduct == false)
              .map((item) => (
                <div
                  className="flex gap-2 p-3 my-3 align-center justify-between"
                  style={{
                    background: addOpacity(theme.primary_bg, 0.2),
                    borderRadius: "10px",
                  }}
                >
                  <div>
                    <p>Name of Firm : {item?.name}</p>
                  </div>

                  <div className="action_buttons">
                    <button
                      className="edit_btn"
                      onClick={() => {
                        setManufactureRowMaterialForm(true);
                        setEditData(item);
                      }}
                    >
                      <FaRegEdit />
                    </button>
                    <button
                      className="delete_btn"
                      onClick={() =>
                        deleteProfessionalIncomeDataApi(
                          item?.id,
                          "manufacturingConcern"
                        )
                      }
                    >
                      <RiDeleteBin6Fill />
                    </button>
                  </div>
                </div>
              ))}
          </StyleFormBox>

          {/* -------- Details of Manufacturing Concern (Finished Products)  ------- */}
          <StyleFormBox>
            <div className="flex items-center justify-between my-4">
              <h4>
                {strings.income.details_manufacturing_concern_finished_products}
              </h4>

              <button
                className="add_button"
                onClick={() => setManufactureFinishedProductForm(true)}
              >
                {strings.button.add}
              </button>
            </div>

            {formData?.manufacturingConcern
              ?.filter((i) => i.isFinishedProduct == true)
              ?.map((item) => (
                <div
                  className="flex gap-2 p-3 my-3 align-center justify-between"
                  style={{
                    background: addOpacity(theme.primary_bg, 0.2),
                    borderRadius: "10px",
                  }}
                >
                  <div>
                    <p>Name of Firm : {item?.name}</p>
                  </div>

                  <div className="action_buttons">
                    <button
                      className="edit_btn"
                      onClick={() => {
                        setManufactureRowMaterialForm(true);
                        setEditData(item);
                      }}
                    >
                      <FaRegEdit />
                    </button>
                    <button
                      className="delete_btn"
                      onClick={() =>
                        deleteProfessionalIncomeDataApi(
                          item?.id,
                          "manufacturingConcern"
                        )
                      }
                    >
                      <RiDeleteBin6Fill />
                    </button>
                  </div>
                </div>
              ))}
          </StyleFormBox>
        </StyleFormBox>
      </AccordianBox>

      {/* ----- Add GST Details ----- */}
      <FormDialogBox
        open={gstFormBox}
        formTitle={strings.income.add_gst_details}
        handleClose={() => {
          setGstFormBox(false);
          setEditFormData({});
        }}
      >
        <AddGst
          data={editFormData}
          handleCallback={(type, e) => {
            setGstFormData(e);
            setGstFormBox(false);
            setEditFormData({});
          }}
        />
      </FormDialogBox>

      {/* ----------- Speculative Income > Other section  -------- */}
      <FormDialogBox
        open={scheduleBpForm}
        formTitle={strings.income.schedule_bp}
        handleClose={() => {
          setScheduleBpForm(false);
          setEditData({});
        }}
      >
        <ScheduleBp
          data={editData}
          handleCallback={(type, e) => {
            const updatedScheduleBP =
              type === "edit"
                ? formData.scheduleBP.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...formData.scheduleBP, e];

            setFormData((prev) => ({
              ...prev,
              scheduleBP: updatedScheduleBP,
            }));
            setEditData({});
            setScheduleBpForm(false);
          }}
        />
      </FormDialogBox>

      {/* ---------- Schedule OI (Other Information) -------- */}
      <FormDialogBox
        open={scheduleOIForm}
        formTitle={strings.income.schedule_oi}
        handleClose={() => {
          setScheduleOIForm(false);
        }}
      >
        <ScheduleOI handleCallback={() => setScheduleOIForm(false)} />
      </FormDialogBox>

      {/* --------- Detail of Trading Concern ---------- */}
      <FormDialogBox
        open={tradingConcernForm}
        formTitle={strings.income.trading_concern}
        handleClose={() => {
          setTradingConcernForm(false);
          setEditData({});
        }}
      >
        <TradingConcern
          unitCategory={incomeData?.unitCategory}
          data={editData}
          handleCallback={(type, e) => {
            const updatedtradingConcern =
              type === "edit"
                ? formData.tradingConcern.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...formData.tradingConcern, e];

            setFormData((prev) => ({
              ...prev,
              tradingConcern: updatedtradingConcern,
            }));
            setEditData({});
            setTradingConcernForm(false);
          }}
        />
      </FormDialogBox>

      {/* ----------- Details of Manufacturing Concern (Raw Materials) ------------ */}
      <FormDialogBox
        open={manufactureRowMaterialForm}
        formTitle={strings.income.manufacture_raw_materials}
        handleClose={() => {
          setManufactureRowMaterialForm(false);
          setEditData({});
        }}
      >
        <ManufactureRowMaterials
          unitCategory={incomeData?.unitCategory}
          isFinishedProduct={false}
          data={editData}
          handleCallback={(type, e) => {
            console.log("=========== ManufactureRowMaterials ==========e");
            const updatedmanufacturingConcern =
              type === "edit"
                ? formData.manufacturingConcern.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...formData.manufacturingConcern, e];

            setFormData((prev) => ({
              ...prev,
              manufacturingConcern: updatedmanufacturingConcern,
            }));
            setManufactureRowMaterialForm(false);
            setEditData({});
          }}
        />
      </FormDialogBox>

      {/* ------------- Details of Manufacturing Concern (Finished Products)  ---------------- */}
      <FormDialogBox
        open={manufactureFinishedProductForm}
        formTitle={strings.income.manufacture_finished_product}
        handleClose={() => {
          setManufactureFinishedProductForm(false);
          setEditData({});
        }}
      >
        <ManufactureRowMaterials
          isFinishedProduct={true}
          unitCategory={incomeData?.unitCategory}
          data={editData}
          handleCallback={(type, e) => {
            const updatedmanufacturingConcern =
              type === "edit"
                ? formData.manufacturingConcern.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...formData.manufacturingConcern, e];

            setFormData((prev) => ({
              ...prev,
              manufacturingConcern: updatedmanufacturingConcern,
            }));
            setEditData({});
            setManufactureFinishedProductForm(false);
          }}
        />
      </FormDialogBox>
    </div>
  );
}
