import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Input from "../../../../styledComponent/Inputs/Input";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import LoadingBox from "../../../DialogBox/LoadingBox";
import Select from "../../../../styledComponent/Inputs/Select";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import styled from 'styled-components';
import { ItrsBusiness } from "../../../../utils/helpers";
export default function RentalProperty({
  handleCallback,
  formsData,
  financialYear,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [activeState, setActiveState] = useState(1);
  const [citiesList, setCitiesList] = useState([]);

  const { strings, states } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);

  const dispatch = useDispatch();
  const validationSchema1 = Yup.object().shape({
    interestPaidOnHL: Yup.number().moreThan(
      0,
      strings.validation_message.amount_greater_than_0
    ),
    unrealizedRent: Yup.number().moreThan(
      0,
      strings.validation_message.amount_greater_than_0
    ),
    municipalTaxes: Yup.number().moreThan(
      0,
      strings.validation_message.amount_greater_than_0
    ),
    annualRentReceived: Yup.number()
      .required(strings.validation_message.required)
      .moreThan(0, strings.validation_message.amount_greater_than_0),
  });
  const validationSchema2 = Yup.object().shape({
    annualRentReceived: Yup.number()
      .required(strings.validation_message.required)
      .moreThan(0, strings.validation_message.amount_greater_than_0),
    address: Yup.object().shape({
      flatDoorNo: Yup.string().required(strings.validation_message.required),
      area: Yup.string().required(strings.validation_message.required),
      city: Yup.string().required(strings.validation_message.required),
      state: Yup.string().required(strings.validation_message.required),
      pinCode: Yup.string().required(strings.validation_message.required).matches(
        /^[1-9][0-9]{5}$/, strings.validation_message.invalid_pin
      ),
    }),
    propertyCoOwners: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        pan: Yup.string().required("PAN is required").matches(
          /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
          strings.validation_message.invalid_pin
        ),
        sharePercentage: Yup.number().required("Percentage is required")
          .min(0)
          .max(100),
      })
    ),
    tenants: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        pan: Yup.string()
          .required(strings.validation_message.required)
          .matches(
            /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
            strings.validation_message.invalid_pan
          ),
        aadhaar: Yup.string()
          .required("Aadhaar number is required")
          .matches(
            /^[2-9]{1}[0-9]{3}-[0-9]{4}-[0-9]{4}$/,
            "Invalid Aadhaar number format"
          )
          .max(16, "Aadhaar number must be at most 16 characters long"),
      })
    ),
  });

  // API : Get citi by state id
  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };
  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    console.log(formsData);
    setLoader(true);
    setFormData({
      id: formsData?.id || "",
      propertyType: 101,
      annualRentReceived: formsData?.annualRentReceived || "",
      municipalTaxes: formsData?.municipalTaxes || "",
      interestPaidOnHL: formsData?.interestPaidOnHL || "",
      unrealizedRent: formsData?.unrealizedRent || "",
      ...(ItrsBusiness.includes(ItrData.form)
        ? {
            intPaidPreConstAmount: formsData?.intPaidPreConstAmount || '',
            intPaidPreConstPeriod:
              formsData?.intPaidPreConstPeriod || "",

            address: {
              id: formsData?.address?.id || "",
              flatDoorNo: formsData?.address?.flatDoorNo || "",
              premiseName: formsData?.address?.premiseName || "",
              street: formsData?.address?.street || "",
              area: formsData?.address?.area || "",
              city: formsData?.address?.city?.id || "",
              state: formsData?.address?.state?.id || "",
              country: 101,
              pinCode: formsData?.address?.pinCode || "",
            },
            propertyCoOwners: formsData?.propertyCoOwners?.map(({createdAt, updatedAt, ...rest})=> rest) || [],
            tenants: formsData?.tenants?.map(({createdAt,updatedAt, ...rest})=> rest) || [],
          }
        : {}),
    });
    setActiveState(formsData?.address?.state?.id || "")
    setLoader(false);
  }, [formsData]);

  return (
    <Root>
      <LoadingBox open={loader} />

      <Formik
        initialValues={formData}
        validationSchema={
          ItrsBusiness.includes(ItrData.form)
            ? validationSchema2
            : validationSchema1
        }
        enableReinitialize={true}
        onSubmit={(values) => {
          handleCallback(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <div>
            <StyleFormBox>
              <h4>{strings.income.income_details} *</h4>
              <div className="flex_input my-2">
                <label>{strings.income.annual_rent_recieved} *</label>
                <Input
                  inputType={"currency"}
                  name={"annualRentReceived"}
                  error={errors.annualRentReceived}
                  value={values.annualRentReceived}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.municipal_tax}</label>
                <Input
                  inputType={"currency"}
                  name={"municipalTaxes"}
                  error={errors.municipalTaxes}
                  value={values.municipalTaxes}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>
                  {strings.income.interest_paid_on_loan_for_this_house_property}
                </label>
                <Input
                  inputType={"currency"}
                  name={"interestPaidOnHL"}
                  error={errors.interestPaidOnHL}
                  value={values.interestPaidOnHL}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>
            <StyleFormBox>
              <h4>{strings.income.unrealized_rent}</h4>
              <div className="flex_input my-2">
                <label>{strings.income.arrears_unrealized_rent_received}</label>
                <Input
                  name={"unrealizedRent"}
                  inputType={"currency"}
                  error={errors.unrealizedRent}
                  value={values.unrealizedRent}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

            {ItrsBusiness.includes(ItrData.form) ? (
              <>
                <StyleFormBox>
                  <h4>
                    {strings.income.interest_paid_during_the_pre_construction}
                  </h4>
                  <div className="flex_input my-2">
                    <label>
                      {
                        strings.income
                          .financial_year_previous_complete_house_property
                      }
                    </label>
                    <Select
                      name={"intPaidPreConstPeriod"}
                      value={values.intPaidPreConstPeriod}
                      onChange={handleChange}
                      error={errors.intPaidPreConstPeriod}
                    >
                      <option value={""}>{strings.label.choose_option}</option>
                      {financialYear?.map((i, ix) => (
                        <option value={i.value} key={ix}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="flex_input my-2">
                    <label>
                      {
                        strings.income
                          .total_interest_amount_paid_during_pre_construction
                      }
                    </label>
                    <Input
                      type={"number"}
                      inputType={"currency"}
                      error={errors.intPaidPreConstAmount}
                      name={"intPaidPreConstAmount"}
                      value={values.intPaidPreConstAmount}
                      onChange={handleChange}
                    />
                  </div>
                </StyleFormBox>

                <FieldArray name="tenants">
                  {({ insert, remove, push }) => (
                    <StyleFormBox>
                      <h4>{strings.income.tenant_details}</h4>
                      {values.tenants?.map((coOwner, index) => (
                        <div className="flex_input my-2" key={index}>
                          <div>
                            <label>{strings.income.name_of_the_tenant}</label>
                            <Input
                              name={`tenants.${index}.name`}
                              value={coOwner.name}
                              placeholder={strings.income.name_of_the_tenant}
                              onChange={handleChange}
                              error={
                                errors.tenants &&
                                errors.tenants[index] &&
                                errors.tenants[index].name
                              }
                            />
                          </div>
                          <div>
                            <label>
                              {strings.income.pan_tan_of_the_tenant}
                            </label>
                            <Input
                              value={coOwner.pan}
                              name={`tenants.${index}.pan`}
                              placeholder={strings.income.pan_tan_of_the_tenant}
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                  setFieldValue(`tenants.${index}.pan`, e.target.value.toUpperCase());
                                }
                              }}
                              error={
                                errors.tenants &&
                                errors.tenants[index] &&
                                errors.tenants[index].pan
                              }
                            />
                          </div>
                          <div>
                            <label>
                              {strings.income.aadhaar_of_the_tenant}
                            </label>
                            <Input
                              inputType={"aadhaar"}
                              name={`tenants.${index}.aadhaar`}
                              value={coOwner.aadhaar}
                              placeholder={strings.income.aadhaar_of_the_tenant}
                              onChange={handleChange}
                              error={
                                errors.tenants &&
                                errors.tenants[index] &&
                                errors.tenants[index].aadhaar
                              }
                            />
                          </div>
                          <button
                            className="delete_button mt-6"
                            onClick={() => remove(index)}
                          >
                            <RiDeleteBin6Fill />
                          </button>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          type="button"
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({ name: "", pan: "", aadhaar: "" });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </StyleFormBox>
                  )}
                </FieldArray>

                <StyleFormBox>
                  <h4>{strings.income.your_house_address}</h4>
                  <p>
                    {
                      strings.income
                        .you_can_provide_either_your_current_address_or_permanent_address
                    }
                  </p>

                  <div className="flex_input my-2">
                    <label>
                      {strings.address.flat +
                        " / " +
                        strings.address.door_no +
                        " / " +
                        strings.address.block_no}
                    </label>
                    <Input
                      name={"address.flatDoorNo"}
                      error={errors.address && errors.address.flatDoorNo}
                      value={values.address && values.address.flatDoorNo}
                      placeholder={strings.address.flat}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>{strings.address.premise_name}</label>
                    <Input
                      name={"address.premiseName"}
                      value={values?.address?.premiseName}
                      error={errors?.address && errors.address.premiseName}
                      placeholder={strings.address.premise_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>
                      {strings.address.road + " / " + strings.address.street}
                    </label>
                    <Input
                      name={"address.street"}
                      value={values.address?.street}
                      error={errors.address && errors.address.street}
                      placeholder={strings.address.road}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>{strings.address.area_locality}</label>
                    <Input
                      name={"address.area"}
                      value={values.address?.area}
                      error={errors.address && errors.address.area}
                      placeholder={strings.address.road}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>{strings.address.pincode}</label>
                    <Input
                      name={"address.pinCode"}
                      type={"number"}
                      error={errors.address && errors.address.pinCode}
                      value={values.address?.pinCode}
                      placeholder={strings.address.pincode}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>{strings.address.state}</label>
                    <Select
                      name={"address.state"}
                      error={errors.address && errors.address.state}
                      value={values.address && values.address.state}
                      onChange={(e) => {
                        handleChange(e);
                        setActiveState(e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        {strings.label.choose_option}
                      </option>
                      {states?.map((i, ix) => (
                        <option value={i.id} key={ix}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="flex_input my-2">
                    <label>{strings.address.city}</label>

                    <Select
                      name={"address.city"}
                      error={errors.address && errors.address.city}
                      value={values.address && values.address.city}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        {strings.label.choose_option}
                      </option>
                      {citiesList?.map((i, ix) => (
                        <option value={i.id} key={ix}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                </StyleFormBox>

                <FieldArray name="propertyCoOwners">
                  {({ insert, remove, push }) => (
                    <StyleFormBox>
                      <h4>{strings.address.co_owners_details}</h4>
                      <p>{strings.address.in_case_this_property_is_jointly}</p>
                      {values.propertyCoOwners?.map((coOwner, index) => (
                        <div className="flex_input my-2" key={index}>
                          <div>
                            <label>{strings.address.name_of_co_owner}</label>
                            <Input
                              name={`propertyCoOwners.${index}.name`}
                              value={coOwner.name}
                              placeholder={strings.address.name_of_co_owner}
                              onChange={handleChange}
                              error={
                                errors.propertyCoOwners &&
                                errors.propertyCoOwners[index] &&
                                errors.propertyCoOwners[index].name
                              }
                            />
                          </div>
                          <div>
                            <label>{strings.address.pan_of_co_owner}</label>
                            <Input
                              name={`propertyCoOwners.${index}.pan`}
                              value={coOwner.pan}
                              placeholder={strings.address.pan_of_co_owner}
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                  setFieldValue(`propertyCoOwners.${index}.pan`, e.target.value.toUpperCase());
                                }
                              }}
                              error={
                                errors.propertyCoOwners &&
                                errors.propertyCoOwners[index] &&
                                errors.propertyCoOwners[index].pan
                              }
                            />
                          </div>
                          <div class="shareper">
                            <label>{strings.address.percentage_stage}</label>
                            <Input
                              inputType={"percentage"}
                              name={`propertyCoOwners.${index}.sharePercentage`}
                              value={coOwner.sharePercentage}
                              placeholder={strings.address.percentage_stage}
                              onChange={handleChange}
                              error={
                                errors.propertyCoOwners &&
                                errors.propertyCoOwners[index] &&
                                errors.propertyCoOwners[index].sharePercentage
                              }
                            />
                          </div>
                          <button
                            className="delete_button mt-6"
                            onClick={() => remove(index)}
                          >
                            <RiDeleteBin6Fill />
                          </button>
                        </div>
                      ))}

                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({ name: "", pan: "", sharePercentage: "" });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </StyleFormBox>
                  )}
                </FieldArray>
              </>
            ) : (
              ""
            )}

            <button className="primary_button my-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </Root>
  );
}
const Root = styled.section`
.shareper .error {
    bottom: -18px;
}`