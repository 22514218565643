import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import ToggelButton from "../../../../styledComponent/Button/ToggelButton";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "../../../../styledComponent/Inputs/Select";
import Input from "../../../../styledComponent/Inputs/Input";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { toast } from "react-toastify";
import { removeEmptyKeys } from "../../../../utils/helpers";
import {
  saveStocksAction,
  updateStocksAction,
} from "../../../../redux/itr/action";

export default function AddEquityFunds({
  handleCallback,
  editForm,
  mutualFundsAssets,
  stocksAssets,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);

  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    typeOfAssets: Yup.string().required(strings.validation_message.required),
    dateOfSale: Yup.string().required(strings.validation_message.required),
    dateOfExercise: Yup.string().required(strings.validation_message.required),
    totalSalePrice: Yup.string().required(strings.validation_message.required),
    totalPurchasePrice: Yup.string().required(
      strings.validation_message.required
    ),
  });

  const handleSubmitForm = (values) => {
    values = {...formData,...values}
    if (values.id) {
      setLoader(true);
      dispatch(
        updateStocksAction(removeEmptyKeys(values), (e) => {
          console.log(e);
          if (e.status === 200) {
            toast.success("Successfully update data");
            setLoader(false);
            handleCallback(e.data);
          } else {
            setLoader(false);
          }
        })
      );
    } else {
      setLoader(true);
      let { id, ...newData } = values;
      dispatch(
        saveStocksAction(removeEmptyKeys(newData), (e) => {
          console.log(e);
          if (e.status === 200) {
            toast.success("Successfully upload data");
            setLoader(false);
            handleCallback(e.data);
          } else {
            setLoader(false);
          }
        })
      );
    }
  };

  useEffect(() => {
    setLoader(true);
    setFormData({
      typeOfAssets: editForm?.typeOfAssets?.id || "",
      dateOfSale: editForm?.dateOfSale?.split("T")[0] || "",
      types: editForm?.types || "stocks",
      dateOfExercise: editForm?.dateOfExercise?.split("T")[0] || "",
      descriptionOfAssets: editForm?.descriptionOfAssets || "",
      totalSalePrice: editForm?.totalSalePrice || "",
      transferExpenses: editForm?.transferExpenses || "",
      totalPurchasePrice: editForm?.totalPurchasePrice || "",
      paidStt: editForm?.paidStt || true,
      id: editForm?.id || "",
      itrId: ItrData.id,
    });
    setLoader(false);
  }, [editForm, ItrData]);
  return (
    <Root theme={theme}>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <div>
            <div className="action_tabs">
              <button
                className={values.types === "stocks" ? "active" : ""}
                onClick={() => {
                  setFieldValue("types", "stocks");
                }}
              >
                {strings.income.stocks}
              </button>
              <button
                className={values.types === "mutualFunds" ? "active" : ""}
                onClick={() => {
                  setFieldValue("types", "mutualFunds");
                }}
              >
                {strings.income.mutual_funds}
              </button>
            </div>

            <div>
              <StyleFormBox>
                <div className="flex_input my-2">
                  <label>{strings.income.select_asset} *</label>
                  <Select
                    value={values.typeOfAssets}
                    error={errors.typeOfAssets}
                    name={"typeOfAssets"}
                    onChange={handleChange}
                  >
                    <option value={""}>
                      {strings.label.choose_option}
                    </option>
                    {values.types === "stocks"
                      ? stocksAssets?.map((i, ix) => (
                          <option value={i.id} key={ix}>
                            {i.name}
                          </option>
                        ))
                      : mutualFundsAssets?.map((i, ix) => (
                          <option value={i.id} key={ix}>
                            {i.name}
                          </option>
                        ))}
                  </Select>
                </div>

                <div className="flex_input my-2">
                  <label>{strings.income.date_of_sale} *</label>
                  <Input
                    inputType={"date"}
                    name={"dateOfSale"}
                    value={values.dateOfSale}
                    error={errors.dateOfSale}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-2">
                  <label>{strings.income.date_of_exercise} *</label>
                  <Input
                    inputType={"date"}
                    name={"dateOfExercise"}
                    value={values.dateOfExercise}
                    error={errors.dateOfExercise}
                    onChange={handleChange}
                  />
                </div>
              </StyleFormBox>

              <StyleFormBox>
                <h4>{strings.income.add_details_asset}</h4>
                <hr />
                <div className="flex_input my-2">
                  <label>{strings.income.description_of_asset_sold}</label>
                  <Input
                    name={"descriptionOfAssets"}
                    value={values.descriptionOfAssets}
                    error={errors.descriptionOfAssets}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-2">
                  <label>{strings.income.total_sale_price} *</label>
                  <Input
                    inputType={"currency"}
                    name={"totalSalePrice"}
                    value={values.totalSalePrice}
                    error={errors.totalSalePrice}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-2">
                  <label>{strings.income.transfer_expenses}</label>
                  <Input
                    inputType={"currency"}
                    name={"transferExpenses"}
                    value={values.transferExpenses}
                    error={errors.transferExpenses}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-2">
                  <label>{strings.income.total_purchase_price} *</label>
                  <Input
                    inputType={"currency"}
                    name={"totalPurchasePrice"}
                    value={values.totalPurchasePrice}
                    error={errors.totalPurchasePrice}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-2 justify-between">
                  <h4>{strings.income.did_you_pay_stt}</h4>
                  <ToggelButton
                    value={values.paidStt}
                    handleClick={() =>
                      setFieldValue("paidStt", !values.paidStt)
                    }
                  />
                </div>
              </StyleFormBox>
              
            </div>
            <button className="primary_button mt-4" onClick={handleSubmit} disabled={loader} >
                {strings.button.submit}
              </button>
          </div>
        )}
      </Formik>
    </Root>
  );
}
const Root = styled.section`
  .action_tabs {
    margin-top: 10px;
    > button {
      padding: 10px;
      width: 50%;
      background-color: ${(p) => p.theme.tertiary_bg};
      border: 1px solid transparent;
      font-weight: 600;
      &:first-child {
        border-radius: 10px 0px 0px 10px;
      }
      &:last-child {
        border-radius: 0px 10px 10px 0px;
      }
      &.active {
        color: ${(p) => p.theme.primary_bg};
        border: 1px solid ${(p) => p.theme.primary_bg};
      }
    }
  }
`;
