export  const addOpacity = (color, opacity) => {
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// Convert Object to array 
export const convertObjectIntoArray = (obj) => {
  let ary = Object.keys(obj);
  ary = ary.map((item) => {
    return {
      keyname: item,
      value: obj[item],
    };
  });
  return ary || [];
};

// convert array into object 
export const arrayToObject = (ary) => {
  return ary.reduce((obj, item) => {
    if (item.keyname) {
      obj[item.keyname] = item.value;
    }
    return obj;
  }, {});
};

export const removeBlankObjects = (payload) => {
  for (let key in payload) {
    if (typeof payload[key] === "object" && !Array.isArray(payload[key])) {
      if (Object.keys(payload[key]).length === 0) {
        delete payload[key];
      } else {
        removeBlankObjects(payload[key]);
      }
    } else if (
      typeof payload[key] === "object" &&
      Array.isArray(payload[key])
    ) {
      if (payload[key].length === 0) {
        delete payload[key];
      }
    }
  }
  return payload;
};

export const removeBlankKey = (obj) => {
  let newData = Object.assign({}, obj)
  for(let keys in newData){
    if(typeof newData[keys] != "number" ){
      delete newData[keys]
    }
  }
  return newData
}



export const validatePan = (pan)=>{
  const panPattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
  return panPattern.test(pan)
}

// This function remove keys with blank value of blank objects
export const removeEmptyKeys = (obj) => {
  for (let prop in obj) {
    if (obj[prop] === "") {
      delete obj[prop];
    } else if (typeof obj[prop] === "object" && Object.keys(obj[prop]).length === 0) {
      delete obj[prop];
    }
  }
  return obj;
};

// Date Formate from "2024-04-14T18:30:00.000Z" to "2024-04-14"
export const dateFormat = (date)=>{
  return date.split('T')[0]
}


//  =======================================   Format Aadhaar Enrolment Number  
export const formatAadhaarEnrolmentNo = (value) => {

  return value;
};

export const ItrsBusiness = ["ITR_2", "ITR_3", "ITR_5", "ITR_6"];