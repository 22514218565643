import React, { useEffect, useState } from "react";
import SalaryIncome from "../CommonScreens/GrossIncome/SalaryIncome";
import OtherSourceIncome from "../CommonScreens/GrossIncome/OtherSourceIncome";
import HousePropertyIncome from "../CommonScreens/GrossIncome/HousePropertyIncome";
import { useDispatch, useSelector } from "react-redux";
import { getGrossIncomeAction } from "../../redux/itr/action";
import LoadingBox from "../DialogBox/LoadingBox";

export default function GrossIncome({ handleNext }) {
  const [incomeData, setIncomeData] = useState({});
  const [loading, setLoading] = useState(false);
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const getGrossIncomeApi = () => {
    setLoading(true);
    dispatch(
      getGrossIncomeAction(ItrData.id, (data) => {
        if (data.status == 200) {
          setIncomeData(data.data.grossIncomeDetails);
        }
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    getGrossIncomeApi();
  }, []);

  return (
    <div>
      <LoadingBox open={loading} />
      <SalaryIncome />
      <OtherSourceIncome
        getData={incomeData?.otherIncomeSource}
        handleRefresh={() => getGrossIncomeApi()}
      />

      <HousePropertyIncome
        data={incomeData?.housePropertyIncome}
        handleCallback={() => getGrossIncomeApi()}
      />

      <button
        type="submit"
        className="primary_button mt-4"
        onClick={handleNext}
      >
        {strings.button.next}
      </button>
    </div>
  );
}
