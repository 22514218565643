import React, { useEffect, useState } from "react";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import CurrencyField from "../../../../styledComponent/Inputs/CurrencyField";
import AddActionButton from "../../../../styledComponent/Button/AddActionButton";
import Input from "../../../../styledComponent/Inputs/Input";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import BadDebts from "./BadDebts";
import {
  getDetailBalanceSheet6Action,
  saveDetailBalanceSheet6Action,
} from "../../../../redux/itr/action";
import { removeBlankKey } from "../../../../utils/helpers";
import LoadingBox from "../../../DialogBox/LoadingBox";

export default function DetailBalanceSheet6() {
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [badDebtPop, setBadDebtPop] = useState(false);
  const [badDebtData, setBadDebtData] = useState([]);
  const [detailBalanceSheet, setDetailBalanceSheet] = useState("")
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const [sourcesOfFunds6, setSourcesOfFunds6] = useState({
    authorised: "",
    issuedSubscribedFullyPay: "",
    subscribedNotFullyPay: "",

    RSCapitalReserve: "",
    RSCapitalRedemptionReserve: "",
    RSSecuritiesPremiumReserve: "",
    RSDebentureRedemptionReserve: "",
    RSRevaluationReserve: "",
    RSShareOptionsOutstandingAmount: "",
    RSOtherReserve: [{ name: "", amount: "" }],

    surplusBalanceInPAndL: "",

    moneyReceivedAgainstShareWarrant: "",

    shareAppMoneyPendingOneYear: "",
    shareAppMoneyPendingMoreThanYear: "",

    bondDebentureForeignCurrency: "",
    bondDebentureRupee: "",

    termLoansForeignCurrency: "",
    rupeeLoanFromBanks: "",
    rupeeLoanFromOthers: "",

    LTBdeferredPaymentLiabilities: "",
    LTBDepositsFromRelatedParties: "",
    LTBOtherDeposits: "",
    LTBLoanAdvancesFromRelatedParties: "",
    LTBOtherLoanAdvances: "",
    LTBLongTermMaturities: "",

    deferredTaxLiabilities: "",
    otherLTTradePayables: "",
    otherLTOthers: "",

    LTProvisionEmployeeBenefits: "",
    LTProvisionOthers: "",

    LRDFromBank: "",
    LRDFromNonBankingFinance: "",
    LRDFromOtherFinancialInst: "",
    LRDFromOthers: "",

    STBDepositsFromRelatedParties: "",
    STBLoansAndAdvances: "",
    STBOtherLoansAndAdvances: "",
    STBOtherDeposits: "",

    TPOutstandingMoreThanYear: "",
    TPOthers: "",

    OCLLongTermDebt: "",
    OCLFinanceLeaseObligations: "",
    OCLInterestAccruedNotDue: "",
    OCLInterestAccuredDue: "",
    OCLIncomeReceivedAdvance: "",
    OCLUnpaidDividends: "",
    OCLApplicationMoneyReceived: "",
    OCLUnpaidMaturedDeposits: "",
    OCLUnpainMaturedDebentures: "",
    OCLOtherPayables: "",

    STPEmployeeBenefit: "",
    STPIncomeTax: "",
    STPProposedDividend: "",
    STPTaxOnDividend: "",
    STPOthers: "",
  });

  const [applicationsOfFunds6, setApplicationsOfFunds6] = useState({
    TAGrossBlock: "",
    TADepreciation: "",
    TAImpairmentLosses: "",

    IAGrossBlock: "",
    IAAmortization: "",
    IAImpairmentLosses: "",

    FACapitalWorkInProgress: "",
    FAIntangibleAssetsUnderDev: "",

    NCIInvestmentInProperty: "",
    IEIListedEquities: "",
    IEIUnlistedEquities: "",

    NCIPreferenceShares: "",
    NCIGovtTrustSecurities: "",
    NCIDebentureOrBonds: "",
    NCIMutualFunds: "",
    NCIPartenershipFirms: "",
    NCIOtherInvestments: "",

    deferredTaxAssets: "",
    LTLCapitalAdvances: "",
    LTLSecurityDeposits: "",
    LTLLoanAdvancesRelatedParties: "",
    LTLOtherLoansAdvances: "",

    LTLDvPUrposeOfBusiness: "",
    LTLDvNotBusinessPurpose: "",
    LTLDvGivenToShareHolder: "",

    LTTSecuredConsideredGood: "",
    LTTUnsecuredConsideredGood: "",
    LTTDoubtful: "",

    ONAOthers: "",

    nonCurrentAssetsIncludedInEIII: "",

    INEListedEquities: "",
    INEUnlistedEquities: "",

    CIPreferenceShares: "",
    CIGovtTrustSecurities: "",
    CIDebenturesOrBonds: "",
    CIMutualFunds: "",
    CIPartnershipFirms: "",
    CIOtherInvestment: "",

    InvtRawMaterials: "",
    InvtWorkInProgress: "",
    InvtFinishedGoods: "",
    InvtStockInTrade: "",
    InvtStoresAndSapres: "",
    InvtLooseTools: "",
    InvtOthers: "",

    TROutstandingMoreThan6Month: "",
    TROthers: "",

    CCBalanceWithBank: "",
    CCChequeDraft: "",
    CCCashInHand: "",
    CCOthers: "",

    STLLoanAndAdvances: "",
    STLOthers: "",

    STLEiiiForBusiness: "",
    STLEiiiNotForBusiness: "",
    STLEiiiGivenToShareholder: "",
    otherCurrentAssets: "",
  });

  const [manufacturingAccount6, setManufacturingAccount6] = useState({
    OIRawMaterial: "",
    OIWorkInProgress: "",

    OIPurchases: "",
    OIDirectWages: "",

    DECarriageInward: "",
    DEPowerAndFuel: "",
    DEOther: "",
    FOIndirectWages: "",
    FOFactoryRent: "",
    FOFactoryInsurance: "",
    FOFactoryFuelAndPower: "",
    FOFactoryGeneralExpenses: "",
    FODepreciationFactoryMachinery: "",

    CSRawMaterial: "",
    CSWorkInProgress: "",
  });

  const [tradingAccount6, setTradingAccount6] = useState({
    SGRGoods: "",
    SGRServices: "",
    SGROtherOperatingRevenue: [{ name: "", amount: "" }],

    grossReceiptsProfession: "",
    DTCUnionExciseDuties: "",
    DTCserviceTax: "",
    DTCVat: "",
    DTCCentralGST: "",
    DTCStateGST: "",
    DTCIntegratedGST: "",
    DTCUTGST: "",
    DTCAnyOther: "",

    closingStockFinishedStocks: "",

    openingStockFG: "",
    purchases: "",

    DECarriageInward2: "",
    DEPowerAndFuel2: "",
    otherDE: [{ name: "", amount: "" }],

    DTPCustomDuty: "",
    DTPCounterVeiling: "",
    DTPSpecialAdditionalDuty: "",
    DTPUnionExciseDuty: "",
    DTPServiceTax: "",
    DTPVat: "",
    DTPCentralGST: "",
    DTPStateGST: "",
    DTPIntegratedGST: "",
    DTPUTGST: "",
    DTPAnyOther: "",

    turnoverFromIntraday: "",
    incomeFromIntraday: "",
  });

  const [profitAndLossAccount6, setProfitAndLossAccount6] = useState({
    OIRent: "",
    OIcommission: "",
    OIDividendIncome: "",
    OIInterestIncome: "",
    OIProfitOnSaleAssets: "",
    OIProfitOnSaleInvestment: "",
    OIProfitOnSaleOtherInvestment: "",
    OIGainOnAccount: "",
    OIProfitOnCenversion: "",
    OIAgriculturalIncome: "",
    OIAnyOtherIncome: [{ name: "", amount: "" }],

    freightOutward: "",
    consumptionStoreSpare: "",
    powerAndFuel: "",
    rents: "",
    repairsBuilding: "",
    repairsMachinery: "",
    CTESalaries: "",
    CTEBonus: "",
    CTEReimbursementMedical: "",
    CTELeaveEncashment: "",
    CTELeaveTravel: "",
    CTESuperannuationFund: "",
    CTEProvidentFund: "",
    CTEGratuityFund: "",
    CTEAnyOtherFund: "",
    CTEAnyOtherBenefit: "",

    CTEAmountPaidToNR: "",
    IMedicalInsurance: "",
    ILiftInsurance: "",
    IKeymanInsurance: "",
    IOtherInsurance: "",

    workmenAndStaff: "",
    entertainment: "",
    hospitality: "",
    conference: "",
    salesPromotion: "",
    advertisement: "",
    ComPaidOutsideIndia: "",
    ComToOthers: "",

    RoyPaidOutsideIndia: "",
    RoyToOthers: "",

    PCFPaidOutsideIndia: "",
    PCFToOthers: "",

    hotelBoardingLodging: "",
    travelingExpenses: "",
    foreignTravelling: "",
    conveyanceExpenses: "",
    telephoneExpenses: "",
    guestHouseExpenses: "",
    clubExpenses: "",
    festivalCelebration: "",
    scholarship: "",
    gift: "",
    donation: "",
    RTUnionExciseDuty: "",
    RTServiceTax: "",
    RTVat: "",
    RTCess: "",
    RTCentralGST: "",
    RTSTateGST: "",
    RTIntegratedGST: "",
    RTUTGST: "",
    RTAnyOther: "",

    auditFee: "",
    otherExpenses: [{ name: "", amount: "" }],

    badDebts: [{ pan: "", amount: "" }],
    otherMoreThanLakhNoPan: "",
    OtherLessthanLakh: "",

    provisionForBadDoutfulDebt: "",
    otherProvisions: "",

    interestPaidOutsideIndia: "",
    interestPaidOutsideOthers: "",
    interestPaidInIndia: "",
    interestPainInIndiaOthers: "",

    depreciationAmortisation: "",

    provisionForCurrentTax: "",
    provisionForDeferredTax: "",

    balanceBroughtForwardPreviousYear: "",

    AppTransferToReserves: "",
    AppProposedDividend: "",
    AppTaxOnDividend: "",
    AppAppropriationCSR: "",
    AppAnyOther: "",
  });

  let totalShareCapital =
    Number(sourcesOfFunds6.issuedSubscribedFullyPay) +
    Number(sourcesOfFunds6.subscribedNotFullyPay);

  let RSTotalOtherReserve =
    sourcesOfFunds6?.RSOtherReserve?.length > 0
      ? sourcesOfFunds6?.RSOtherReserve?.reduce((total, current) => {
          const value = Number(current.amount || 0);
          return total + value;
        }, 0)
      : 0;

  let totalReservesAndSurplus =
    Number(sourcesOfFunds6.RSCapitalReserve) +
    Number(sourcesOfFunds6.RSCapitalRedemptionReserve) +
    Number(sourcesOfFunds6.RSSecuritiesPremiumReserve) +
    Number(sourcesOfFunds6.RSDebentureRedemptionReserve) +
    Number(sourcesOfFunds6.RSRevaluationReserve) +
    Number(sourcesOfFunds6.RSShareOptionsOutstandingAmount) +
    Number(RSTotalOtherReserve) +
    Number(sourcesOfFunds6.surplusBalanceInPAndL);

  let totalShareholdersFund =
    Number(totalShareCapital) +
    Number(totalReservesAndSurplus) +
    Number(sourcesOfFunds6.moneyReceivedAgainstShareWarrant);

  let totalShareApplicationMoney =
    Number(sourcesOfFunds6.shareAppMoneyPendingOneYear) +
    Number(sourcesOfFunds6.shareAppMoneyPendingMoreThanYear);

  let bondDebentureTotal =
    Number(sourcesOfFunds6.bondDebentureForeignCurrency) +
    Number(sourcesOfFunds6.bondDebentureRupee);

  let rupeeLoanTotal =
    Number(sourcesOfFunds6.rupeeLoanFromOthers) +
    Number(sourcesOfFunds6.rupeeLoanFromBanks);

  let totalTermLoan =
    Number(sourcesOfFunds6.termLoansForeignCurrency) + Number(rupeeLoanTotal);

  let totallongTermBorrowings =
    Number(bondDebentureTotal) +
    Number(totalTermLoan) +
    Number(sourcesOfFunds6.LTBdeferredPaymentLiabilities) +
    Number(sourcesOfFunds6.LTBDepositsFromRelatedParties) +
    Number(sourcesOfFunds6.LTBOtherDeposits) +
    Number(sourcesOfFunds6.LTBLoanAdvancesFromRelatedParties) +
    Number(sourcesOfFunds6.LTBOtherLoanAdvances) +
    Number(sourcesOfFunds6.LTBLongTermMaturities);

  let TotalOtherLongTermLiabilities =
    Number(sourcesOfFunds6.otherLTTradePayables) +
    Number(sourcesOfFunds6.otherLTOthers);

  let totalLTProvisions =
    Number(sourcesOfFunds6.LTProvisionEmployeeBenefits) +
    Number(sourcesOfFunds6.LTProvisionOthers);

  let totalNonCurrentLiabilities =
    Number(totallongTermBorrowings) +
    Number(sourcesOfFunds6.deferredTaxLiabilities) +
    Number(TotalOtherLongTermLiabilities) +
    Number(totalLTProvisions);

  let totalLoansRepayableOnDemand =
    Number(sourcesOfFunds6.LRDFromBank) +
    Number(sourcesOfFunds6.LRDFromNonBankingFinance) +
    Number(sourcesOfFunds6.LRDFromOtherFinancialInst) +
    Number(sourcesOfFunds6.LRDFromOthers);

  let totalShortTermBorrowing =
    Number(totalLoansRepayableOnDemand) +
    Number(sourcesOfFunds6.STBDepositsFromRelatedParties) +
    Number(sourcesOfFunds6.STBLoansAndAdvances) +
    Number(sourcesOfFunds6.STBOtherLoansAndAdvances) +
    Number(sourcesOfFunds6.STBOtherDeposits);

  let totalTradePayables =
    Number(sourcesOfFunds6.TPOutstandingMoreThanYear) +
    Number(sourcesOfFunds6.TPOthers);

  let totalOtherCurrentLiabilities =
    Number(sourcesOfFunds6.OCLLongTermDebt) +
    Number(sourcesOfFunds6.OCLFinanceLeaseObligations) +
    Number(sourcesOfFunds6.OCLInterestAccruedNotDue) +
    Number(sourcesOfFunds6.OCLInterestAccuredDue) +
    Number(sourcesOfFunds6.OCLIncomeReceivedAdvance) +
    Number(sourcesOfFunds6.OCLUnpaidDividends) +
    Number(sourcesOfFunds6.OCLApplicationMoneyReceived) +
    Number(sourcesOfFunds6.OCLUnpaidMaturedDeposits) +
    Number(sourcesOfFunds6.OCLUnpainMaturedDebentures) +
    Number(sourcesOfFunds6.OCLOtherPayables);

  let totalShortTermProvisions =
    Number(sourcesOfFunds6.STPEmployeeBenefit) +
    Number(sourcesOfFunds6.STPIncomeTax) +
    Number(sourcesOfFunds6.STPProposedDividend) +
    Number(sourcesOfFunds6.STPTaxOnDividend) +
    Number(sourcesOfFunds6.STPOthers);

  let totalCurrentLiabilities =
    Number(totalShortTermProvisions) +
    Number(totalOtherCurrentLiabilities) +
    Number(totalTradePayables) +
    Number(totalShortTermBorrowing);

  let totalEquityAndLiabilities =
    totalNonCurrentLiabilities +
    totalCurrentLiabilities +
    totalShareApplicationMoney +
    totalShareholdersFund;

  // *********************************************** //

  let TANetBlock =
    Number(applicationsOfFunds6.TAGrossBlock) -
    Number(applicationsOfFunds6.TADepreciation) -
    Number(applicationsOfFunds6.TAImpairmentLosses);

  let IANetBlock =
    Number(applicationsOfFunds6.IAGrossBlock) -
    Number(applicationsOfFunds6.IAAmortization);
  Number(applicationsOfFunds6.IAImpairmentLosses);

  let totalFixedAsssets =
    Number(TANetBlock) +
    Number(IANetBlock) +
    Number(applicationsOfFunds6.FACapitalWorkInProgress) +
    Number(applicationsOfFunds6.FAIntangibleAssetsUnderDev);

  let totalIEI =
    Number(applicationsOfFunds6.IEIListedEquities) +
    Number(applicationsOfFunds6.IEIUnlistedEquities);

  let totalNonCurrentInvestment =
    Number(applicationsOfFunds6.NCIInvestmentInProperty) +
    Number(totalIEI) +
    Number(applicationsOfFunds6.NCIPreferenceShares) +
    Number(applicationsOfFunds6.NCIGovtTrustSecurities) +
    Number(applicationsOfFunds6.NCIDebentureOrBonds) +
    Number(applicationsOfFunds6.NCIMutualFunds) +
    Number(applicationsOfFunds6.NCIPartenershipFirms) +
    Number(applicationsOfFunds6.NCIOtherInvestments);

  let totalLongTermLoansAdvances =
    Number(applicationsOfFunds6.LTLCapitalAdvances) +
    Number(applicationsOfFunds6.LTLSecurityDeposits) +
    Number(applicationsOfFunds6.LTLLoanAdvancesRelatedParties) +
    Number(applicationsOfFunds6.LTLOtherLoansAdvances);

  let totalLTT =
    Number(applicationsOfFunds6.LTTSecuredConsideredGood) +
    Number(applicationsOfFunds6.LTTUnsecuredConsideredGood) +
    Number(applicationsOfFunds6.LTTDoubtful);

  let ONATotal = Number(totalLTT) + Number(applicationsOfFunds6.ONAOthers);

  let totalOtherNonCurrentAssets =
    Number(ONATotal) +
    Number(totalLongTermLoansAdvances) +
    Number(applicationsOfFunds6.deferredTaxAssets) +
    Number(totalNonCurrentInvestment) +
    Number(totalFixedAsssets);

  let totalINE =
    Number(applicationsOfFunds6.INEListedEquities) +
    Number(applicationsOfFunds6.INEUnlistedEquities);

  let totalCurrentInvestments =
    Number(totalINE) +
    Number(applicationsOfFunds6.CIPreferenceShares) +
    Number(applicationsOfFunds6.CIGovtTrustSecurities) +
    Number(applicationsOfFunds6.CIDebenturesOrBonds) +
    Number(applicationsOfFunds6.CIMutualFunds) +
    Number(applicationsOfFunds6.CIPartnershipFirms) +
    Number(applicationsOfFunds6.CIOtherInvestment);

  let totalInventories =
    Number(applicationsOfFunds6.InvtRawMaterials) +
    Number(applicationsOfFunds6.InvtWorkInProgress) +
    Number(applicationsOfFunds6.InvtFinishedGoods) +
    Number(applicationsOfFunds6.InvtStockInTrade) +
    Number(applicationsOfFunds6.InvtStoresAndSapres) +
    Number(applicationsOfFunds6.InvtLooseTools) +
    Number(applicationsOfFunds6.InvtOthers);

  let totalTradeReceivables =
    Number(applicationsOfFunds6.TROutstandingMoreThan6Month) +
    Number(applicationsOfFunds6.TROthers);

  let totalCashAndCashEquivalents =
    Number(applicationsOfFunds6.CCBalanceWithBank) +
    Number(applicationsOfFunds6.CCChequeDraft) +
    Number(applicationsOfFunds6.CCCashInHand) +
    Number(applicationsOfFunds6.CCOthers);

  let totalShortTermLoans =
    Number(applicationsOfFunds6.STLLoanAndAdvances) +
    Number(applicationsOfFunds6.STLOthers);

  let totalCurrentAssets =
    Number(applicationsOfFunds6.otherCurrentAssets) +
    Number(totalShortTermLoans) +
    Number(totalCashAndCashEquivalents) +
    Number(totalTradeReceivables) +
    Number(totalInventories) +
    Number(totalCurrentInvestments);

  let totalAssets = totalOtherNonCurrentAssets + totalCurrentAssets;

  // *********************************************** //

  let totalRawAndProgress = Number(manufacturingAccount6.OIRawMaterial);
  Number(manufacturingAccount6.OIWorkInProgress);

  let OIDirectExpenses = Number(manufacturingAccount6.DECarriageInward);
  Number(manufacturingAccount6.DEPowerAndFuel);
  Number(manufacturingAccount6.DEOther);

  let totalFactoryOverheads = Number(manufacturingAccount6.FOIndirectWages);
  Number(manufacturingAccount6.FOFactoryRent);
  Number(manufacturingAccount6.FOFactoryInsurance);
  Number(manufacturingAccount6.FOFactoryFuelAndPower);
  Number(manufacturingAccount6.FOFactoryGeneralExpenses);
  Number(manufacturingAccount6.FODepreciationFactoryMachinery);

  let totalDebtsToManufacturingAccount =
    Number(totalFactoryOverheads) +
    Number(OIDirectExpenses) +
    Number(manufacturingAccount6.OIPurchases) +
    Number(manufacturingAccount6.OIDirectWages) +
    Number(totalRawAndProgress);

  let totalClosingStock =
    Number(manufacturingAccount6.CSRawMaterial) +
    Number(manufacturingAccount6.CSWorkInProgress);

  let goodsProducedMinusTransferredTA =
    Number(totalDebtsToManufacturingAccount) - Number(totalClosingStock);

  // *********************************************** //

  let totalOtherOperatingRevenue =
    tradingAccount6.SGROtherOperatingRevenue.length > 0
      ? tradingAccount6.SGROtherOperatingRevenue.reduce((total, current) => {
          const value = Number(current.amount || 0);
          return total + value;
        }, 0)
      : 0;

  let totalSGR = Number(tradingAccount6.SGRGoods);
  Number(tradingAccount6.SGRServices);
  Number(totalOtherOperatingRevenue);

  let totalDTC =
    Number(tradingAccount6.DTCUnionExciseDuties) +
    Number(tradingAccount6.DTCserviceTax) +
    Number(tradingAccount6.DTCVat) +
    Number(tradingAccount6.DTCCentralGST) +
    Number(tradingAccount6.DTCStateGST) +
    Number(tradingAccount6.DTCIntegratedGST) +
    Number(tradingAccount6.DTCUTGST) +
    Number(tradingAccount6.DTCAnyOther);

  let totalRevenueFromOperations =
    Number(totalSGR) +
    Number(tradingAccount6.grossReceiptsProfession) +
    Number(totalDTC);

  let totalCreditsToTA =
    Number(totalRevenueFromOperations) +
    Number(tradingAccount6.closingStockFinishedStocks);

  let totalOtherDE =
    tradingAccount6.otherDE.length > 0
      ? tradingAccount6.otherDE.reduce((total, current) => {
          const value = Number(current.amount || 0);
          return total + value;
        }, 0)
      : 0;

  let directExpenses =
    Number(tradingAccount6.DECarriageInward2) +
    Number(tradingAccount6.DEPowerAndFuel2) +
    Number(totalOtherDE);

  let totalDTP =
    Number(tradingAccount6.DTPCustomDuty) +
    Number(tradingAccount6.DTPCounterVeiling) +
    Number(tradingAccount6.DTPSpecialAdditionalDuty) +
    Number(tradingAccount6.DTPUnionExciseDuty) +
    Number(tradingAccount6.DTPServiceTax) +
    Number(tradingAccount6.DTPVat) +
    Number(tradingAccount6.DTPCentralGST) +
    Number(tradingAccount6.DTPStateGST) +
    Number(tradingAccount6.DTPIntegratedGST) +
    Number(tradingAccount6.DTPUTGST) +
    Number(tradingAccount6.DTPAnyOther);

  let goodsProducedMinusTransferredMA = goodsProducedMinusTransferredTA;

  let grossPFBMinusTransferredPLA =
    Number(totalCreditsToTA) -
    Number(tradingAccount6.openingStockFG) -
    Number(tradingAccount6.purchases) -
    Number(directExpenses) -
    Number(totalDTP) -
    Number(goodsProducedMinusTransferredMA);

  // *********************************** //

  let grossProfitTransferredFromTA = grossPFBMinusTransferredPLA;

  let totalOIAnyOtherIncome =
    profitAndLossAccount6.OIAnyOtherIncome.length > 0
      ? profitAndLossAccount6.OIAnyOtherIncome.reduce((total, current) => {
          const value = Number(current.amount || 0);
          return total + value;
        }, 0)
      : 0;

  let totalOfOtherIncome =
    Number(profitAndLossAccount6.OIRent) +
    Number(profitAndLossAccount6.OIcommission) +
    Number(profitAndLossAccount6.OIDividendIncome) +
    Number(profitAndLossAccount6.OIInterestIncome) +
    Number(profitAndLossAccount6.OIProfitOnSaleAssets) +
    Number(profitAndLossAccount6.OIProfitOnSaleInvestment) +
    Number(profitAndLossAccount6.OIProfitOnSaleOtherInvestment) +
    Number(profitAndLossAccount6.OIGainOnAccount) +
    Number(profitAndLossAccount6.OIProfitOnCenversion) +
    Number(profitAndLossAccount6.OIAgriculturalIncome) +
    Number(totalOIAnyOtherIncome);

  let TotalCreditsToPNLA =
    Number(grossProfitTransferredFromTA) + Number(totalOfOtherIncome);

  let totalCompensationToEmployee =
    Number(profitAndLossAccount6.CTESalaries) +
    Number(profitAndLossAccount6.CTEBonus) +
    Number(profitAndLossAccount6.CTEReimbursementMedical) +
    Number(profitAndLossAccount6.CTELeaveEncashment) +
    Number(profitAndLossAccount6.CTELeaveTravel) +
    Number(profitAndLossAccount6.CTESuperannuationFund) +
    Number(profitAndLossAccount6.CTEProvidentFund) +
    Number(profitAndLossAccount6.CTEGratuityFund) +
    Number(profitAndLossAccount6.CTEAnyOtherFund) +
    Number(profitAndLossAccount6.CTEAnyOtherBenefit);

  let totalExpenditureOnInsurance =
    Number(profitAndLossAccount6.IMedicalInsurance) +
    Number(profitAndLossAccount6.ILiftInsurance) +
    Number(profitAndLossAccount6.IKeymanInsurance) +
    Number(profitAndLossAccount6.IOtherInsurance);

  let totalCommission =
    Number(profitAndLossAccount6.ComPaidOutsideIndia) +
    Number(profitAndLossAccount6.ComToOthers);

  let totalRoyality =
    Number(profitAndLossAccount6.RoyPaidOutsideIndia) +
    Number(profitAndLossAccount6.RoyToOthers);

  let totalPCF =
    Number(profitAndLossAccount6.PCFPaidOutsideIndia) +
    Number(profitAndLossAccount6.PCFToOthers);

  let totalRatesAndTaxes =
    Number(profitAndLossAccount6.RTUnionExciseDuty) +
    Number(profitAndLossAccount6.RTServiceTax) +
    Number(profitAndLossAccount6.RTVat) +
    Number(profitAndLossAccount6.RTCess) +
    Number(profitAndLossAccount6.RTCentralGST) +
    Number(profitAndLossAccount6.RTSTateGST) +
    Number(profitAndLossAccount6.RTIntegratedGST) +
    Number(profitAndLossAccount6.RTUTGST) +
    Number(profitAndLossAccount6.RTAnyOther);

  let totalOtherExpenses =
    profitAndLossAccount6.otherExpenses.length > 0
      ? profitAndLossAccount6.otherExpenses.reduce((total, current) => {
          const value = Number(current.amount || 0);
          return total + value;
        }, 0)
      : 0;

  let badDebtsTotal =
    profitAndLossAccount6.badDebts.length > 0
      ? profitAndLossAccount6.badDebts.reduce((total, current) => {
          const value = Number(current.amount || 0);
          return total + value;
        }, 0)
      : 0;

  let totalBadDebt =
    Number(badDebtsTotal) +
    Number(profitAndLossAccount6.otherMoreThanLakhNoPan) +
    Number(profitAndLossAccount6.OtherLessthanLakh);

  let profitBeforeInterest =
    Number(TotalCreditsToPNLA) -
    (Number(profitAndLossAccount6.freightOutward) +
      Number(profitAndLossAccount6.consumptionStoreSpare) +
      Number(profitAndLossAccount6.powerAndFuel) +
      Number(profitAndLossAccount6.rents) +
      Number(profitAndLossAccount6.repairsBuilding) +
      Number(profitAndLossAccount6.repairsMachinery) +
      Number(totalCompensationToEmployee) +
      Number(totalExpenditureOnInsurance) +
      Number(profitAndLossAccount6.workmenAndStaff) +
      Number(profitAndLossAccount6.entertainment) +
      Number(profitAndLossAccount6.hospitality) +
      Number(profitAndLossAccount6.conference) +
      Number(profitAndLossAccount6.salesPromotion) +
      Number(profitAndLossAccount6.advertisement) +
      Number(totalCommission) +
      Number(totalRoyality) +
      Number(totalPCF) +
      Number(profitAndLossAccount6.hotelBoardingLodging) +
      Number(profitAndLossAccount6.travelingExpenses) +
      Number(profitAndLossAccount6.foreignTravelling) +
      Number(profitAndLossAccount6.conveyanceExpenses) +
      Number(profitAndLossAccount6.telephoneExpenses) +
      Number(profitAndLossAccount6.guestHouseExpenses) +
      Number(profitAndLossAccount6.clubExpenses) +
      Number(profitAndLossAccount6.festivalCelebration) +
      Number(profitAndLossAccount6.scholarship) +
      Number(profitAndLossAccount6.gift) +
      Number(profitAndLossAccount6.donation) +
      Number(totalRatesAndTaxes) +
      Number(profitAndLossAccount6.auditFee) +
      Number(totalOtherExpenses) +
      Number(totalBadDebt) +
      Number(profitAndLossAccount6.provisionForBadDoutfulDebt) +
      Number(profitAndLossAccount6.otherProvisions));

  let totalInterest =
    Number(profitAndLossAccount6.interestPaidOutsideIndia) +
    Number(profitAndLossAccount6.interestPaidOutsideOthers) +
    Number(profitAndLossAccount6.interestPaidInIndia) +
    Number(profitAndLossAccount6.interestPainInIndiaOthers);

  let netProfitBeforeTaxes =
    Number(profitBeforeInterest) -
    Number(totalInterest) -
    Number(profitAndLossAccount6.depreciationAmortisation);

  let profitAfterTax =
    Number(netProfitBeforeTaxes) -
    Number(profitAndLossAccount6.provisionForCurrentTax) -
    Number(profitAndLossAccount6.provisionForDeferredTax);

  let amountAvailableAppropriation =
    Number(profitAfterTax) +
    Number(profitAndLossAccount6.balanceBroughtForwardPreviousYear);

  let totalAppropriation =
    Number(profitAndLossAccount6.AppTransferToReserves) +
    Number(profitAndLossAccount6.AppProposedDividend) +
    Number(profitAndLossAccount6.AppTaxOnDividend) +
    Number(profitAndLossAccount6.AppAppropriationCSR) +
    Number(profitAndLossAccount6.AppAnyOther);

  let balanceCarriedToBalanceSheet =
    Number(amountAvailableAppropriation) - Number(totalAppropriation);

  let totals = {
    totalShareCapital,
    RSTotalOtherReserve,
    totalReservesAndSurplus,
    totalShareholdersFund,
    totalShareApplicationMoney,
    bondDebentureTotal,
    rupeeLoanTotal,
    totalTermLoan,
    totallongTermBorrowings,
    TotalOtherLongTermLiabilities,
    totalLTProvisions,
    totalNonCurrentLiabilities,
    totalLoansRepayableOnDemand,
    totalShortTermBorrowing,
    totalTradePayables,
    totalOtherCurrentLiabilities,
    totalShortTermProvisions,
    totalCurrentLiabilities,
    totalEquityAndLiabilities,
    TANetBlock,
    IANetBlock,
    totalFixedAsssets,
    totalIEI,
    totalNonCurrentInvestment,
    totalLongTermLoansAdvances,
    totalLTT,
    ONATotal,
    totalOtherNonCurrentAssets,
    totalINE,
    totalCurrentInvestments,
    totalInventories,
    totalTradeReceivables,
    totalCashAndCashEquivalents,
    totalShortTermLoans,
    totalCurrentAssets,
    totalAssets,
    totalRawAndProgress,
    OIDirectExpenses,
    totalFactoryOverheads,
    totalDebtsToManufacturingAccount,
    totalClosingStock,
    goodsProducedMinusTransferredTA,
    totalOtherOperatingRevenue,
    totalSGR,
    totalDTC,
    totalRevenueFromOperations,
    totalCreditsToTA,
    totalOtherDE,
    directExpenses,
    totalDTP,
    goodsProducedMinusTransferredMA,
    grossPFBMinusTransferredPLA,
    grossProfitTransferredFromTA,
    totalOIAnyOtherIncome,
    totalOfOtherIncome,
    TotalCreditsToPNLA,
    totalCompensationToEmployee,
    totalExpenditureOnInsurance,
    totalCommission,
    totalRoyality,
    totalPCF,
    totalRatesAndTaxes,
    totalOtherExpenses,
    badDebtsTotal,
    totalBadDebt,
    profitBeforeInterest,
    totalInterest,
    netProfitBeforeTaxes,
    profitAfterTax,
    amountAvailableAppropriation,
    totalAppropriation,
    balanceCarriedToBalanceSheet,
  };

  let allSections = [
    [
      {
        sectionTitle: strings.balance_sheet.sources_of_funds,
        state: setSourcesOfFunds6,
        val: sourcesOfFunds6,
        mainData: [
          {
            title: "1: Shareholder’s fund",
            subCategory: [
              {
                title: "1A: Share capital",
                subCategory: [
                  {
                    title: "1Ai: Authorised",
                    key: "authorised",
                    input: true,
                  },
                  {
                    title: "1Aii: Issued, Subscribed and fully Paid up",
                    key: "issuedSubscribedFullyPay",
                    input: true,
                  },
                  {
                    title: "1Aiii: Subscribed but not fully paid",
                    key: "subscribedNotFullyPay",
                    input: true,
                  },
                  {
                    title: "1Aiv: Total (Aii + Aiii)",
                    key: "totalShareCapital",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "1B: Reserves and Surplus",
                subCategory: [
                  {
                    title: "1Bi: Capital Reserve",
                    key: "RSCapitalReserve",
                    input: true,
                  },
                  {
                    title: "1Bii: Capital Redemption Reserve",
                    key: "RSCapitalRedemptionReserve",
                    input: true,
                  },
                  {
                    title: "1Biii: Securities Premium Reserve",
                    key: "RSSecuritiesPremiumReserve",
                    input: true,
                  },
                  {
                    title: "1Biv: Debenture Redemption Reserve",
                    key: "RSDebentureRedemptionReserve",
                    input: true,
                  },
                  {
                    title: "1Bv: Revaluation Reserve",
                    key: "RSRevaluationReserve",
                    input: true,
                  },
                  {
                    title: "1Bvi: Share options outstanding amount",
                    key: "RSShareOptionsOutstandingAmount",
                    input: true,
                  },
                  {
                    title: "1Bvii: Other reserve (specify nature and amount)",
                    key: "RSOtherReserve",
                    input: true,
                    addMore: true,
                  },
                  {
                    title: "1Bviia: Total",
                    input: true,
                    key: "RSTotalOtherReserve",
                    total: true,
                  },
                  {
                    title:
                      "1Bviii: Surplus i.e. Balance in profit and loss account (Debit balance to be shown as –ve figure)",
                    input: true,
                    key: "surplusBalanceInPAndL",
                  },
                  {
                    title:
                      "1Bix: Total (Bi + Bii + Biii + Biv + Bv + Bvi + Bvii + Bviii) (Debit balance to be shown as –ve figure)",
                    input: true,
                    key: "totalReservesAndSurplus",
                    total: true,
                  },
                ],
              },
              {
                title: "1C: Money received against share warrants",
                input: true,
                key: "moneyReceivedAgainstShareWarrant",
              },
              {
                title: "1D: Total Shareholder’s fund (Aiv + Bix + 1C)",
                input: true,
                key: "totalShareholdersFund",
                total: true,
              },
            ],
          },
          {
            title: "2: Share application money pending allotment",
            subCategory: [
              {
                title: "2i: Pending for less than one year",
                input: true,
                key: "shareAppMoneyPendingOneYear",
              },
              {
                title: "2ii: Pending for more than one year",
                input: true,
                key: "shareAppMoneyPendingMoreThanYear",
              },
              {
                title: "2iii: Total (i + ii)",
                input: true,
                key: "totalShareApplicationMoney",
                total: true,
              },
            ],
          },
          {
            title: "3: Non-current liabilities",
            subCategory: [
              {
                title: "3A: Long-term borrowings",
              },
              {
                title: "3Ai: Bonds / debentures",
                subCategory: [
                  {
                    title: "3Aia: Foreign currency",
                    input: true,
                    key: "bondDebentureForeignCurrency",
                  },
                  {
                    title: "3Aib: Rupee",
                    input: true,
                    key: "bondDebentureRupee",
                  },
                  {
                    title: "3Aic: Total (ia + ib)",
                    input: true,
                    key: "bondDebentureTotal",
                    total: true,
                  },
                ],
              },
              {
                title: "3Aii: Term loans",
              },
              {
                title: "3Aiia: Foreign currency",
                input: true,
                key: "termLoansForeignCurrency",
              },
              {
                title: "3Aiib: Rupee loans",
                subCategory: [
                  {
                    title: "3Aiib1: From Banks",
                    input: true,
                    key: "rupeeLoanFromBanks",
                  },
                  {
                    title: "3Aiib2: From others",
                    input: true,
                    key: "rupeeLoanFromOthers",
                  },
                  {
                    title: "3Aiib3: Total (b1 + b2)",
                    input: true,
                    key: "rupeeLoanTotal",
                    total: true,
                  },
                ],
              },
              {
                title: "3Aiic: Total Term loans (iia + b3)",
                input: true,
                key: "totalTermLoan",
                total: true,
              },
              {
                title: "3Aiii: Deferred payment liabilities",
                input: true,
                key: "LTBdeferredPaymentLiabilities",
              },
              {
                title: "3Aiv: Deposits from related parties (see instructions)",
                input: true,
                key: "LTBDepositsFromRelatedParties",
              },
              {
                title: "3Av: Other deposits",
                input: true,
                key: "LTBOtherDeposits",
              },
              {
                title:
                  "3Avi: Loans and advances from related parties (see instructions)",
                input: true,
                key: "LTBLoanAdvancesFromRelatedParties",
              },
              {
                title: "3Avii: Other loans and advances",
                input: true,
                key: "LTBOtherLoanAdvances",
              },
              {
                title:
                  "3Aviii: Long term maturities of finance lease obligations",
                input: true,
                key: "LTBLongTermMaturities",
              },
              {
                title:
                  "3Aix: Total Long term borrowings (ic + iic + iii + iv + v + vi + vii + viii)",
                input: true,
                key: "totallongTermBorrowings",
                total: true,
              },
              {
                title: "3B: Deferred tax liabilities (net)",
                key: "deferredTaxLiabilities",
                input: true,
              },
              {
                title: "3C: Other long-term liabilities",
                subCategory: [
                  {
                    title: "3Ci: Trade payables",
                    key: "otherLTTradePayables",
                    input: true,
                  },
                  {
                    title: "3Cii: Others",
                    key: "otherLTOthers",
                    input: true,
                  },
                  {
                    title: "3Ciii: Total Other long-term liabilities (i + ii)",
                    key: "TotalOtherLongTermLiabilities",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "3D: Long-term provisions",
                subCategory: [
                  {
                    title: "3Di: Provision for employee benefits",
                    key: "LTProvisionEmployeeBenefits",
                    input: true,
                  },
                  {
                    title: "3Dii: Others",
                    key: "LTProvisionOthers",
                    input: true,
                  },
                  {
                    title: "3Diii: Total (i + ii)",
                    key: "totalLTProvisions",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "3E: Total Non-current liabilities (3A + 3B + 3C + 3D)",
                key: "totalNonCurrentLiabilities",
                input: true,
                total: true,
              },
            ],
          },
          {
            title: "4: Current liabilities",
            subCategory: [
              {
                title: "4A: Short-term borrowings",
              },
              {
                title: "4Ai: Loans repayable on demand",
                subCategory: [
                  {
                    title: "4Aia: From Banks",
                    key: "LRDFromBank",
                    input: true,
                  },
                  {
                    title: "4Aib: From Non-Banking Finance Companies",
                    key: "LRDFromNonBankingFinance",
                    input: true,
                  },
                  {
                    title: "4Aic: From other financial institutions",
                    key: "LRDFromOtherFinancialInst",
                    input: true,
                  },
                  {
                    title: "4Aid: From others",
                    key: "LRDFromOthers",
                    input: true,
                  },
                  {
                    title:
                      "4Aie: Total Loans repayable on demand (ia + ib + ic + id)",
                    key: "totalLoansRepayableOnDemand",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "4Aii: Deposits from related parties (see instructions)",
                key: "STBDepositsFromRelatedParties",
                input: true,
              },
              {
                title:
                  "4Aiii: Loans and advances from related parties (see instructions)",
                key: "STBLoansAndAdvances",
                input: true,
              },
              {
                title: "4Aiv: Other loans and advances",
                key: "STBOtherLoansAndAdvances",
                input: true,
              },
              {
                title: "4Av: Other deposits",
                key: "STBOtherDeposits",
                input: true,
              },
              {
                title:
                  "4Avi: Total Short-term borrowings (ie + ii + iii + iv + v)",
                key: "totalShortTermBorrowing",
                input: true,
                total: true,
              },
              {
                title: "4B: Trade payables",
                subCategory: [
                  {
                    title: "4Bi: Outstanding for more than 1 year",
                    key: "TPOutstandingMoreThanYear",
                    input: true,
                  },
                  {
                    title: "4Bii: Others",
                    key: "TPOthers",
                    input: true,
                  },
                  {
                    title: "4Biii: Total Trade payables (i + ii)",
                    key: "totalTradePayables",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "4C: Other current liabilities",
                subCategory: [
                  {
                    title: "4Ci: Current maturities of long-term debt",
                    input: true,
                    key: "OCLLongTermDebt",
                  },
                  {
                    title:
                      "4Cii: Current maturities of finance lease obligations",
                    input: true,
                    key: "OCLFinanceLeaseObligations",
                  },
                  {
                    title: "4Ciii: Interest accrued but not due on borrowings",
                    input: true,
                    key: "OCLInterestAccruedNotDue",
                  },
                  {
                    title: "4Civ: Interest accrued and due on borrowings",
                    input: true,
                    key: "OCLInterestAccuredDue",
                  },
                  {
                    title: "4Cv: Income received in advance",
                    input: true,
                    key: "OCLIncomeReceivedAdvance",
                  },
                  {
                    title: "4Cvi: Unpaid dividends",
                    input: true,
                    key: "OCLUnpaidDividends",
                  },
                  {
                    title:
                      "4Cvii: Application money received for allotment of securities and due for refund and interest accrued",
                    input: true,
                    key: "OCLApplicationMoneyReceived",
                  },
                  {
                    title:
                      "4Cviii: Unpaid matured deposits and interest accrued thereon",
                    input: true,
                    key: "OCLUnpaidMaturedDeposits",
                  },
                  {
                    title:
                      "4Cix: Unpaid matured debentures and interest accrued thereon",
                    input: true,
                    key: "OCLUnpainMaturedDebentures",
                  },
                  {
                    title: "4Cx: Other payables",
                    input: true,
                    key: "OCLOtherPayables",
                  },
                  {
                    title:
                      "4Cxi: Total Other current liabilities (i + ii + iii + iv + v + vi + vii + viii + ix + x)",
                    input: true,
                    key: "totalOtherCurrentLiabilities",
                    total: true,
                  },
                ],
              },
              {
                title: "4D: Short-term provisions",
                subCategory: [
                  {
                    title: "4Di: Provision for employee benefit",
                    key: "STPEmployeeBenefit",
                    input: true,
                  },
                  {
                    title: "4Dii: Provision for Income-tax",
                    key: "STPIncomeTax",
                    input: true,
                  },
                  {
                    title: "4Diii: Proposed Dividend",
                    key: "STPProposedDividend",
                    input: true,
                  },
                  {
                    title: "4Div: Tax on dividend",
                    key: "STPTaxOnDividend",
                    input: true,
                  },
                  {
                    title: "4Dv: Other",
                    key: "STPOthers",
                    input: true,
                  },
                  {
                    title:
                      "4Dvi: Total Short-term provisions (i + ii +iii + iv + v + vi)",
                    key: "totalShortTermProvisions",
                    input: true,
                    total: true,
                  },
                ],
              },

              {
                title: "4E: Total Current liabilities (4A + 4B + 4C + 4D)",
                input: true,
                total: true,
                key: "totalCurrentLiabilities",
              },
            ],
          },
          {
            title: "Total Equity and liabilities (1D + 2 + 3E + 4E)",
            total: true,
            key: "totalEquityAndLiabilities",
            input: true,
          },
        ],
      },
    ],
    [
      {
        sectionTitle: strings.detail_balance_sheet.applicationsOfFunds,
        state: setApplicationsOfFunds6,
        val: applicationsOfFunds6,
        mainData: [
          {
            title: "1: Non-current Assets",
            subCategory: [
              {
                title: "1A: Fixed assets",
              },
              {
                title: "1Ai: Tangible assets",
                subCategory: [
                  {
                    title: "1Aia: Gross block",
                    input: true,
                    key: "TAGrossBlock",
                  },
                  {
                    title: "1Aib: Depreciation",
                    input: true,
                    key: "TADepreciation",
                  },
                  {
                    title: "1Aic: Impairment losses",
                    input: true,
                    key: "TAImpairmentLosses",
                  },
                  {
                    title: "1Aid: Net block (ia – ib - ic)",
                    input: true,
                    key: "TANetBlock",
                    total: true,
                  },
                ],
              },
              {
                title: "1Aii: Intangible assets",
                subCategory: [
                  {
                    title: "1Aiia: Gross block",
                    key: "IAGrossBlock",
                    input: true,
                  },
                  {
                    title: "1Aiib: Amortization",
                    key: "IAAmortization",
                    input: true,
                  },
                  {
                    title: "1Aiic: Impairment losses",
                    key: "IAImpairmentLosses",
                    input: true,
                  },
                  {
                    title: "1Aiid: Net block (iia – iib - iic)",
                    key: "IANetBlock",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "1Aiii: Capital work-in-progress",
                key: "FACapitalWorkInProgress",
                input: true,
              },
              {
                title: "1Aiv: Intangible assets under development",
                key: "FAIntangibleAssetsUnderDev",
                input: true,
              },
              {
                title: "1Av: Total Fixed assets (id + iid + iii + iv)",
                key: "totalFixedAsssets",
                input: true,
                total: true,
              },
              {
                title: "1B: Non-current investments",
              },
              {
                title: "1Bi: Investment in property",
                input: true,
                key: "NCIInvestmentInProperty",
              },
              {
                title: "1Bii: Investments in Equity instruments",
                subCategory: [
                  {
                    title: "1Biia: Listed equities",
                    input: true,
                    key: "IEIListedEquities",
                  },
                  {
                    title: "1Biib: Unlisted equities",
                    input: true,
                    key: "IEIUnlistedEquities",
                  },
                  {
                    title: "1Biic: Total (iia + iib)",
                    input: true,
                    key: "totalIEI",
                    total: true,
                  },
                ],
              },
              {
                title: "1Biii: Investments in Preference shares",
                key: "NCIPreferenceShares",
                input: true,
              },
              {
                title: "1Biv: Investments in Government or trust securities",
                key: "NCIGovtTrustSecurities",
                input: true,
              },
              {
                title: "1Bv: Investments in Debenture or bonds",
                key: "NCIDebentureOrBonds",
                input: true,
              },
              {
                title: "1Bvi: Investments in Mutual funds",
                key: "NCIMutualFunds",
                input: true,
              },
              {
                title: "1Bvii: Investments in Partnership firms",
                key: "NCIPartenershipFirms",
                input: true,
              },
              {
                title: "1Bviii: Others Investments",
                key: "NCIOtherInvestments",
                input: true,
              },
              {
                title:
                  "1Bix: Total Non-current investments (i + iic + iii + iv + v + vi + vii + viii)",
                key: "totalNonCurrentInvestment",
                input: true,
                total: true,
              },
              {
                title: "1C: Deferred tax assets (Net)",
                key: "deferredTaxAssets",
                input: true,
              },
              {
                title: "1D: Long-term loans and advances",
                subCategory: [
                  {
                    title: "1Di: Capital advances",
                    input: true,
                    key: "LTLCapitalAdvances",
                  },
                  {
                    title: "1Dii: Security deposits",
                    input: true,
                    key: "LTLSecurityDeposits",
                  },
                  {
                    title:
                      "1Diii: Loans and advances to related parties (see instructions)",
                    input: true,
                    key: "LTLLoanAdvancesRelatedParties",
                  },
                  {
                    title: "1Div: Other Loans and advances",
                    input: true,
                    key: "LTLOtherLoansAdvances",
                  },
                  {
                    title:
                      "1Dv: Total Long-term loans and advances (i + ii + iii + iv)",
                    input: true,
                    key: "totalLongTermLoansAdvances",
                    total: true,
                  },
                  {
                    title:
                      "1Dvi: Long-term loans and advances included in Dv which is",
                  },
                  {
                    title: "1Dvia: for the purpose of business or profession",
                    input: true,
                    key: "LTLDvPUrposeOfBusiness",
                  },
                  {
                    title:
                      "1Dvib: not for the purpose of business or profession",
                    input: true,
                    key: "LTLDvNotBusinessPurpose",
                  },
                  {
                    title:
                      "1Dvic: given to shareholder, being the beneficial owner of share, orto any concern or on behalf/ benefit of such shareholder as per section 2(22)(e) of I.T. Act",
                    input: true,
                    key: "LTLDvGivenToShareHolder",
                  },
                ],
              },
              {
                title: "1E: Other non-current assets",
              },
              {
                title: "1Ei: Long-term trade receivables ",
                subCategory: [
                  {
                    title: "1Eia: Secured, considered good",
                    input: true,
                    key: "LTTSecuredConsideredGood",
                  },
                  {
                    title: "1Eib: Unsecured, considered good",
                    input: true,
                    key: "LTTUnsecuredConsideredGood",
                  },
                  {
                    title: "1Eic: Doubtful",
                    input: true,
                    key: "LTTDoubtful",
                  },
                  {
                    title:
                      "1Eid: Total Other non-current assets (ia + ib + ic)",
                    input: true,
                    key: "totalLTT",
                    total: true,
                  },
                ],
              },
              {
                title: "1Eii: Others",
                key: "ONAOthers",
                input: true,
              },
              {
                title: "1Eiii: Total (id + ii)",
                key: "ONATotal",
                input: true,
              },
              {
                title:
                  "1Eiv: Non-current assets included in Eiii which is due from shareholder, being the beneficial owner of share, or from any concern or on behalf/ benefit of such shareholder as per section 2(22)(e) of I.T. Act",
                key: "nonCurrentAssetsIncludedInEIII",
                input: true,
              },
              {
                title:
                  "1F: Total Non-current assets (Av + Bix + C + Dv + Eiii)",
                total: true,
                key: "totalOtherNonCurrentAssets",
                input: true,
              },
            ],
          },
          {
            title: "2: Current assets",
            subCategory: [
              {
                title: "2A: Current investments",
              },
              {
                title: "2Ai: Investment in Equity instruments",
                subCategory: [
                  {
                    title: "2Aia: Listed equities",
                    key: "INEListedEquities",
                    input: true,
                  },
                  {
                    title: "2Aib: Unlisted equities",
                    key: "INEUnlistedEquities",
                    input: true,
                  },
                  {
                    title: "2Aic: Total (ia + ib)",
                    key: "totalINE",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "2Aii: Investment in Preference shares",
                key: "CIPreferenceShares",
                input: true,
              },
              {
                title: "2Aiii: Investment in government or trust securities",
                key: "CIGovtTrustSecurities",
                input: true,
              },
              {
                title: "2Aiv: Investment in debentures or bonds",
                key: "CIDebenturesOrBonds",
                input: true,
              },
              {
                title: "2Av: Investment in Mutual funds",
                key: "CIMutualFunds",
                input: true,
              },
              {
                title: "2Avi: Investment in partnership firms",
                key: "CIPartnershipFirms",
                input: true,
              },
              {
                title: "2Avii: Other investment",
                key: "CIOtherInvestment",
                input: true,
              },
              {
                title:
                  "2Aviii: Total Current investments (ic + ii + iii + iv + v + vi + vii)",
                key: "totalCurrentInvestments",
                input: true,
                total: true,
              },
              {
                title: "2B: Inventories",
                subCategory: [
                  {
                    title: "2Bi: Raw materials",
                    key: "InvtRawMaterials",
                    input: true,
                  },
                  {
                    title: "2Bii: Work-in-progress",
                    key: "InvtWorkInProgress",
                    input: true,
                  },
                  {
                    title: "2Biii: Finished goods",
                    key: "InvtFinishedGoods",
                    input: true,
                  },
                  {
                    title:
                      "2Biv: Stock-in-trade (in respect of goods acquired for trading)",
                    key: "InvtStockInTrade",
                    input: true,
                  },
                  {
                    title: "2Bv: Stores and spares",
                    key: "InvtStoresAndSapres",
                    input: true,
                  },
                  {
                    title: "2Bvi: Loose tools",
                    key: "InvtLooseTools",
                    input: true,
                  },
                  {
                    title: "2Bvii: Others",
                    key: "InvtOthers",
                    input: true,
                  },
                  {
                    title:
                      "2Bviii: Total Inventories (i + ii + iii + iv + v + vi + vii)",
                    key: "totalInventories",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "2C: Trade receivables",
                subCategory: [
                  {
                    title: "2Ci: Outstanding for more than 6 months",
                    key: "TROutstandingMoreThan6Month",
                    input: true,
                  },
                  {
                    title: "2Cii: Others",
                    key: "TROthers",
                    input: true,
                  },
                  {
                    title: "2Ciii: Total Trade receivables (i + ii + iii)",
                    key: "totalTradeReceivables",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "2D: Cash and cash equivalents",
                subCategory: [
                  {
                    title: "2Di: Balances with Banks",
                    key: "CCBalanceWithBank",
                    input: true,
                  },
                  {
                    title: "2Dii: Cheques, drafts in hand",
                    key: "CCChequeDraft",
                    input: true,
                  },
                  {
                    title: "2Diii: Cash in hand",
                    key: "CCCashInHand",
                    input: true,
                  },
                  {
                    title: "2Div: Others",
                    key: "CCOthers",
                    input: true,
                  },
                  {
                    title:
                      "2Dv: Total Cash and cash equivalents (i + ii + iii + iv)",
                    key: "totalCashAndCashEquivalents",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "2E: Short-term loans and advances",
              },
              {
                title:
                  "2Ei: Loans and advances to related parties (see instructions)",
                key: "STLLoanAndAdvances",
                input: true,
              },
              {
                title: "2Eii: Others",
                key: "STLOthers",
                input: true,
              },
              {
                title: "2Eiii: Total Short-term loans and advances (i + ii)",
                key: "totalShortTermLoans",
                input: true,
                total: true,
              },
              {
                title:
                  "2Eiv: Short-term loans and advances included in Eiii which is",
                subCategory: [
                  {
                    title: "2Eiva: for the purpose of business or profession",
                    key: "STLEiiiForBusiness",
                    input: true,
                  },
                  {
                    title:
                      "2Eivb: not for the purpose of business or profession",
                    key: "STLEiiiNotForBusiness",
                    input: true,
                  },
                  {
                    title:
                      "2Eivc: given to a shareholder, being the beneficial owner of share, or to any concern or on behalf/ benefit of such shareholder as per section 2(22)(e) of I.T. Act",
                    key: "STLEiiiGivenToShareholder",
                    input: true,
                  },
                ],
              },
              {
                title: "2F: Other current assets",
                key: "otherCurrentAssets",
                input: true,
              },
              {
                title:
                  "2G: Total Current assets (Aviii + Bviii + Ciii + Dv + Eiii + F)",
                key: "totalCurrentAssets",
                input: true,
                total: true,
              },
            ],
          },
          {
            title: "3: Total Assets (1F + 2G)",
            input: true,
            total: true,
            key: "totalAssets",
          },
        ],
      },
    ],
    [
      {
        sectionTitle: strings.detail_balance_sheet.ManufacturingAccount,
        state: setManufacturingAccount6,
        val: manufacturingAccount6,
        mainData: [
          {
            title: "1: Opening Inventory",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title: "1ai: Opening stock of raw-material",
                    key: "OIRawMaterial",
                    input: true,
                  },
                  {
                    title: "1aii: Opening stock of Work in progress",
                    key: "OIWorkInProgress",
                    input: true,
                  },
                  {
                    title: "1aiii: Total (i + ii)",
                    key: "totalRawAndProgress",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "1B: Purchases (net of refunds and duty or tax, if any)",
                key: "OIPurchases",
                input: true,
              },
              {
                title: "1C: Direct wages",
                key: "OIDirectWages",
                input: true,
              },
              {
                title: "1D: Direct expenses",
                key: "OIDirectExpenses",
                input: true,
                total: true,
                subCategory: [
                  {
                    title: "1di: Carriage inward",
                    key: "DECarriageInward",
                    input: true,
                  },
                  {
                    title: "1dii: Power and fuel",
                    key: "DEPowerAndFuel",
                    input: true,
                  },
                  {
                    title: "1diii: Other direct expenses",
                    key: "DEOther",
                    input: true,
                  },
                ],
              },
              {
                title: "1E: Factory Overheads",
                subCategory: [
                  {
                    title: "1ei: Indirect wages",
                    key: "FOIndirectWages",
                    input: true,
                  },
                  {
                    title: "1eii: Factory rent and rates",
                    key: "FOFactoryRent",
                    input: true,
                  },
                  {
                    title: "1eiii: Factory Insurance",
                    key: "FOFactoryInsurance",
                    input: true,
                  },
                  {
                    title: "1eiv: Factory fuel and power",
                    key: "FOFactoryFuelAndPower",
                    input: true,
                  },
                  {
                    title: "1ev: Factory general expenses",
                    key: "FOFactoryGeneralExpenses",
                    input: true,
                  },
                  {
                    title: "1evi: Depreciation of factory machinery",
                    key: "FODepreciationFactoryMachinery",
                    input: true,
                  },
                  {
                    title: "1evii: Total (i+ii+iii+iv+v+vi)",
                    key: "totalFactoryOverheads",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title:
                  "1F: Total of Debits to Manufacturing Account (Aiii+B+C+D+Evii)",
                total: true,
                key: "totalDebtsToManufacturingAccount",
                input: true,
              },
            ],
          },
          {
            title: "2: Closing Stock",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title: "2ai: Raw material",
                    key: "CSRawMaterial",
                    input: true,
                  },
                  {
                    title: "2aii: Work-in-progress",
                    key: "CSWorkInProgress",
                    input: true,
                  },
                  {
                    title: "2aiii: Total (2i +2ii)",
                    key: "totalClosingStock",
                    input: true,
                    total: true,
                  },
                ],
              },
            ],
          },
          {
            title:
              "3: Cost of Goods Produced - transferred to Trading Account (1F-2)",
            total: true,
            key: "goodsProducedMinusTransferredTA",
            input: true,
          },
        ],
      },
    ],
    [
      {
        sectionTitle: strings.detail_balance_sheet.tradingAccount,
        state: setTradingAccount6,
        val: tradingAccount6,
        mainData: [
          {
            title: "4: Revenue from operations",
            subCategory: [
              {
                title:
                  "4A: Sales/ Gross receipts of business (net of returns and refunds and duty or tax, if any)",
              },
              {
                title: "4ai: Sale of goods",
                key: "SGRGoods",
                input: true,
              },
              {
                title: "4aii: Sale of services",
                key: "SGRServices",
                input: true,
              },
              {
                title:
                  "4Aiii: Other operating revenues (specify nature and amount)",
                subCategory: [
                  {
                    title: "",
                    addMore: true,
                    key: "SGROtherOperatingRevenue",
                    input: true,
                  },
                  {
                    title: "4Aiiia: Total",
                    total: true,
                    key: "totalOtherOperatingRevenue",
                    input: true,
                  },
                ],
              },
              {
                title: "4aiv: Total(i+ii+iiic)",
                key: "totalSGR",
                input: true,
                total: true,
              },
              {
                title: "4B: Gross receipts from Profession",
                key: "grossReceiptsProfession",
                input: true,
              },
              {
                title:
                  "4C: Duties, taxes and cess received or receivable in respect of goods and services sold or supplied",
                subCategory: [
                  {
                    title: "4ci: Union Excise duties",
                    key: "DTCUnionExciseDuties",
                    input: true,
                  },
                  {
                    title: "4cii: Service Tax",
                    key: "DTCserviceTax",
                    input: true,
                  },
                  {
                    title: "4ciii: VAT/ Sales tax",
                    key: "DTCVat",
                    input: true,
                  },
                  {
                    title: "4civ: Central Goods & Service Tax (CGST)",
                    key: "DTCCentralGST",
                    input: true,
                  },
                  {
                    title: "4cv: State Goods & Services Tax (SGST)",
                    key: "DTCStateGST",
                    input: true,
                  },
                  {
                    title: "4cvi: Integrated Goods & Services Tax (IGST)",
                    key: "DTCIntegratedGST",
                    input: true,
                  },
                  {
                    title:
                      "4cvii: Union Territory Goods & Services Tax (UTGST)",
                    key: "DTCUTGST",
                    input: true,
                  },
                  {
                    title: "4cviii: Any other duty, tax and cess",
                    key: "DTCAnyOther",
                    input: true,
                  },
                  {
                    title: "4cix: Total (i + ii + iii + iv +v+ vi+vii+viii)",
                    key: "totalDTC",
                    input: true,
                    total: true,
                  },
                ],
              },
              {
                title: "4d: Total Revenue from operations (Aiv + B +Cix)",
                total: true,
                key: "totalRevenueFromOperations",
                input: true,
              },
            ],
          },
          {
            title: "5: Closing Stock of Finished Stocks",
            key: "closingStockFinishedStocks",
            input: true,
          },
          {
            title: "6: Total of credits to Trading Account (4D + 5 )",
            key: "totalCreditsToTA",
            input: true,
            total: true,
          },
          {
            title: "7: Opening Stock of Finished Goods",
            key: "openingStockFG",
            input: true,
          },
          {
            title: "8: Purchases (net of refunds and duty or tax, if any)",
            key: "purchases",
            input: true,
          },
          {
            title: "9: Direct Expenses",
            total: true,
            key: "directExpenses",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title: "9ai: Carriage inward",
                    key: "DECarriageInward2",
                    input: true,
                  },
                  {
                    title: "9aii: Power and fuel",
                    key: "DEPowerAndFuel2",
                    input: true,
                  },
                  {
                    title: "9Aiii: Other direct expenses",
                    key: "otherDE",
                    input: true,
                    addMore: true,
                  },
                  {
                    title: "9Aiiia: Total",
                    key: "totalOtherDE",
                    input: true,
                    total: true,
                  },
                ],
              },
            ],
          },
          {
            title:
              "10: Duties and taxes, paid or payable, in respect of goods and services purchased",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title: "10ai: Custom duty",
                    key: "DTPCustomDuty",
                    input: true,
                  },
                  {
                    title: "10aii: Counter veiling duty",
                    key: "DTPCounterVeiling",
                    input: true,
                  },
                  {
                    title: "10aiii: Special additional duty",
                    key: "DTPSpecialAdditionalDuty",
                    input: true,
                  },
                  {
                    title: "10aiv: Union excise duty",
                    key: "DTPUnionExciseDuty",
                    input: true,
                  },
                  {
                    title: "10av: Service Tax",
                    key: "DTPServiceTax",
                    input: true,
                  },
                  {
                    title: "10avi: VAT/ Sales tax",
                    key: "DTPVat",
                    input: true,
                  },
                  {
                    title: "10avii: Central Goods & Service Tax (CGST)",
                    key: "DTPCentralGST",
                    input: true,
                  },
                  {
                    title: "10aviii: State Goods & Services Tax (SGST)",
                    key: "DTPStateGST",
                    input: true,
                  },
                  {
                    title: "10aix: Integrated Goods & Services Tax (IGST)",
                    key: "DTPIntegratedGST",
                    input: true,
                  },
                  {
                    title: "10ax: Union Territory Goods & Services Tax (UTGST)",
                    key: "DTPUTGST",
                    input: true,
                  },
                  {
                    title: "10axi: Any other tax, paid or payable",
                    key: "DTPAnyOther",
                    input: true,
                  },
                  {
                    title:
                      "10Axii: Total (10i + 10ii + 10iii + 10iv + 10v + 10vi + 10vii + 10viii + 10ix + 10x+10xi)",
                    key: "totalDTP",
                    input: true,
                    total: true,
                  },
                ],
              },
            ],
          },
          {
            title:
              "11: Cost of goods produced – Transferred from Manufacturing Account",
            key: "goodsProducedMinusTransferredMA",
            input: true,
            total: true,
          },
          {
            title:
              " 12: Gross Profit from Business/Profession - transferred to Profit and Loss account (6-7-8-9-10xii-11)",
            total: true,
            input: true,
            key: "grossPFBMinusTransferredPLA",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title: "12ai: Turnover From Intraday Trading",
                    key: "turnoverFromIntraday",
                    input: true,
                  },
                  {
                    title: "12aii: Income From Intraday Trading",
                    key: "incomeFromIntraday",
                    input: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    [
      {
        sectionTitle: strings.detail_balance_sheet.profitAndLossAccount,
        state: setProfitAndLossAccount6,
        val: profitAndLossAccount6,
        mainData: [
          {
            title: "13: Gross profit transferred from Trading Account",
            key: "grossProfitTransferredFromTA",
            total: true,
            input: true,
          },
          {
            title: "14: Other income",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title: "14i: Rent",
                    key: "OIRent",
                    input: "true",
                  },
                  {
                    title: "14ii: Commission",
                    key: "OIcommission",
                    input: "true",
                  },
                  {
                    title: "14iii: Dividend income",
                    key: "OIDividendIncome",
                    input: "true",
                  },
                  {
                    title: "14iv: Interest income",
                    key: "OIInterestIncome",
                    input: "true",
                  },
                  {
                    title: "14v: Profit on sale of fixed assets",
                    key: "OIProfitOnSaleAssets",
                    input: "true",
                  },
                  {
                    title:
                      "14vi: Profit on sale of investment beign securities chargeble to Securities Transaction Tax (STT)",
                    key: "OIProfitOnSaleInvestment",
                    input: "true",
                  },
                  {
                    title: "14vii: Profit on sale of other investment",
                    key: "OIProfitOnSaleOtherInvestment",
                    input: "true",
                  },
                  {
                    title:
                      "14viii: Gain (Loss) on account of foreign exchange fluctuation u/s 43AA",
                    key: "OIGainOnAccount",
                    input: "true",
                  },
                  {
                    title:
                      "14ix: Profit on conversion of inventory into capital asset u/s 28(via) (Fair Market value of inventory as the date of conversion)",
                    key: "OIProfitOnCenversion",
                    input: "true",
                  },
                  {
                    title: "14x: Agricultural income",
                    key: "OIAgriculturalIncome",
                    input: "true",
                  },
                  {
                    title: "14xi: Any other income (specify nature and amount)",
                    subCategory: [
                      {
                        title: "",
                        key: "OIAnyOtherIncome",
                        addMore: true,
                      },
                      {
                        title: "14xia: Total",
                        key: "totalOIAnyOtherIncome",
                        input: true,
                        total: true,
                      },
                    ],
                  },
                  {
                    title:
                      "14xii: Total of other income (i + ii + iii + iv + v + vi + vii + viii + ix + x + xi)",
                    total: true,
                    input: true,
                    key: "totalOfOtherIncome",
                  },
                ],
              },
            ],
          },
          {
            title:
              "15: Total of credits to profit and loss account (12 + 14xii)",
            total: true,
            key: "TotalCreditsToPNLA",
            input: true,
          },
          {
            title: "16: Freight outward",
            key: "freightOutward",
            input: true,
          },
          {
            title: "17: consumption of stores and spare parts",
            key: "consumptionStoreSpare",
            input: true,
          },
          {
            title: "18: Power and fuel",
            key: "powerAndFuel",
            input: true,
          },
          {
            title: "19: Rents",
            key: "rents",
            input: true,
          },
          {
            title: "20: Repairs to building",
            key: "repairsBuilding",
            input: true,
          },
          {
            title: "21: Repairs to machinery ",
            key: "repairsMachinery",
            input: true,
          },
          {
            title: "22: Compensation to employees",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title: "22i: Salaries and wages",
                    input: true,
                    key: "CTESalaries",
                  },
                  {
                    title: "22ii: Bonus",
                    input: true,
                    key: "CTEBonus",
                  },
                  {
                    title: "22iii: Reimbursement of medical expenses",
                    input: true,
                    key: "CTEReimbursementMedical",
                  },
                  {
                    title: "22iv: Leave encashment",
                    input: true,
                    key: "CTELeaveEncashment",
                  },
                  {
                    title: "22v: Leave travel benefits",
                    input: true,
                    key: "CTELeaveTravel",
                  },
                  {
                    title: "22vi: Contribution to approved superannuation fund",
                    input: true,
                    key: "CTESuperannuationFund",
                  },
                  {
                    title: "22vii: Contribution to recognised provident fund",
                    input: true,
                    key: "CTEProvidentFund",
                  },
                  {
                    title: "22viii: Contribution to recognised gratuity fund",
                    input: true,
                    key: "CTEGratuityFund",
                  },
                  {
                    title: "22ix: Contribution to any other fund",
                    input: true,
                    key: "CTEAnyOtherFund",
                  },
                  {
                    title:
                      "22x: Any other benefit to employees in respect of which an expenditure has been incurred",
                    input: true,
                    key: "CTEAnyOtherBenefit",
                  },
                  {
                    title:
                      "22xi: Total compensation to employees (22i + 22ii + 22iii + 22iv + 22v + 22vi + 22vii + 22viii +22ix + 22x)",
                    input: true,
                    total: true,
                    key: "totalCompensationToEmployee",
                  },
                  {
                    title:
                      "22xii: Whether any compensation, included in 22xi, paid to non-residents",
                  },
                  {
                    title: "22xiii: If Yes, amount paid to non-residents",
                    input: true,
                    key: "CTEAmountPaidToNR",
                  },
                ],
              },
            ],
          },
          {
            title: "Insurance",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title: "23i: Medical Insurance",
                    input: true,
                    key: "IMedicalInsurance",
                  },
                  {
                    title: "23ii: Life Insurance",
                    input: true,
                    key: "ILiftInsurance",
                  },
                  {
                    title: "23iii: Keyman's Insurance",
                    input: true,
                    key: "IKeymanInsurance",
                  },
                  {
                    title:
                      "23iv: Other Insurance including factory, office, car, goods,etc.",
                    input: true,
                    key: "IOtherInsurance",
                  },
                  {
                    title:
                      "23v: Total expenditure on insurance (23i + 23ii + 23iii + 23iv)",
                    input: true,
                    total: true,
                    key: "totalExpenditureOnInsurance",
                  },
                ],
              },
            ],
          },
          {
            title: "24: Workmen and staff welfare expenses",
            input: true,
            key: "workmenAndStaff",
          },
          {
            title: "25: Entertainment",
            input: true,
            key: "entertainment",
          },
          {
            title: "26: Hospitality",
            input: true,
            key: "hospitality",
          },
          {
            title: "27: Conference",
            input: true,
            key: "conference",
          },
          {
            title:
              "28: Sales promotion including publicity (other than advertisement)",
            input: true,
            key: "salesPromotion",
          },
          {
            title: "29: Advertisement",
            input: true,
            key: "advertisement",
          },
          {
            title: "30: Commission",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title:
                      "30i: Paid outside India, or paid in India to a non-resident other than a company or a foreign company",
                    key: "ComPaidOutsideIndia",
                    input: true,
                  },
                  {
                    title: "30ii: To others",
                    key: "ComToOthers",
                    input: true,
                  },
                  {
                    title: "30iii: Total (i + ii)",
                    key: "totalCommission",
                    input: true,
                    total: true,
                  },
                ],
              },
            ],
          },
          {
            title: "31: Royalty",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title:
                      "31i: Paid outside India, or paid in India to a non-resident other than a company or a foreign company",
                    key: "RoyPaidOutsideIndia",
                    input: true,
                  },
                  {
                    title: "31ii: To others",
                    key: "RoyToOthers",
                    input: true,
                  },
                  {
                    title: "31iii: Total (i + ii)",
                    key: "totalRoyality",
                    input: true,
                    total: true,
                  },
                ],
              },
            ],
          },
          {
            title:
              "32: Professional / Consultancy fees / Fee for technical services",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title:
                      "32i: Paid outside India, or paid in India to a non-resident other than a company or a foreign company",
                    key: "PCFPaidOutsideIndia",
                    input: true,
                  },
                  {
                    title: "32ii: To others",
                    key: "PCFToOthers",
                    input: true,
                  },
                  {
                    title: "32iii: Total (i + ii)",
                    key: "totalPCF",
                    input: true,
                    total: true,
                  },
                ],
              },
            ],
          },
          {
            title: "33: Hotel , boarding and Lodging",
            input: true,
            key: "hotelBoardingLodging",
          },
          {
            title: "34: Traveling expenses other than on foreign traveling",
            input: true,
            key: "travelingExpenses",
          },
          {
            title: "35: Foreign travelling expenses",
            input: true,
            key: "foreignTravelling",
          },
          {
            title: "36: Conveyance expenses",
            input: true,
            key: "conveyanceExpenses",
          },
          {
            title: "37: Telephone expenses",
            input: true,
            key: "telephoneExpenses",
          },
          {
            title: "38: Guest House expenses",
            input: true,
            key: "guestHouseExpenses",
          },
          {
            title: "39: Club expenses",
            input: true,
            key: "clubExpenses",
          },
          {
            title: "40: Festival celebration expenses",
            input: true,
            key: "festivalCelebration",
          },
          {
            title: "41: Scholarship",
            input: true,
            key: "scholarship",
          },
          {
            title: "42: Gift",
            input: true,
            key: "gift",
          },
          {
            title: "43: Donation",
            input: true,
            key: "donation",
          },
          {
            title:
              "44: Rates and taxes, paid or payable to Government or any local body (excluding taxes on income)",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title: "44i: Union excise duty",
                    key: "RTUnionExciseDuty",
                    input: true,
                  },
                  {
                    title: "44ii: Service tax",
                    key: "RTServiceTax",
                    input: true,
                  },
                  {
                    title: "44iii: VAT/ Sales tax",
                    key: "RTVat",
                    input: true,
                  },
                  {
                    title: "44iv: Cess",
                    key: "RTCess",
                    input: true,
                  },
                  {
                    title: "44v: Central Goods & Service Tax (CGST)",
                    key: "RTCentralGST",
                    input: true,
                  },
                  {
                    title: "44vi: State Goods & Services Tax (SGST)",
                    key: "RTSTateGST",
                    input: true,
                  },
                  {
                    title: "44vii: Integrated Goods & Services Tax (IGST)",
                    key: "RTIntegratedGST",
                    input: true,
                  },
                  {
                    title:
                      "44viii: Union Territory Goods & Services Tax (UTGST)",
                    key: "RTUTGST",
                    input: true,
                  },
                  {
                    title:
                      "44ix: Any other rate, tax, duty or cess including STT and CTT",
                    key: "RTAnyOther",
                    input: true,
                  },
                  {
                    title:
                      "44x: Total rates and taxes paid or payable (44i + 44ii + 44iii + 44iv + 44v + 44vi + 44vii + 44viii + 44ix)",
                    key: "totalRatesAndTaxes",
                    input: true,
                    total: true,
                  },
                ],
              },
            ],
          },
          {
            title: "45: Audit feen",
            input: true,
            key: "auditFee",
          },
          {
            title: "46: Other expenses (specify nature and amount)",
            subCategory: [
              {
                title: "",
                key: "otherExpenses",
                addMore: true,
                input: true,
              },
              {
                title: "",
                subCategory: [
                  {
                    title: "46i: Total",
                    key: "totalOtherExpenses",
                    input: true,
                    total: true,
                  },
                ],
              },
            ],
          },
          {
            title: "47: bad debts",
            button:true,
            subCategory: [
              {
                title:
                  "47i: Bad debts(specify PAN of the person, if available, for whom Bad Debt for amount of Rs. 1 lakh or more is claimed and amount)",
                subCategory: [
                  {
                    title: "",
                    key: "badDebts",
                    addMore: true,
                    input: true,
                  },
                  {
                    title:
                      "47ii: Others (more than Rs. 1 lakh) where PAN is not available (providedetails using button Add/Edit BadDebts at the bottom.)",
                    input: true,
                    key: "otherMoreThanLakhNoPan",
                  },
                  {
                    title: "47iii: Others (amounts less than R s. 1 lakh)",
                    input: true,
                    key: "OtherLessthanLakh",
                  },
                  {
                    title:
                      "47iv: Total Bad Debt (47i (All PAN) + 47ii + 47iii)",
                    input: true,
                    key: "totalBadDebt",
                    total: true,
                  },
                ],
              },
            ],
          },
          {
            title: "48: Provision for bad and doubtful debts",
            input: true,
            key: "provisionForBadDoutfulDebt",
          },
          {
            title: "49: Other provisions",
            input: true,
            key: "otherProvisions",
          },
          {
            title:
              "50: Profit before interest, depreciation and taxes [15 – (16 to 21 + 22xi + 23v + 24 to 29 + 30iii + 31iii + 32iii + 33 to 43 + 44x + 45 + 46 + 47iv + 48 + 49)]",
            input: true,
            key: "profitBeforeInterest",
            total: true,
          },
          {
            title: "51: Interest",
            subCategory: [
              {
                title:
                  "51a: Paid outside India, or paid in India to a non-resident other than a company or a foreign company",
                input: true,
                key: "interestPaidOutsideIndia",
              },
              {
                title: "51ai: To others",
                input: true,
                key: "interestPaidOutsideOthers",
              },
              {
                title: "51b: Paid in India, or paid to a resident",
                input: true,
                key: "interestPaidInIndia",
              },
              {
                title: "51bi: To others",
                input: true,
                key: "interestPainInIndiaOthers",
              },
              {
                title: "51iii: Total (ai + aii + bi + bii)",
                input: true,
                key: "totalInterest",
                total: true,
              },
            ],
          },
          {
            title: "52: Depreciation and amortisation",
            input: true,
            key: "depreciationAmortisation",
          },
          {
            title: "53: Net Profit before taxes (50 - 51iii - 52 )",
            input: true,
            key: "netProfitBeforeTaxes",
            total: true,
          },
          {
            title: "54: Provision for current tax",
            input: true,
            key: "provisionForCurrentTax",
          },
          {
            title: "55: Provision for Deferred Tax",
            input: true,
            key: "provisionForDeferredTax",
          },
          {
            title: "56: Profit after tax( 53 - 54 - 55)",
            input: true,
            key: "profitAfterTax",
            total: true,
          },
          {
            title: "57: Balance brought forward from previous year",
            input: true,
            key: "balanceBroughtForwardPreviousYear",
          },
          {
            title: "58: Amount available for appropriation (56 + 57)",
            input: true,
            key: "amountAvailableAppropriation",
            total: true,
          },
          {
            title: "59: Appropriation",
            subCategory: [
              {
                title: "",
                subCategory: [
                  {
                    title: "59i: Transfer to reserves and surplus",
                    input: true,
                    key: "AppTransferToReserves",
                  },
                  {
                    title: "59ii: Proposed dividend/ Interim dividend",
                    input: true,
                    key: "AppProposedDividend",
                  },
                  {
                    title:
                      "59iii: Tax on dividend/ Tax on dividend for earlier years",
                    input: true,
                    key: "AppTaxOnDividend",
                  },
                  {
                    title:
                      "59iv: Appropriation towards Corporate Social Responsibility (CSR) activities (in case of companies covered under section 135 of Companies Act, 2013)",
                    input: true,
                    key: "AppAppropriationCSR",
                  },
                  {
                    title: "59v: Any Other Appropriation",
                    input: true,
                    key: "AppAnyOther",
                  },
                  {
                    title: "59vi: Total (59i + 59ii + 59iii + 59iv + 59v)",
                    input: true,
                    key: "totalAppropriation",
                    total: true,
                  },
                ],
              },
            ],
          },
          {
            title: "60: Balance carried to balance sheet (58 - 59vi)",
            input: true,
            key: "balanceCarriedToBalanceSheet",
            total: true,
          },
        ],
      },
    ],
  ];

  const handleSubmitBalanceSheet = () => {

    setLoader(true);
    let submitData = {
      itrId: ItrData.id ,
      detailBalanceSheet,
      sourcesOfFunds6,
      applicationsOfFunds6,
      manufacturingAccount6,
      tradingAccount6,
      profitAndLossAccount6
    }
    dispatch(
      saveDetailBalanceSheet6Action(
        submitData,
        (e) => {
          setLoader(false);
        }
      )
    );
  };

  useEffect(() => {
    setLoader(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getDetailBalanceSheet6Action(parm, (e) => {
        if (e.status === 200) {
          console.log("dataffff", e.data.sourcesOfFunds);
          setBadDebtData({ detailId: e.data.id, data: e?.data?.badDebts });
          setDetailBalanceSheet(e.data.id)
          if (e.data.sourcesOfFunds6.length > 0) {
            for (let items in sourcesOfFunds6) {
              sourcesOfFunds6[items] = e.data.sourcesOfFunds6[0][items];
            }
            for (let items in applicationsOfFunds6) {
              applicationsOfFunds6[items] =
                e.data.applicationsOfFunds6[0][items];
            }
            for (let items in manufacturingAccount6) {
              manufacturingAccount6[items] =
                e.data.manufacturingAccount6[0][items];
            }
            for (let items in tradingAccount6) {
              tradingAccount6[items] = e.data.tradingAccount6[0][items];
            }
            for (let items in profitAndLossAccount6) {
              profitAndLossAccount6[items] =
                e.data.profitAndLossAccount6[0][items];
            }
          }
          setLoader(false);
        } else {
          setLoader(false);
          toast.error("Something went wrong");
        }
      })
    );
  }, [badDebtPop, dispatch]);

  console.log("Data---data", totalOIAnyOtherIncome);

  return (
    <div className="container_box">
      <LoadingBox open={loader} />
      {allSections &&
        allSections?.map((first) =>
          first?.map((second) => (
            <AccordianBox key={second.sectionTitle} title={second.sectionTitle}>
              <StyleFormBox>
                {second &&
                  second?.mainData?.map((third) => {
                    return (
                      <>
                        <div className="py-2">
                          <div className="flex_input my-2 ">
                            <h2>{third.title}</h2>
                            {third?.input && (
                              <div style={{ paddingLeft: "15px" }}>
                                <CurrencyField
                                  error={""}
                                  value={
                                    third.total
                                      ? totals[third.key]
                                      : second.val[third?.key]
                                  }
                                  handleChange={(e) => {
                                    !third.total &&
                                      second.state({
                                        ...second.val,
                                        [third.key]: e.target.value,
                                      });
                                  }}
                                />
                              </div>
                            )}
                            {third?.button && (
                              <div className="flex justify-end">
                                <AddActionButton
                                  title={badDebtData?.data?.length>0?"Edit Bad Debt": strings.button.addBadDebts}
                                  handleChange={() => {
                                    setBadDebtPop(true);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          {third?.subCategory?.map((sub) =>
                            sub.addMore ? (
                              <div style={{ padding: "0 15px 0 15px" }}>
                                <label>{sub.title}</label>
                                {second.val[sub["key"]]?.map((info, ix) => (
                                  <div className="flex gap-5 items-center	">
                                    {ix + 1}
                                    <Input
                                      inputType="default"
                                      value={info.name}
                                      onChange={(e) =>
                                        second.state((pre) => {
                                          const updated = [...pre[sub.key]];
                                          updated[ix] = {
                                            ...updated[ix],
                                            name: e.target.value,
                                          };
                                          return {
                                            ...pre,
                                            [sub.key]: updated,
                                          };
                                        })
                                      }
                                    />
                                    <CurrencyField
                                      error={""}
                                      value={info.amount}
                                      handleChange={(e) =>
                                        second.state((pre) => {
                                          const updated = [...pre[sub.key]];
                                          updated[ix] = {
                                            ...updated[ix],
                                            amount: e.target.value,
                                          };
                                          return {
                                            ...pre,
                                            [sub.key]: updated,
                                          };
                                        })
                                      }
                                    />

                                    <button
                                      className="delete_button mb-2"
                                      onClick={() => {
                                        ix != 0 &&
                                          second.state((prev) => ({
                                            ...prev,
                                            [sub["key"]]: prev[
                                              sub["key"]
                                            ].filter(
                                              (_, index) => index !== ix
                                            ),
                                          }));
                                      }}
                                    >
                                      <RiDeleteBin6Fill />
                                    </button>
                                  </div>
                                ))}

                                <div className="flex justify-end mt-5">
                                  <button
                                    className="add_item_button"
                                    onClick={() =>
                                      second.state((previous) => ({
                                        ...previous,
                                        [sub["key"]]: [
                                          ...(previous[sub["key"]] || []),
                                          { name: "", amount: "" },
                                        ],
                                      }))
                                    }
                                  >
                                    {strings.button.add_more_item}
                                  </button>

                                  <AddActionButton
                                    title={strings.button.add}
                                    handleChange={(e) => {
                                      console.log(e);
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="">
                                <div className="flex_input my-2">
                                  {sub.title.length > 0 && (
                                    <label>{sub.title}</label>
                                  )}
                                  {sub.input && (
                                    <div style={{ paddingLeft: "15px" }}>
                                      <CurrencyField
                                        error={""}
                                        value={
                                          sub.total
                                            ? totals[sub.key]
                                            : second.val[sub?.key]
                                        }
                                        handleChange={(e) => {
                                          !sub.total &&
                                            second.state({
                                              ...second.val,
                                              [sub.key]: e.target.value,
                                            });
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                {sub?.subCategory?.map((child) =>
                                  child.addMore ? (
                                    <div style={{ padding: "0 15px 0 15px" }}>
                                      <label>{child.title}</label>
                                      {second.val[child["key"]]?.map(
                                        (info, ix) => (
                                          <div className="flex gap-5 items-center	">
                                            {ix + 1}
                                            <Input
                                              inputType={
                                                child.type === "pan"
                                                  ? "pan"
                                                  : "default"
                                              }
                                              value={
                                                child.type === "pan"
                                                  ? info.pan
                                                  : info.name
                                              }
                                              onChange={(e) =>
                                                second.state((pre) => {
                                                  if (child.type === "pan") {
                                                    const updated = [
                                                      ...pre[child.key],
                                                    ];
                                                    updated[ix] = {
                                                      ...updated[ix],
                                                      pan: e.target.value,
                                                    };
                                                    return {
                                                      ...pre,
                                                      [child.key]: updated,
                                                    };
                                                  } else {
                                                    const updated = [
                                                      ...pre[child.key],
                                                    ];
                                                    updated[ix] = {
                                                      ...updated[ix],
                                                      name: e.target.value,
                                                    };
                                                    return {
                                                      ...pre,
                                                      [child.key]: updated,
                                                    };
                                                  }
                                                })
                                              }
                                            />
                                            <CurrencyField
                                              error={""}
                                              value={info.amount}
                                              handleChange={(e) =>
                                                second.state((pre) => {
                                                  const updated = [
                                                    ...pre[child.key],
                                                  ];
                                                  updated[ix] = {
                                                    ...updated[ix],
                                                    amount: e.target.value,
                                                  };
                                                  return {
                                                    ...pre,
                                                    [child.key]: updated,
                                                  };
                                                })
                                              }
                                            />

                                            <button
                                              style={{ marginLeft: "0px" }}
                                              className="delete_button mb-2"
                                              onClick={() => {
                                                ix != 0 &&
                                                  second.state((prev) => ({
                                                    ...prev,
                                                    [child["key"]]: prev[
                                                      child["key"]
                                                    ].filter(
                                                      (_, index) => index !== ix
                                                    ),
                                                  }));
                                              }}
                                            >
                                              <RiDeleteBin6Fill />
                                            </button>
                                          </div>
                                        )
                                      )}

                                      <div className="flex justify-end mt-5">
                                        <button
                                          className="add_item_button"
                                          onClick={() =>
                                            second.state((previous) => ({
                                              ...previous,
                                              [child["key"]]: [
                                                ...(previous[child["key"]] ||
                                                  []),
                                                child.type === "pan"
                                                  ? { pan: "", amount: "" }
                                                  : { name: "", amount: "" },
                                              ],
                                            }))
                                          }
                                        >
                                          {strings.button.add_more_item}
                                        </button>

                                        <AddActionButton
                                          title={strings.button.add}
                                          handleChange={(e) => {
                                            console.log(e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="">
                                      <div className="flex_input my-2">
                                        <div style={{ paddingLeft: "15px" }}>
                                          <label>{child.title}</label>
                                        </div>
                                        {child.input && (
                                          <CurrencyField
                                            error={""}
                                            value={
                                              child.total
                                                ? totals[child.key]
                                                : second.val[child?.key]
                                            }
                                            handleChange={(e) => {
                                              !child.total &&
                                                second.state({
                                                  ...second.val,
                                                  [child?.key]: e.target.value,
                                                });
                                            }}
                                          />
                                        )}
                                      </div>
                                      {child?.subCategory?.map((grandChild) =>
                                        grandChild?.addMore ? (
                                          <div
                                            style={{ padding: "0 15px 0 35px" }}
                                          >
                                            <label>{grandChild.title}</label>
                                            {second.val[grandChild["key"]]?.map(
                                              (info, ix) => (
                                                <div className="flex gap-5 items-center	">
                                                  {ix + 1}
                                                  <Input
                                                    inputType={
                                                      grandChild.type === "pan"
                                                        ? "pan"
                                                        : "default"
                                                    }
                                                    value={
                                                      grandChild.type === "pan"
                                                        ? info.pan
                                                        : info.name
                                                    }
                                                    onChange={(e) =>
                                                      second.state((pre) => {
                                                        if (
                                                          grandChild.type ===
                                                          "pan"
                                                        ) {
                                                          const updated = [
                                                            ...pre[
                                                              grandChild.key
                                                            ],
                                                          ];
                                                          updated[ix] = {
                                                            ...updated[ix],
                                                            pan: e.target.value,
                                                          };
                                                          return {
                                                            ...pre,
                                                            [grandChild.key]:
                                                              updated,
                                                          };
                                                        } else {
                                                          const updated = [
                                                            ...pre[
                                                              grandChild.key
                                                            ],
                                                          ];
                                                          updated[ix] = {
                                                            ...updated[ix],
                                                            name: e.target
                                                              .value,
                                                          };
                                                          return {
                                                            ...pre,
                                                            [grandChild.key]:
                                                              updated,
                                                          };
                                                        }
                                                      })
                                                    }
                                                  />
                                                  <CurrencyField
                                                    error={""}
                                                    value={info.amount}
                                                    handleChange={(e) =>
                                                      second.state((pre) => {
                                                        const updated = [
                                                          ...pre[
                                                            grandChild.key
                                                          ],
                                                        ];
                                                        updated[ix] = {
                                                          ...updated[ix],
                                                          amount:
                                                            e.target.value,
                                                        };
                                                        return {
                                                          ...pre,
                                                          [grandChild.key]:
                                                            updated,
                                                        };
                                                      })
                                                    }
                                                  />

                                                  <button
                                                    style={{
                                                      marginLeft: "0px",
                                                    }}
                                                    className="delete_button mb-2"
                                                    onClick={() => {
                                                      ix != 0 &&
                                                        second.state(
                                                          (prev) => ({
                                                            ...prev,
                                                            [grandChild["key"]]:
                                                              prev[
                                                                grandChild[
                                                                  "key"
                                                                ]
                                                              ].filter(
                                                                (_, index) =>
                                                                  index !== ix
                                                              ),
                                                          })
                                                        );
                                                    }}
                                                  >
                                                    <RiDeleteBin6Fill />
                                                  </button>
                                                </div>
                                              )
                                            )}

                                            <div className="flex justify-end mt-5">
                                              <button
                                                className="add_item_button"
                                                onClick={() =>
                                                  second.state((previous) => ({
                                                    ...previous,
                                                    [grandChild["key"]]: [
                                                      ...(previous[
                                                        grandChild["key"]
                                                      ] || []),
                                                      grandChild.type === "pan"
                                                        ? {
                                                            pan: "",
                                                            amount: "",
                                                          }
                                                        : {
                                                            name: "",
                                                            amount: "",
                                                          },
                                                    ],
                                                  }))
                                                }
                                              >
                                                {strings.button.add_more_item}
                                              </button>

                                              <AddActionButton
                                                title={strings.button.add}
                                                handleChange={(e) => {
                                                  console.log(e);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="flex_input my-2">
                                            <div
                                              style={{
                                                padding: "0 0px 0 0",
                                                margin: "0 -10px 0 30px",
                                              }}
                                            >
                                              <label>{grandChild.title}</label>
                                            </div>
                                            {grandChild?.input && (
                                              <CurrencyField
                                                error={""}
                                                value={
                                                  grandChild?.total
                                                    ? totals[grandChild.key]
                                                    : second.val[child?.key]
                                                }
                                                handleChange={(e) => {
                                                  !grandChild.total &&
                                                    second.state({
                                                      ...second.val,
                                                      [grandChild?.key]:
                                                        e.target.value,
                                                    });
                                                }}
                                              />
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </>
                    );
                  })}
              </StyleFormBox>
            </AccordianBox>
          ))
        )}
      <button
        className="primary_button mt-5"
        type="submit"
        onClick={() => {
          handleSubmitBalanceSheet();
        }}
      >
        {strings.button.save}
      </button>

      <FormDialogBox
        open={badDebtPop}
        handleClose={() => {
          setBadDebtPop(false);
        }}
        formTitle="Bad Debts"
      >
        <BadDebts
          badData={badDebtData}
          setBadDebtPop={(e) => {
            setBadDebtPop(e);
          }}
          changing={badDebtPop}
        />
      </FormDialogBox>
    </div>
  );
}
