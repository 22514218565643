import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "../../../../styledComponent/Inputs/Select";
import Input from "../../../../styledComponent/Inputs/Input";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { toast } from "react-toastify";
import { removeEmptyKeys } from "../../../../utils/helpers";
import {
  saveBondsAndDebentureAction,
  updateBondsAndDebentureAction,
} from "../../../../redux/itr/action";

export default function BondsDebentures({
  handleCallback,
  editForm,
  stocksAssets,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);

  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData} = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    typeOfBonds: Yup.string().required(strings.validation_message.required),
    totalSalePrice: Yup.string().required(strings.validation_message.required),
    totalPurchasePrice: Yup.string().required(strings.validation_message.required),
    dateOfSale: Yup.string().required(strings.validation_message.required),
    dateOfExercise: Yup.string().required(strings.validation_message.required),
  });

  const handleSubmitForm = (values) => {
    if (values.id) {
      setLoader(true);
      dispatch(
        updateBondsAndDebentureAction(removeEmptyKeys(values), (e) => {
          console.log(e);
          if (e.status === 200) {
            toast.success("Successfully update data");
            setLoader(false);
            handleCallback(e.data);
          } else {
            setLoader(false);
          }
        })
      );
    } else {
      setLoader(true);
      let { id, ...newData } = values;
      dispatch(
        saveBondsAndDebentureAction(removeEmptyKeys(newData), (e) => {
          if (e.status === 200) {
            toast.success("Successfully upload data");
            setLoader(false);
            handleCallback(e.data);
          } else {
            setLoader(false);
          }
        })
      );
    }
  };

  useEffect(() => {
    setLoader(true);
    setFormData({
      itrId: ItrData.id,
      id: editForm?.id || "",
      typeOfBonds: editForm?.typeOfBonds?.id || "",
      dateOfSale: editForm?.dateOfSale?.split("T")[0] || "",
      dateOfExercise: editForm?.dateOfExercise?.split("T")[0] || "",
      descriptionOfAssets: editForm?.descriptionOfAssets || "",
      totalSalePrice: editForm?.totalSalePrice || "",
      transferExpenses: editForm?.transferExpenses || "",
      totalPurchasePrice: editForm?.totalPurchasePrice || "",
    });
    setLoader(false);
  }, [editForm]);

  return (
    <section>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <div>
            <StyleFormBox>
              <h4>{strings.income.add_bonds_debentures}</h4>
              <p>{strings.income.specify_those_you_sold_redeemed}</p>
              <div className="flex_input my-2">
                <label>{strings.income.select_type_bond} *</label>
                <Select
                  value={values.typeOfBonds}
                  error={errors.typeOfBonds}
                  name={"typeOfBonds"}
                  onChange={handleChange}
                >
                  <option value={""}>
                    {strings.label.choose_option}
                  </option>
                  {stocksAssets?.map((i, ix) => (
                    <option value={i.id} key={ix}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="flex_input my-2">
                <label>{strings.income.date_of_sale} *</label>
                <Input
                  inputType={"date"}
                  name={"dateOfSale"}
                  value={values.dateOfSale}
                  error={errors.dateOfSale}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-2">
                <label>{strings.income.date_of_exercise} *</label>
                <Input
                  inputType={"date"}
                  name={"dateOfExercise"}
                  value={values.dateOfExercise}
                  error={errors.dateOfExercise}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

            <StyleFormBox>
              <h4>{strings.income.add_details_asset}</h4>
              <hr />
              <div className="flex_input my-2">
                <label>{strings.income.description_of_asset_sold}</label>
                <Input
                  name={"descriptionOfAssets"}
                  value={values.descriptionOfAssets}
                  error={errors.descriptionOfAssets}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.sale_value} *</label>
                <Input
                  inputType={"currency"}
                  name={"totalSalePrice"}
                  value={values.totalSalePrice}
                  error={errors.totalSalePrice}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.purchase_price} *</label>
                <Input
                  inputType={"currency"}
                  name={"totalPurchasePrice"}
                  value={values.totalPurchasePrice}
                  error={errors.totalPurchasePrice}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.transfer_expenses_if_any}</label>
                <Input
                  inputType={"currency"}
                  name={"transferExpenses"}
                  value={values.transferExpenses}
                  error={errors.transferExpenses}
                  onChange={handleChange}
                />
              </div>
              <span className="note_message">
                {
                  strings.message
                    .received_any_interest_coupon_payment_in_same_financial_year
                }
              </span>
            </StyleFormBox>

            <button className="primary_button mt-4" onClick={handleSubmit}>
              {strings.button.submit}
            </button>
          </div>
        )}
      </Formik>
    </section>
  );
}
