import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { removeEmptyKeys } from "../../../../../utils/helpers";
import Input from "../../../../../styledComponent/Inputs/Input";
import Select from "../../../../../styledComponent/Inputs/Select";
import {
  editManufacturingConcernAction,
  saveManufacturingConcernAction,
} from "../../../../../redux/itr/action";
import { toast } from "react-toastify";
import LoadingBox from "../../../../DialogBox/LoadingBox";
import * as Yup from "yup";

export default function ManufactureRowMaterials({
  data,
  isFinishedProduct,
  unitCategory,
  handleCallback,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);

  const { ItrData } = useSelector((s) => s.itr);
  const { strings } = useSelector((s) => s.platformSetting);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    unitOfMeasures: Yup.number().required(strings.validation_message.required),
    name: Yup.string().required(strings.validation_message.required),
    openingStock: Yup.number().required(strings.validation_message.required),
    consumptionDuringPreviousYear: Yup.number().required(strings.validation_message.required),
    SaleDuringPreviousYear: Yup.number().required(strings.validation_message.required),
    purchaseDuringPreviousYear: Yup.number().required(strings.validation_message.required),
    closingStock: Yup.number().required(strings.validation_message.required),
    shortageExcess: Yup.number().required(strings.validation_message.required),
    percentageOfYield: Yup.number()
    .min(0.01, "Percentage should be more than 0")
    .max(100, "Percentage cannot exceed 100"),
  });
  const validationFinishedSchema = Yup.object().shape({
    unitOfMeasures: Yup.number().required(strings.validation_message.required),
    name: Yup.string().required(strings.validation_message.required),
    openingStock: Yup.number().required(strings.validation_message.required),
    consumptionDuringPreviousYear: Yup.number().required(strings.validation_message.required),
    SaleDuringPreviousYear: Yup.number().required(strings.validation_message.required),
    purchaseDuringPreviousYear: Yup.number().required(strings.validation_message.required),
    closingStock: Yup.number().required(strings.validation_message.required),
    shortageExcess: Yup.number().required(strings.validation_message.required),
  });
  const handleSubmitForm = (obj) => {
    setLoader(true);
    let { type, ...data } = obj;
    const action =
      type == "edit"
        ? editManufacturingConcernAction
        : saveManufacturingConcernAction;
    dispatch(
      action(data, (e) => {
        if (e.status === 200) {
          handleCallback(type, e.data);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    setFormData({
      name: data?.name || "",
      openingStock: data?.openingStock || "",
      SaleDuringPreviousYear: data?.SaleDuringPreviousYear || "",
      purchaseDuringPreviousYear: data?.purchaseDuringPreviousYear || "",
      closingStock: data?.closingStock || "",
      shortageExcess: data?.shortageExcess || "",
      unitOfMeasures: data?.unitOfMeasures?.id || "",
      consumptionDuringPreviousYear: data?.consumptionDuringPreviousYear || "",
      yieldFinishedProduct: data?.yieldFinishedProduct || "",
      percentageOfYield: data?.percentageOfYield || "",
      isFinishedProduct: data?.isFinishedProduct || isFinishedProduct,
      itrId: ItrData?.id,
      id: data?.id || "",
      type: data?.id ? "edit" : "add",
    });
    setLoader(false);
  }, [data]);
  console.log(formData, isFinishedProduct)
  return (
    <div>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={isFinishedProduct ? validationFinishedSchema : validationSchema}
        onSubmit={(values) => {
          console.log(values);
          handleSubmitForm(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <StyleFormBox>
              <h4>
                {values.isFinishedProduct
                  ? strings.income.add_manufacture_finished_product
                  : strings.income.add_manufacture_raw_materials}
              </h4>
              <hr />
              <div className="flex_input my-3">
                <label>{strings.label.name} *</label>
                <Input
                  name={"name"}
                  error={errors.name}
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.unit_of_measure} *</label>

                <Select
                  name={"unitOfMeasures"}
                  error={errors.unitOfMeasures}
                  value={values?.unitOfMeasures}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {unitCategory?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.opening_stock} *</label>
                <Input
                  type="number"
                  name={"openingStock"}
                  error={errors.openingStock}
                  value={values.openingStock}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.purchase_during_previous_year} *</label>
                <Input
                  type="number"
                  name={"purchaseDuringPreviousYear"}
                  error={errors.purchaseDuringPreviousYear}
                  value={values.purchaseDuringPreviousYear}
                  onChange={handleChange}
                />
              </div>

              {values.isFinishedProduct ? (
                <div className="flex_input my-3">
                  <label>
                    {strings.income.quantity_manufactured_during_pre_year}
                  </label>
                  <Input
                    type="number"
                    name={"consumptionDuringPreviousYear"}
                    error={errors.consumptionDuringPreviousYear}
                    value={values.consumptionDuringPreviousYear}
                    onChange={handleChange}
                  />
                </div>
              ) : (
                <div className="flex_input my-3">
                  <label>
                    {strings.income.consuption_during_previous_year} *
                  </label>
                  <Input
                    type="number"
                    name={"consumptionDuringPreviousYear"}
                    error={errors.consumptionDuringPreviousYear}
                    value={values.consumptionDuringPreviousYear}
                    onChange={handleChange}
                  />
                </div>
              )}

              <div className="flex_input my-3">
                <label>{strings.income.sales_during_previous_year} *</label>
                <Input
                  type="number"
                  name={"SaleDuringPreviousYear"}
                  error={errors.SaleDuringPreviousYear}
                  value={values.SaleDuringPreviousYear}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.closing_stock} *</label>
                <Input
                  type="number"
                  name={"closingStock"}
                  error={errors.closingStock}
                  value={values.closingStock}
                  onChange={handleChange}
                />
              </div>
              {!values.isFinishedProduct && (
                <>
                  <div className="flex_input my-3">
                    <label>{strings.income.yield_finished_products}</label>
                    <Input
                      type="number"
                      name="yieldFinishedProduct"
                      error={errors.yieldFinishedProduct}
                      value={values.yieldFinishedProduct}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-3">
                    <label>{strings.income.percentage_of_yield}</label>
                    <Input
                      type="number"
                      inputType="percentage"
                      name="percentageOfYield"
                      error={errors.percentageOfYield}
                      value={values.percentageOfYield}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
              <div className="flex_input my-3">
                <label>{strings.income.shortage_excess} *</label>
                <Input
                  type="number"
                  name={"shortageExcess"}
                  error={errors.shortageExcess}
                  value={values.shortageExcess}
                  onChange={handleChange}
                />
              </div>
              <button className="primary_button my-4" type="submit">
                {strings.button.save_and_close}
              </button>
            </StyleFormBox>
          </form>
        )}
      </Formik>
    </div>
  );
}
