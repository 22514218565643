import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import AccordianBox from "../../../../../styledComponent/Form/AccordianBox";
import Input from "../../../../../styledComponent/Inputs/Input";
import { FieldArray, Formik } from "formik";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import { removeEmptyKeys } from "../../../../../utils/helpers";
import {
  editBooksAccountNotMaintained,
  saveBooksAccountNotMaintained,
} from "../../../../../redux/itr/action";
import LoadingBox from "../../../../DialogBox/LoadingBox";

export default function AccountMaintained({
  data,
  businessType,
  handleCallback,
}) {
  const [formData, setFormData] = useState({});
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  const handleSubmitForm = (obj) => {
    setLoader(true);
    let { type, ...data } = obj;
    if(data?.id){
      delete data?.id;
    }
    const action = saveBooksAccountNotMaintained;
    dispatch(
      action(data, (e) => {
        if (e.status == 200) {
          handleCallback(type, e.data);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    setFormData({
      amountOfTotalSunDRs: data?.amountOfTotalSunDRs || 0,
      amountOfTotalSunCRs: data?.amountOfTotalSunCRs || 0,
      amountOfTotalStockInTrade: data?.amountOfTotalStockInTrade || 0,
      amountOfCashBalance: data?.amountOfCashBalance || 0,
      businessAmountThroughAccountPayee:
        data?.businessAmountThroughAccountPayee || 0,
      businessAnyOtherMode: data?.businessAnyOtherMode || 0,
      businessGrossProfitAmount: data?.businessGrossProfitAmount || 0,
      businessExpensesAmount: data?.businessExpensesAmount || 0,
      professionAmountThroughAccountPayee:
        data?.professionAmountThroughAccountPayee || 0,
      professionAnyOtherMode: data?.professionAnyOtherMode || 0,
      professionGrossProfitAmount: data?.professionGrossProfitAmount || 0,
      professionExpensesAmount: data?.professionExpensesAmount || 0,
      businessDetails: data?.businessDetails || [],

      itrId: ItrData?.id,
      id: data?.id || "",
      type: data.id ? "edit" : "add",
    });
    setLoader(false);
  }, [data]);

  return (
    <>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <FieldArray name="businessDetails">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <div className="flex_input my-2 items-center">
                      <label>{strings.income.nature_of_business}</label>
                      <label>{strings.income.trade_name}</label>
                      <label>{strings.label.description}</label>
                    </div>
                    {values?.businessDetails?.map((item, index) => (
                      <div className="flex_input my-2 items-center" key={index}>
                        <select
                          name={`businessDetails.${index}.natureOfBusiness`}
                          value={item.natureOfBusiness}
                          error={
                            errors?.businessDetails &&
                            errors?.businessDetails[index]?.natureOfBusiness
                          }
                          onChange={handleChange}
                        >
                          <option value="">
                            {strings.label.choose_option}
                          </option>

                          {businessType?.map((i, ix) =>
                            i.parentId === 0 ? (
                              <optgroup key={ix} label={i.name}>
                                {i.subCategory?.map((j, subIx) => (
                                  <option value={j.id} key={`${ix}-${subIx}`}>
                                    {j.name}
                                  </option>
                                ))}
                              </optgroup>
                            ) : (
                              <option value={i.id} key={ix}>
                                {i.name}
                              </option>
                            )
                          )}
                        </select>

                        <Input
                          name={`businessDetails.${index}.tradeName`}
                          value={item.tradeName}
                          error={
                            errors?.businessDetails &&
                            errors?.businessDetails[index]?.tradeName
                          }
                          onChange={handleChange}
                        />

                        <Input
                          name={`businessDetails.${index}.description`}
                          value={item.description}
                          error={
                            errors?.businessDetails &&
                            errors?.businessDetails[index]?.description
                          }
                          onChange={handleChange}
                        />

                        <button
                          className="delete_button mb-2"
                          onClick={(e) => {
                            e.preventDefault();
                            remove(index);
                          }}
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            natureOfBusiness: "",
                            tradeName: "",
                            description: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              <StyleFormBox>
                <p>{strings.message.this_page_give_sheet_way_to_enter_data}</p>
              </StyleFormBox>

              <AccordianBox
                title={strings.income.regular_book_account_not_maintained}
              >
                <p>
                  {
                    strings.income
                      .regular_book_account_not_maintained_description
                  }
                </p>

                <div className="flex_input my-3">
                  <label>{strings.income.a_sundry_debtors}</label>
                  <Input
                    name={"amountOfTotalSunDRs"}
                    inputType={"currency"}
                    error={errors.amountOfTotalSunDRs}
                    value={values?.amountOfTotalSunDRs}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.b_sundry_creditors}</label>
                  <Input
                    name={"amountOfTotalSunCRs"}
                    inputType={"currency"}
                    error={errors.amountOfTotalSunCRs}
                    value={values?.amountOfTotalSunCRs}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-3">
                  <label>{strings.income.c_amount_total_stock_trade}</label>
                  <Input
                    name={"amountOfTotalStockInTrade"}
                    inputType={"currency"}
                    error={errors.amountOfTotalStockInTrade}
                    value={values?.amountOfTotalStockInTrade}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-3">
                  <label>{strings.income.d_amount_cash_balance}</label>
                  <Input
                    name={"amountOfCashBalance"}
                    inputType={"currency"}
                    error={errors.amountOfCashBalance}
                    value={values?.amountOfCashBalance}
                    onChange={handleChange}
                  />
                </div>

                <hr className="mb-4" />
                <p>
                  {
                    strings.income
                      .regular_book_account_not_maintained_prev_year_description
                  }
                </p>

                <label>{strings.income.i_carrying_on_business}</label>

                <div className="flex_input my-3">
                  <label>{strings.income.ia_gross_turnover_receipt}</label>

                  <Input
                    inputType={"currency"}
                    value={
                      Number(values?.businessAmountThroughAccountPayee) +
                      Number(values?.businessAnyOtherMode)
                    }
                    disabled
                  />
                </div>

                <div className="mx-4">
                  <div className="flex_input my-3">
                    <label>{strings.income.iai_payee_cheque_or_bank}</label>
                    <Input
                      name={"businessAmountThroughAccountPayee"}
                      inputType={"currency"}
                      error={errors.businessAmountThroughAccountPayee}
                      value={values?.businessAmountThroughAccountPayee}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-3">
                    <label>{strings.income.iaii_any_other_mode}</label>
                    <Input
                      name={"businessAnyOtherMode"}
                      inputType={"currency"}
                      error={errors.businessAnyOtherMode}
                      value={values?.businessAnyOtherMode}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex_input my-3">
                  <label>{strings.income.ib_gross_profite}</label>
                  <Input
                    name={"businessGrossProfitAmount"}
                    inputType={"currency"}
                    error={errors.businessGrossProfitAmount}
                    value={values?.businessGrossProfitAmount}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-3">
                  <label>{strings.income.ic_expenses}</label>
                  <Input
                    name={"businessExpensesAmount"}
                    inputType={"currency"}
                    error={errors.businessExpensesAmount}
                    value={values?.businessExpensesAmount}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-3">
                  <label>{strings.income.id_net_profit} </label>
                  <Input
                    inputType={"currency"}
                    value={
                      Number(values?.businessGrossProfitAmount) -
                      Number(values?.businessExpensesAmount)
                    }
                    disabled
                  />
                </div>

                <label>{strings.income.ii_carrying_profession}</label>

                <div className="flex_input my-3">
                  <label>{strings.income.iia_gross_receipt}</label>
                  <Input
                    inputType={"currency"}
                    value={
                      Number(values?.professionAmountThroughAccountPayee) +
                      Number(values?.professionAnyOtherMode)
                    }
                    disabled
                  />
                </div>

                <div className="mx-4">
                  <div className="flex_input my-3">
                    <label>{strings.income.iiai_through_payee_cheque}</label>
                    <Input
                      name={"professionAmountThroughAccountPayee"}
                      inputType={"currency"}
                      error={errors.professionAmountThroughAccountPayee}
                      value={values?.professionAmountThroughAccountPayee}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-3">
                    <label>{strings.income.iiaii_any_other_mode}</label>
                    <Input
                      name={"professionAnyOtherMode"}
                      inputType={"currency"}
                      error={errors.professionAnyOtherMode}
                      value={values?.professionAnyOtherMode}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex_input my-3">
                  <label>{strings.income.iib_gross_profite}</label>
                  <Input
                    name={"professionGrossProfitAmount"}
                    inputType={"currency"}
                    error={errors.professionGrossProfitAmount}
                    value={values?.professionGrossProfitAmount}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-3">
                  <label>{strings.income.iic_expenses}</label>
                  <Input
                    name={"professionExpensesAmount"}
                    inputType={"currency"}
                    error={errors.professionExpensesAmount}
                    value={values?.professionExpensesAmount}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-3">
                  <label>{strings.income.iid_net_profit} </label>

                  <Input
                    inputType={"currency"}
                    value={
                      Number(values?.professionGrossProfitAmount) -
                      Number(values?.professionExpensesAmount)
                    }
                    disabled
                  />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.iii_total_profit}</label>
                  <Input
                    inputType={"currency"}
                    value={
                      Number(values?.businessGrossProfitAmount) -
                      Number(values?.businessExpensesAmount) +
                      Number(values?.professionGrossProfitAmount) -
                      Number(values?.professionExpensesAmount)
                    }
                    disabled
                  />
                </div>
              </AccordianBox>
              <button className="primary_button my-4" type="submit">
                {strings.button.save_and_close}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
