import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Input from "../../../../../styledComponent/Inputs/Input";
import Select from "../../../../../styledComponent/Inputs/Select";
import {
  editTradingConcernAction,
  saveTradingConcernAction,
} from "../../../../../redux/itr/action";
import { toast } from "react-toastify";
import { removeEmptyKeys } from "../../../../../utils/helpers";
import * as Yup from "yup";
import LoadingBox from "../../../../DialogBox/LoadingBox";

export default function TradingConcern({ data, unitCategory ,handleCallback }) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);

  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const validationSchema = Yup.object().shape({
    unit: Yup.number().required(strings.validation_message.required),
    name: Yup.string().required(strings.validation_message.required),
    openingStock: Yup.number().required(strings.validation_message.required),
    saleDuringPreviousYear: Yup.number().required(strings.validation_message.required),
    purchaseDuringPreviousYear: Yup.number().required(strings.validation_message.required),
    closingStock: Yup.number().required(strings.validation_message.required),
    shortageExcess: Yup.number().required(strings.validation_message.required),
  });
  const dispatch = useDispatch();
  const handleSubmitForm = (obj) => {
    setLoader(true);
    let { type, ...data } = obj;
    const action =
      type === "edit" ? editTradingConcernAction : saveTradingConcernAction;
    dispatch(
      action(data, (e) => {
        if (e.status === 200) {
          handleCallback(type, e.data);
          toast.success(
            `Successfully ${type === "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    setFormData({
      name: data?.name || "",
      unit: data?.unit?.id || "",
      openingStock: data?.openingStock || "",
      saleDuringPreviousYear: data?.saleDuringPreviousYear || "",
      purchaseDuringPreviousYear: data?.purchaseDuringPreviousYear || "",
      closingStock: data?.closingStock || "",
      shortageExcess: data?.shortageExcess || "",
      itrId: ItrData?.id,
      id: data?.id || "",
      type: data?.id ? "edit" : "add",
    });
    setLoader(false);
  }, [data]);

  return (
    <div>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <StyleFormBox>
              <h4>{strings.income.add_trading_company_details}</h4>
              <hr />
              <div className="flex_input my-3">
                <label>{strings.label.name} *</label>
                <Input
                  name={"name"}
                  error={errors.name}
                  value={values?.name}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.unit} *</label>

                <Select
                  name={"unit"}
                  error={errors.unit}
                  value={values?.unit}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {unitCategory?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.opening_stock} *</label>
                <Input
                  type="number"
                  name={"openingStock"}
                  error={errors.openingStock}
                  value={values.openingStock}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.sales_during_previous_year} *</label>
                <Input
                  type="number"
                  name={"saleDuringPreviousYear"}
                  error={errors.saleDuringPreviousYear}
                  value={values.saleDuringPreviousYear}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.purchase_during_previous_year} *</label>
                <Input
                  type="number"
                  name={"purchaseDuringPreviousYear"}
                  error={errors.purchaseDuringPreviousYear}
                  value={values.purchaseDuringPreviousYear}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.closing_stock} *</label>
                <Input
                  type="number"
                  name={"closingStock"}
                  error={errors.closingStock}
                  value={values.closingStock}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.shortage_excess} *</label>
                <Input
                  type="number"
                  name={"shortageExcess"}
                  error={errors.shortageExcess}
                  value={values.shortageExcess}
                  onChange={handleChange}
                />
              </div>

              <button className="primary_button my-4" type="submit" disabled={loader}>
                {strings.button.save_and_close}
              </button>
            </StyleFormBox>
          </form>
        )}
      </Formik>
    </div>
  );
}
