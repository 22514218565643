import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "../../../../styledComponent/Inputs/Select";
import Input from "../../../../styledComponent/Inputs/Input";
import { toast } from "react-toastify";
import {
  deleteMemberAction,
  getPersonalInfoAction,
  setPersonalInfoAction,
} from "../../../../redux/itr/action";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";

import AddMembers from "./AddMembers";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import AdditionalInfo from "./AdditionalInfo";
import LoadingBox from "../../../DialogBox/LoadingBox";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import { addOpacity, removeEmptyKeys } from "../../../../utils/helpers";
import { FaAngleDown } from "react-icons/fa6";
import Phone from "../../../../styledComponent/Inputs/Phone";

export default function PersonalInfoFirm({ handleNext }) {
  const [formData, setFormData] = useState({});
  const [citiesList, setCitiesList] = useState([]);
  const [activeState, setActiveState] = useState(1);
  const [empCategorys, setEmpCategorys] = useState([]);
  const [assessmentYear, setAssessmentYear] = useState([]);
  const [addMemberBox, setAddMemberBox] = useState(false);
  const [addAdditionalInfoBox, setAddAdditionalInfoBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addBankBox, setAddBankBox] = useState(false);
  const [activeMember, setActiveMember] = useState();
  const [members, setMembers] = useState([]);
  const { strings, theme, states } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const handleCallback = (e) => {
    if (e.status === 200) {
      setLoading(false);
      toast.success("successfully upload data");
      handleNext();
    } else {
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    assessmentYear: Yup.string().required(strings.validation_message.required),
    hufName: Yup.string().required(strings.validation_message.required),
    hufDateOfFormation: Yup.string().required(
      strings.validation_message.required
    )
    .matches(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/, strings.validation_message.invalid_pan),
    // /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/
    PAN: Yup.string()
      .required(strings.validation_message.required)
      .matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/, strings.validation_message.invalid_pan),
    address: Yup.object().shape({
      flatDoorNo: Yup.string().required(strings.validation_message.required),
      area: Yup.string().required(strings.validation_message.required),
      city: Yup.string().required(strings.validation_message.required),
      state: Yup.string().required(strings.validation_message.required),
      pinCode: Yup.string()
        .required(strings.validation_message.required)
        .matches(/^[1-9][0-9]{5}$/, strings.validation_message.invalid_pin),
      emailAddress: Yup.string()
        .required(strings.validation_message.required)
        .email(strings.validation_message.invalid_email),
    }),
    verification: Yup.object().shape({
      nameOfPersonFiling: Yup.string().matches(/^[A-Za-z ]+$/, "Invalid Name"),
      fatherNameOfPersonFiling: Yup.string().matches(
        /^[A-Za-z ]+$/,
        "Invalid Name"
      ),
      panOfPersonFiling: Yup.string()
      .matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/, strings.validation_message.invalid_pan),
      aadhaarOfPersonFiling: Yup.string().matches(
        /^[2-9]{1}[0-9]{3}-[0-9]{4}-[0-9]{4}$/,
        "Invalid Aadhaar card number format"
      )
    }),
  });

  const handleFormSubmit = async (data) => {
    let updateData = {
      ...data,
      mobileNo: data.mobileNo?.slice(data.countryCodeMobile.length),
      itrId: ItrData.id,
      address: {
        ...data.address,
        mobileNo: data.address.mobileNo?.slice(
          data.address?.countryCodeMobile?.length
        ),
      },
    };
    if (updateData?.aadhaarCardNo?.length > 12) {
      delete updateData.aadhaarEnrollmentNo;
    }
    setLoading(true);
    dispatch(
      setPersonalInfoAction(removeEmptyKeys(updateData), (e) => {
        if (e.status === 200) {
          setLoading(false);
          toast.success("successfully upload data");
          handleNext();
        } else {
          setLoading(false);
        }
      })
    );
  };

  // API : Get citi by state id
  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };

  const deleteMember = (id) => {
    const parm = `?itrId=${ItrData.id}&id=${id}`;
    setLoading(true);
    dispatch(
      deleteMemberAction(parm, (e) => {
        console.log("eee=== > ", e);
        if (e.status === 200) {
          setMembers(members.filter((item) => item.id !== id));
          setLoading(false);
          toast.success("Successfully delete member ");
        } else {
          setLoading(false);
        }
      })
    );
  };
  //  API : Get api for personal info
  const getPersonalInfoApi = () => {
    setLoading(true);
    dispatch(
      getPersonalInfoAction(ItrData.id, (e) => {
        let info = e?.data?.personalInfo;
        console.log("infoxxxx",info)
        setActiveState(info?.address?.state?.id || "")
        setFormData({
          assessmentYear: info?.assessmentYear || "",
          hufName: info?.hufName || "",
          hufDateOfFormation: info?.hufDateOfFormation || "",
          PAN: info?.PAN || "",
          email: info?.email,
          address: {
            street: info?.address?.street || "",
            area: info?.address?.area || "",
            city: info?.address?.city?.id || "",
            state: info?.address?.state?.id || "",
            country: "101",
            pinCode: info?.address?.pinCode || "",
            countryCodeMobile: info?.address?.countryCodeMobile || "+91",
            mobileNo: info?.address?.mobileNo
              ? info?.address?.countryCodeMobile + info?.address?.mobileNo
              : "",
            emailAddress: info?.address?.emailAddress || "",
            landlineNumber: info?.address?.landlineNumber || "",
            stdCodeLandlineNumber: info?.address?.stdCodeLandlineNumber || "",
            premiseName: info?.address?.premiseName || "",
            flatDoorNo: info?.address?.flatDoorNo || "",
          },
          verification: {
            nameOfPersonFiling: info?.verification?.nameOfPersonFiling || "",
            panOfPersonFiling: info?.verification?.panOfPersonFiling || "",
            fatherNameOfPersonFiling:
              info?.verification?.fatherNameOfPersonFiling || "",
            aadhaarOfPersonFiling:
              info?.verification?.aadhaarOfPersonFiling || "",
          },
        });
        if (e.status === 200) {
          setAssessmentYear(e.data.defaultAssessmentYear);
          setEmpCategorys(e.data.defaultEmpCategory);
          setMembers(e.data.personalInfo.members);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    getPersonalInfoApi();
  }, []);

  console.log("ItrData===4444>", members);

  return (
    <Root>
      <LoadingBox open={loading} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues, setFieldValue }) => (
          <div>
            <h4 className="mb-5" style={{ color: theme.primary_txt }}>
              {strings.tax.assessment_year} *
            </h4>

            <StyleFormBox>
              <Select
                name={"assessmentYear"}
                error={errors?.assessmentYear}
                value={values?.assessmentYear}
                onChange={handleChange}
              >
                <option value="" disabled>
                  {strings.label.choose_option}
                </option>
                {assessmentYear?.map((i, ix) => (
                  <option value={i.id} key={ix}>
                    {i.name}
                  </option>
                ))}
              </Select>
            </StyleFormBox>

            <AccordianBox
              title={strings.tax.permanent_information}
              description={
                strings.tax.please_provide_all_info_as_per_your_govt_id
              }
            >
              <div className="flex_input">
                <div>
                  <label>
                    {ItrData.userType == "3"
                      ? strings.personal_info.name_of_firm
                      : ItrData.userType == "4"
                      ? "Name of LLPs"
                      : ItrData.userType == "5"
                      ? "Name of Association of Persons (AOPs)"
                      : ItrData.userType == "6"
                      ? "Name of BOIs"
                      : ItrData.userType == "7"
                      ? "Name of Artificial Judicial Persons"
                      : ItrData.userType == "8"
                      ? "Name of Cooperative Societies"
                      : ItrData.userType == "9"
                      ? "Name of Local Authorities"
                      : ""}
                    *{" "}
                  </label>
                  <Input
                    name={"hufName"}
                    placeholder={strings.label.full_name}
                    error={errors.hufName}
                    value={values?.hufName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.personal_info.date_of_formation} *njkh</label>
                  <Input
                    name={"hufDateOfFormation"}
                    inputType={"date"}
                    error={errors.hufDateOfFormation}
                    value={values?.hufDateOfFormation}
                    format={"YYYY-MM-DD"}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.label.pan_number} *</label>
                  <Input
                    name={"PAN"}
                    placeholder={strings.placeholder.enter_10_digit}
                    error={errors.PAN}
                    value={values?.PAN}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setFieldValue("PAN", e.target.value.toUpperCase());
                      }
                    }}
                  />
                </div>
                <div></div>
              </div>
            </AccordianBox>

            {/* Form Identification & Contact details */}
            <AccordianBox
              title={strings.tax.your_address}
              description={
                strings.tax
                  .you_can_provide_either_your_current_address_or_permanent_address
              }
            >
              <div className="flex_input">
                <div>
                  <label>
                    {strings.address.flat} / {strings.address.door_no} /
                    {strings.address.block_no} *
                  </label>
                  <Input
                    name={"address.flatDoorNo"}
                    placeholder={`${strings.address.flat} / ${strings.address.door_no} / ${strings.address.block_no}`}
                    error={errors.address && errors.address.flatDoorNo}
                    value={values?.address && values?.address.flatDoorNo}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>
                    {strings.address.premise_name}{" "}
                    {strings.label.this_field_is_optional}
                  </label>
                  <Input
                    name={"address.premiseName"}
                    placeholder={strings.address.premise_name}
                    error={errors.address && errors.address.premiseName}
                    value={values?.address && values?.address.premiseName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>
                    {strings.address.road} / {strings.address.street}{" "}
                    {strings.label.this_field_is_optional}
                  </label>
                  <Input
                    name={"address.street"}
                    placeholder={`${strings.address.road} / ${strings.address.street}`}
                    error={errors.address && errors.address.street}
                    value={values?.address && values?.address.street}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.address.area_locality} *</label>
                  <Input
                    name={"address.area"}
                    placeholder={strings.address.area_locality}
                    error={errors.address && errors.address.area}
                    value={values?.address && values?.address.area}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.address.pincode} *</label>
                  <Input
                    type={"number"}
                    name={"address.pinCode"}
                    placeholder={strings.address.pincode}
                    error={errors.address && errors.address.pinCode}
                    value={values?.address && values?.address.pinCode}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.address.state} *</label>
                  <Select
                    name="address.state"
                    error={errors.address && errors.address.state}
                    value={values?.address && values?.address.state}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        address: {
                          ...values?.address,
                          state: e.target.value,
                          city: "",
                        },
                      });
                      setActiveState(Number(e.target.value));
                    }}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {states?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div>
                  <label>{strings.address.city} *</label>
                  <Select
                    name={"address.city"}
                    error={errors.address && errors.address.city}
                    value={values?.address && values?.address.city}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>
                    {citiesList?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.label.phone_number} *</label>
                  <Phone
                    name={"address.mobileNo"}
                    placeholder={strings.placeholder.enter_10_digit}
                    error={errors.address && errors.address.mobileNo}
                    value={values?.address && values?.address.mobileNo}
                    countryCode={values?.countryCodeMobile}
                    onChange={(phoneNumber, country) =>
                      setValues({
                        ...values,
                        address: {
                          ...values?.address,
                          mobileNo: phoneNumber.replace(/\D/g, ""),
                          countryCodeMobile: country.dialCode?.replace(
                            /\D/g,
                            ""
                          ),
                        },
                      })
                    }
                  />
                </div>
                <div>
                  <label>{strings.label.email} *</label>
                  <Input
                    name={"address.emailAddress"}
                    placeholder={strings.placeholder.email_address}
                    error={errors.address && errors.address.emailAddress}
                    value={values?.address && values?.address.emailAddress}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* <div className="flex_input">
                <div>
                  <label>{strings.label.secandary_email_address}</label>
                  <Input
                    name={"address.emailAddress"}
                    placeholder={strings.placeholder.email_address}
                    error={errors.address && errors.address.emailAddress}
                    value={values?.address && values?.address.emailAddress}
                    onChange={handleChange}
                  />
                </div>
                <div></div>
              </div> */}
            </AccordianBox>

            {/* Verification Information  */}
            <AccordianBox
              title={strings.personal_info.verification_information}
              description={
                strings.personal_info.enter_your_verification_information
              }
            >
              <div className="flex_input">
                <div>
                  <label>{strings.address.name_of_person_filing} </label>
                  <Input
                    name={"verification.nameOfPersonFiling"}
                    placeholder={strings.address.name_of_person_filing}
                    error={errors?.verification?.nameOfPersonFiling}
                    value={values?.verification?.nameOfPersonFiling}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.address.pan_no_of_person_filing}</label>
                  <Input
                    name={"verification.panOfPersonFiling"}
                    placeholder={strings.placeholder.enter_10_digit}
                    error={errors?.verification?.panOfPersonFiling}
                    value={values?.verification?.panOfPersonFiling}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setFieldValue("verification.panOfPersonFiling", e.target.value.toUpperCase());
                      }
                    }}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.label.aadhar_number} </label>
                  <Input
                    inputType={"aadhaar"}
                    name={"verification.aadhaarOfPersonFiling"}
                    placeholder={strings.label.aadhar_number}
                    error={errors?.verification?.aadhaarOfPersonFiling}
                    value={values?.verification?.aadhaarOfPersonFiling}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label>{strings.label.father_name} </label>
                  <Input
                    name={"verification.fatherNameOfPersonFiling"}
                    placeholder={strings.label.father_name}
                    error={errors?.verification?.fatherNameOfPersonFiling}
                    value={values?.verification?.fatherNameOfPersonFiling}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </AccordianBox>

            {/*  -------------- Members ---------------- */}
            <StyleFormBox>
              <h4>{strings.personal_info.members}</h4>
              <p>
                {
                  strings.tax
                    .you_can_provide_either_your_current_address_or_permanent_address
                }
              </p>
              {/* {members.} */}
              {members?.map((item, ix) => (
                <div
                  className="flex gap-2 p-3 my-3 align-center"
                  style={{
                    background: addOpacity(theme.primary_bg, 0.2),
                    borderRadius: "10px",
                  }}
                  key={ix}
                >
                  <h4 className="mr-auto">
                    {item.firstName + " " + item.lastName}{" "}
                  </h4>

                  <h4>{item?.sharePercentage} %</h4>
                  <div className="action_buttons">
                    <button
                      className="edit_btn"
                      onClick={() => {
                        setActiveMember(item.id);
                        setAddMemberBox(true);
                      }}
                    >
                      <FaRegEdit />
                    </button>
                    <button
                      className="delete_btn"
                      onClick={() => deleteMember(item.id)}
                    >
                      <RiDeleteBin6Fill />
                    </button>
                  </div>
                </div>
              ))}

              <div className="flex_input">
                <button className="secandary_button">
                  {strings.button.upload_file}
                </button>
                <button
                  className="secandary_button"
                  onClick={() => setAddMemberBox(true)}
                >
                  {strings.button.add_manually}
                </button>
              </div>
            </StyleFormBox>

            {/* ------------------ Additional Info  ------------- */}
            <StyleFormBox>
              <div className="flex justify-between items-center">
                <div>
                  <h4>{strings.address.additional_info}</h4>
                  <p>
                    {strings.personal_info.additional_info_about_partner_member}
                  </p>
                </div>
                <button
                  onClick={() => {
                    setAddAdditionalInfoBox(true);
                  }}
                  style={{ color: theme.primary_txt }}
                  className={
                    addAdditionalInfoBox == true
                      ? "dropdown_arrow active"
                      : "dropdown_arrow"
                  }
                >
                  <FaAngleDown />
                </button>
              </div>
              {addAdditionalInfoBox && <AdditionalInfo />}
            </StyleFormBox>

            {/* ----------------------- PAN Details ---------------- */}
            <AccordianBox
              title={strings.tax.pan_details}
              description={strings.tax.pan_linking_is_mandatory_requirement}
            >
              <div className="flex_input">
                <div>{strings.label.name}</div>
                <div>Rahul Singh</div>
              </div>
              <div className="flex_input">
                <div>{strings.label.date_of_birth}</div>
                <div>27/01/2000</div>
              </div>
              <div className="flex_input">
                <div>{strings.label.pan_number}</div>
                <div>DRPUTB755865GJ</div>
              </div>
              <div className="flex_input">
                <div>{strings.label.father_name}</div>
                <div>Mohan Lal</div>
              </div>
            </AccordianBox>

            {/* Form Link your PAN*/}
            <StyleFormBox>
              <div className="flex-1 flex items-center justify-between">
                <h4 style={{ color: theme.primary_txt }}>
                  {strings.tax.add_bank_details}
                </h4>

                <button
                  className="add_button"
                  onClick={() => {
                    setAddBankBox(true);
                  }}
                >
                  {strings.button.add}
                </button>
              </div>
            </StyleFormBox>

            <button className="primary_button mt-5" onClick={handleSubmit}>
              {strings.button.save_and_continue}
            </button>
            {/*  -------------------------- Add Members ======================== */}
            <FormDialogBox
              open={addMemberBox}
              formTitle={strings.personal_info.members}
              handleClose={() => {
                setAddMemberBox(false);
                setActiveMember();
              }}
            >
              <AddMembers
                memberId={activeMember}
                handleCallback={() => {
                  setAddMemberBox(false);
                  setActiveMember();
                  getPersonalInfoApi();
                }}
              />
            </FormDialogBox>
          </div>
        )}
      </Formik>

      {/* dialog box for adding bank details */}
      {/* <AddBankDetails
        open={addBankBox}
        handleClose={() => {
          setAddBankBox(false);
        }}
        handleCallback={() => {}}
      /> */}

      {/* <LoadingBox open={loading} />sss */}
    </Root>
  );
}
const Root = styled.section``;
