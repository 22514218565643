import React, { useCallback, useEffect, useState } from "react";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import { useDispatch, useSelector } from "react-redux";
import { addOpacity } from "../../../../utils/helpers";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import Select from "../../../../styledComponent/Inputs/Select";
import {
  deleteIncome44AD,
  deleteIncome44ADA,
  getProfessionalBusiness,
  getProfessionalBusiness3,
  saveIncome44AD,
  saveIncome44ADA,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import Income44AD from "./Income44AD";
import Section44AE from "./Section44AE";
import LoadingBox from "../../../DialogBox/LoadingBox";
import FinancialParticular from "./FinancialParticular";
import AddGst from "./AddGst";
import RegularBusiness from "./RegularBusiness";
import OtherBusinessIncome from "./OtherBusinessIncome";
import { useNavigate } from "react-router-dom";
import Income44ADA from "./Income44ADA";

export default function ProfessionalIncome() {
  const [professionalIncomeForm, setProfessionalIncomeForm] = useState(false);
  const [financialParticularForm, setFinancialParticularForm] = useState(false);
  const [gstFormBox, setGstFormBox] = useState(false);
  const [profTypeDisabled, setProfTypeDisabled] = useState(false);
  
  const [optionData, setOptionData] = useState({});
  const [activeOption, setActiveOption] = useState("");
  const [editFormData, setEditFormData] = useState({});
  const [edit44ADAFormData, set44ADAFormData] = useState({});
  const [edit44ADFormData, set44ADFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [professionalIncomeData, setProfessionalIncomeData] = useState([]);

  const { strings, theme, categories } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [propertyObj, setPropertyObj] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getProfessionalBusinessApi = () => {
    return new Promise((resolve, reject) => {
      let parm = `?itrId=${ItrData.id}`;
      dispatch(
        getProfessionalBusiness(parm, (e) => {
          if (e.status === 200) {
            console.log("BusinessApi 1============>", e.data.businessIncomeType);
            setOptionData((prev) => ({ ...prev, ...e.data }));
            setProfessionalIncomeData((prev) => ({
              ...prev,
              ...e.data.businessProfessionIncome,
            }));

            if (ItrData.form == "ITR_4") {
              setPropertyObj(
                e?.data?.businessIncomeType.filter(
                  (i) => i.id == 147 || i.id == 148 || i.id == 149
                )
              );
            } else if (ItrData.form == "ITR_3" || ItrData.form == "ITR_5") {
              setPropertyObj(
                e?.data?.businessIncomeType.filter(
                  (i) =>
                    i.id == 147 ||
                    i.id == 148 ||
                    i.id == 149 ||
                    i.id == 625 ||
                    i.id == 760
                )
              );
            } else if (ItrData.form == "ITR_6") {
              setPropertyObj(
                e?.data?.businessIncomeType.filter(
                  (i) => i.id == 149 || i.id == 625 || i.id == 760
                )
              );
            }
            resolve(e); // Resolve the promise when data is fetched successfully
          } else {
            toast.error(e.message);
            reject(e); // Reject the promise if there is an error
          }
        })
      );
    });
  };

  const getProfessionalBusiness3Api = () => {
    return new Promise((resolve, reject) => {
      let parm = `?itrId=${ItrData.id}`;
      dispatch(
        getProfessionalBusiness3(parm, (e) => {
          if (e.status === 200) {
            console.log("BusinessApi 2============>", e.data);
            setOptionData((prev) => ({ ...prev, ...e.data }));
            setProfessionalIncomeData((prev) => ({
              ...prev,
              ...e.data.businessProfessionIncome,
            }));
            // if (ItrData.form == "ITR_4") {
            //   setPropertyObj(
            //     e?.data?.businessIncomeType.filter(
            //       (i) => i.id == 147 || i.id == 148 || i.id == 149
            //     )
            //   );
            // } else if (ItrData.form == "ITR_3" || ItrData.form == "ITR_5") {
            //   setPropertyObj(
            //     e?.data?.businessIncomeType.filter(
            //       (i) =>
            //         i.id == 147 ||
            //         i.id == 148 ||
            //         i.id == 149 ||
            //         i.id == 625 ||
            //         i.id == 760
            //     )
            //   );
            // } else if (ItrData.form == "ITR_6") {
            //   setPropertyObj(
            //     e?.data?.businessIncomeType.filter(
            //       (i) => i.id == 149 || i.id == 625 || i.id == 760
            //     )
            //   );
            // }
            resolve(e); // Resolve the promise when data is fetched successfully
          } else {
            toast.error(e.message);
            reject(e); // Reject the promise if there is an error
          }
        })
      );
    });
  };

  const saveSection44ADAApi = async (value) => {
    setLoading(true);
    dispatch(
      saveIncome44ADA(value, (e) => {
        if (e.status == 200) {
          set44ADAFormData(e.data);
          setActiveOption({});
          setProfTypeDisabled(false);
          toast.success(
            `Successfully saved data`
          );
          // navigate(-1);
          // setProfessionalIncomeForm(false);
          // setLoading(false);
        } else {
          toast.error(e.message);
        }
        setLoading(false);
      })
    );
  };

  const saveSection44ADApi = async (value) => {
    setLoading(true);
    dispatch(
      saveIncome44AD(value, (e) => {
        console.log("saveSection44ADApi =========>", e);
        if (e.status == 200) {
          set44ADFormData(e.data);
          setActiveOption({});
          toast.success(
            `Successfully saved data`
          );
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteSection44ADAApi = async (id) => {
    setLoading(true);
    dispatch(
      deleteIncome44ADA({ id }, (e) => {
        if (e.status == 200) {
          setProfessionalIncomeData((prevData) => ({
            ...prevData,
            professionalIncome: null,
          }));
          setLoading(false);
        } else {
          // toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteSection44ADApi = async (id) => {
    setLoading(true);
    dispatch(
      deleteIncome44AD({ id }, (e) => {
        if (e.status == 200) {
          set44ADFormData({});
          setLoading(false);
        } else {
          // toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const updateFormData = useCallback(() => {
    console.log(professionalIncomeData);
    set44ADAFormData(professionalIncomeData?.professionalIncome || []);
    set44ADFormData(professionalIncomeData?.businessIncome || []);
  }, [professionalIncomeData]);

  // Usage in your component
  useEffect(() => {
    updateFormData();
  }, [updateFormData]);

  useEffect(() => {
    setLoading(true); // Show loader when the API calls start
    const fetchApis = async () => {
      try {
        // Build the array of promises conditionally
        const apiCalls =
          ItrData.form !== "ITR_4"
            ? [getProfessionalBusiness3Api()][getProfessionalBusinessApi()]
            : [getProfessionalBusinessApi()];

        await Promise.all(apiCalls); // Wait for the API call to resolve
      } catch (error) {
        console.error("Error fetching data from APIs:", error);
      } finally {
        setLoading(false); // Hide loader after the API call finishes
      }
    };

    fetchApis();
  }, [ItrData.form]);

  console.log("ItrData ========>", ItrData, edit44ADAFormData);

  return (
    <div className="mt-4 container_box">
      {loading ? (
        <LoadingBox open={loading} />
      ) : (
        <div>
          {/* ---------- Professional Income Under section 44ADA  ---------- */}
          {edit44ADAFormData?.id && (
            <div
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background:
                  activeOption == 147
                    ? addOpacity(theme.primary_bg, 0.8)
                    : addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <h4>Professional Income Under section 44ADA</h4>

                <p>Net Taxable Income: {edit44ADAFormData?.netTaxableIncome}</p>
              </div>

              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    setActiveOption(147);
                    setProfTypeDisabled(true);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() => {
                    deleteSection44ADAApi(
                      edit44ADAFormData?.netTaxableIncome?.id
                    );
                    setProfTypeDisabled(false);
                  }}
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          )}
          {/* ---------- Business Income Under section 44AD */}

          {edit44ADFormData?.id && (
            <div
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background:
                  activeOption == 148
                    ? addOpacity(theme.primary_bg, 0.8)
                    : addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <h4>Business Income Under section 44AD</h4>

                <p>
                  Total Gross Revenue: {edit44ADFormData?.totalGrossRevenue}
                </p>
              </div>

              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    setEditFormData(edit44ADFormData);
                    setActiveOption(148);
                    setProfTypeDisabled(true);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() => {
                    deleteSection44ADApi(edit44ADFormData?.id);
                    setProfTypeDisabled(false);
                  }}
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          )}

          <div>
            <h4>{strings.income.choose_income_type}</h4>
            <Select
              value={activeOption || ""}
              onChange={(e) => {
                setActiveOption(e.target.value);
              }}
              className={"mt-2"}
              disabled={profTypeDisabled}
            >
              <option value={""}>select an option</option>
              {propertyObj?.map((item, ix) => (
                <option value={item.id} key={ix}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>

          {activeOption == 147 ? (
            <Income44ADA
              formsData={edit44ADAFormData}
              professionType={optionData.professionTypes}
              handleCallback={(e) => {
                saveSection44ADAApi(e);
              }}
            />
          ) : activeOption == 148 ? (
            <Income44AD
              handleCallback={(e) => {
                saveSection44ADApi(e);
              }}
              formsData={edit44ADFormData}
              businessType={optionData.businessType}
              optionData={optionData}
            />
          ) : activeOption == 149 ? (
            <Section44AE
              data={optionData?.businessProfessionIncome?.businessDetail44AE}
              businessCode={optionData?.businessCodes}
              ownershipStatus={optionData?.ownershipStatus}
            />
          ) : activeOption == 625 ? (
            <RegularBusiness
              data={optionData?.professionalBusiness}
              businessType={optionData?.businessType}
              depreciationBlock={optionData?.depreciationBlock}
            />
          ) : activeOption == 760 ? (
            <OtherBusinessIncome />
          ) : (
            ""
          )}
          <button
            className="secandary_button"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Previous Page
          </button>
        </div>
      )}
    </div>
  );
}
