import React, { useEffect, useState } from "react";
import LoadingBox from "../../../DialogBox/LoadingBox";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Select from "../../../../styledComponent/Inputs/Select";
import Input from "../../../../styledComponent/Inputs/Input";
import {
  editDepreciationLossAction,
  saveDepreciationLossAction,
} from "../../../../redux/itr/action";
import { removeEmptyKeys } from "../../../../utils/helpers";
import { toast } from "react-toastify";

export default function DepreciationLoss({
  editData,
  AssessmentYear,
  handleCallback,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    assessmentYear: Yup.string().required(strings.validation_message.required),
    amount: Yup.number().required(strings.validation_message.required),
  });

  const handleSubmitForm = (data) => {
    const values = removeEmptyKeys(data);
    setLoader(true);
    const action = values.id
      ? editDepreciationLossAction
      : saveDepreciationLossAction;
    if(values.id){
      delete values.itrId;
    }
    dispatch(
      action(values, (e) => {
        handleCallback(values.id ? "edit" : "add", e);
        if (e.status === 200) {
          toast.success(`Successfully ${values.id ? "update" : "add"} data`);
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    setFormData({
      itrId: ItrData?.id,
      id: editData?.id || undefined,
      assessmentYear: editData?.assessmentYear || "",
      amount: editData?.amount || "",
    });
    setLoader(false);
  }, [editData]);

  return (
    <>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
        handleFormUpdate
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <StyleFormBox>
            <div className="flex_input mt-2">
              <label>{strings.tax_saving.assessment_year_loss}</label>
              <Select
                value={values.assessmentYear}
                error={errors.assessmentYear}
                name={"assessmentYear"}
                onChange={handleChange}
              >
                <option value={""}>{strings.label.choose_option}</option>
                {AssessmentYear?.map((i, ix) => (
                  <option value={i.id} key={ix}>
                    {i.name}
                  </option>
                ))}
              </Select>
            </div>

            <div className="flex_input mt-2">
              <div>
                <label>{strings.income.amount} *</label>
                <span className="note_message">
                  {strings.message.enter_negative_value}
                </span>
              </div>
              <Input
                inputType={"currency"}
                value={values.amount}
                error={errors.amount}
                name={"amount"}
                onChange={handleChange}
              />
            </div>

            <button className="primary_button mt-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </StyleFormBox>
        )}
      </Formik>
    </>
  );
}
