import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { Formik } from "formik";
import Input from "../../../../styledComponent/Inputs/Input";
import { removeBlankKey, removeEmptyKeys } from "../../../../utils/helpers";
import {
  editScheduleICDSAction,
  saveScheduleICDSAction,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";

export default function ScheduleICDS({ data, handleCallback }) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const { strings } = useSelector((state) => state.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();
  const handleSubmitForm = (values) => {
    setLoader(true);
    let { type, ...data } = values;
    const action =
      type == "edit" ? editScheduleICDSAction : saveScheduleICDSAction;
    if (type == "edit") {
      delete data.itrId;
    }
    dispatch(
      action(removeBlankKey(data), (e) => {
        if (e.status === 200) {
          handleCallback(type, e.data);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    setFormData({
      id: data?.id || "",
      type: data.id ? "edit" : "add",
      itrId: ItrData.id,
      accountPolicies: data?.accountPolicies || "",
      valuationOfInventories: data?.valuationOfInventories || "",
      constructionContracts: data?.constructionContracts || "",
      revenueRecognition: data?.revenueRecognition || "",
      tangibleFixedAssets: data?.tangibleFixedAssets || "",
      changesInForeignExchangeRates: data?.changesInForeignExchangeRates || "",
      governmentGrants: data?.governmentGrants || "",
      securities: data?.securities || "",
      borrowingCosts: data?.borrowingCosts || "",
      provisionsContingent: data?.provisionsContingent || "",
    });
    setLoader(false);
  }, [data]);
  return (
    <div>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
         
         
         
         <div>
            <div>
              <h4 className="my-4">
                {
                  strings.balance_sheet
                    .schedule_icds_effect_of_income_computation_disclosure_standards_on_profit
                }
              </h4>
              <div className="flex_input my-2">
                <label>{strings.balance_sheet.accounting_policies}</label>
                <Input
                  inputType={"currency"}
                  name={"accountPolicies"}
                  value={values?.accountPolicies}
                  error={errors?.accountPolicies}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.balance_sheet.valuation_of_inventories}</label>
                <Input
                  inputType={"currency"}
                  name={"valuationOfInventories"}
                  value={values?.valuationOfInventories}
                  error={errors?.valuationOfInventories}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.balance_sheet.construction_contracts}</label>
                <Input
                  inputType={"currency"}
                  name={"constructionContracts"}
                  value={values?.constructionContracts}
                  error={errors?.constructionContracts}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.balance_sheet.revenue_recognition}</label>
                <Input
                  inputType={"currency"}
                  name={"revenueRecognition"}
                  value={values?.revenueRecognition}
                  error={errors?.revenueRecognition}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.balance_sheet.tangible_fixed_assets}</label>
                <Input
                  inputType={"currency"}
                  name={"tangibleFixedAssets"}
                  value={values?.tangibleFixedAssets}
                  error={errors?.tangibleFixedAssets}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>
                  {strings.balance_sheet.changes_foreign_exchange_rates}
                </label>
                <Input
                  inputType={"currency"}
                  name={"changesInForeignExchangeRates"}
                  value={values?.changesInForeignExchangeRates}
                  error={errors?.changesInForeignExchangeRates}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.balance_sheet.government_grants}</label>
                <Input
                  inputType={"currency"}
                  name={"governmentGrants"}
                  value={values?.governmentGrants}
                  error={errors?.governmentGrants}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.balance_sheet.securities}</label>
                <Input
                  inputType={"currency"}
                  name={"securities"}
                  value={values?.securities}
                  error={errors?.securities}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.balance_sheet.borrowing_costs}</label>
                <Input
                  inputType={"currency"}
                  name={"borrowingCosts"}
                  value={values?.borrowingCosts}
                  error={errors?.borrowingCosts}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>
                  {
                    strings.balance_sheet
                      .provisions_contingent_liabilities_contingent_assets
                  }
                </label>
                <Input
                  inputType={"currency"}
                  name={"provisionsContingent"}
                  value={values?.provisionsContingent}
                  error={errors?.provisionsContingent}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button className="primary_button my-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
