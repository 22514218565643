import React, { useEffect, useState } from "react";
import Input from "../../../../styledComponent/Inputs/Input";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../styledComponent/Inputs/Select";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import { saveCompanyOrganizationAction } from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import { removeEmptyKeys } from "../../../../utils/helpers";
import LoadingBox from "../../../DialogBox/LoadingBox";
import * as Yup from 'yup';

export default function SubsidiaryCompany({ data, handleCallback }) {
  const [formData, setFormData] = useState({});
  const [citiesList, setCitiesList] = useState([]);
  const [activeState, setActiveState] = useState(1);
  const [loader, setLoader] = useState(true);
  const { strings, states } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };

  const handleFormSubmit = (value) => {
    setLoader(true);
    dispatch(
      saveCompanyOrganizationAction(value, (e) => {
        if (e.status == 200) {
          toast.success(
            `Data has been successfully ${value.id ? "updated" : "added"}`
          );
          handleCallback(value.id ? "edit" : "add", e.data);
          setLoader(false);
        } else {
          toast.error(e.message);
          setLoader(false);
        }
      })
    );
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    pan: Yup.string().required('PAN is required').matches(
      /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
      `${strings.validation_message.invalid_pan}`
    ),
    percentageShare: Yup.number().required('Percentage Share is required'),
    address: Yup.object().shape({
      flatDoorNo: Yup.string().required('Flat/Door number is required'),
      street: Yup.string().required('Street is required'),
      area: Yup.string().required('Area is required'),
      city: Yup.string().required('City is required'),
      state: Yup.number().required('State is required'),
      pinCode: Yup.string().required('Pin code is required').matches(
        /^[1-9][0-9]{5}$/, strings.validation_message.invalid_pin
      ),
    }),
    isSubsidary: Yup.boolean().required('Subsidiary status is required'),
  });


  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    setLoader(true)
    setFormData({
      itrId: ItrData.id,
      id: data?.id || "",
      name: data?.name || "",
      pan: data?.pan || "",
      percentageShare: data?.percentageShare || "",
      address: {
        flatDoorNo: data?.address?.flatDoorNo || "",
        premiseName: data?.address?.premiseName || "",
        street: data?.address?.street || "",
        area: data?.address?.area || "",
        city: data?.address?.city || '',
        state: data?.address?.state || 1,
        country: "101",
        pinCode: data?.address?.pinCode || "",
      },
      isSubsidary: true,
    });
    setActiveState(data?.address?.state || 1)
    setLoader(false)
  }, [data]);

  return (
    <div>
      <LoadingBox open={loader} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleFormSubmit(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues, setFieldValue }) => (
          <div>
            <div className="flex_input">
              <div>
                <label>{strings.label.full_name} *</label>
                <Input
                  name={"name"}
                  placeholder={strings.label.full_name}
                  error={errors.name}
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>{strings.address.percentage_stage} *</label>
                <Input
                  inputType={"percentage"}
                  name={`percentageShare`}
                  value={values.percentageShare}
                  error={errors?.percentageShare}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex_input">
              <div>
                <label>{strings.label.pan} *</label>
                <Input
                  name={"pan"}
                  className={"uppercase"}
                  placeholder={strings.placeholder.enter_10_digit}
                  error={errors.pan}
                  value={values.pan}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setFieldValue("pan", e.target.value.toUpperCase());
                    }
                  }}
                />
              </div>
              <div></div>
            </div>

            <h4>Address</h4>

            <div className="flex_input">
              <div>
                <label>
                  {strings.address.flat} / {strings.address.door_no} *
                </label>
                <Input
                  name={"address.flatDoorNo"}
                  placeholder={`${strings.address.flat} / ${strings.address.door_no}`}
                  error={errors.address && errors.address.flatDoorNo}
                  value={values.address && values.address.flatDoorNo}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>{strings.address.premise_name}</label>
                <Input
                  name={"address.premiseName"}
                  placeholder={strings.address.premise_name}
                  error={errors.address && errors.address.premiseName}
                  value={values.address && values.address.premiseName}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex_input">
              <div>
                <label>
                  {strings.address.road} / {strings.address.street}
                </label>
                <Input
                  name={"address.street"}
                  placeholder={`${strings.address.road} / ${strings.address.street}`}
                  error={errors.address && errors.address.street}
                  value={values.address && values.address.street}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>{strings.address.area_locality} *</label>
                <Input
                  name={"address.area"}
                  placeholder={strings.address.area_locality}
                  error={errors.address && errors.address.area}
                  value={values.address && values.address.area}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex_input">
              <div>
                <label>{strings.address.pincode} *</label>
                <Input
                  name={"address.pinCode"}
                  type={"number"}
                  placeholder={strings.address.pincode}
                  error={errors.address && errors.address.pinCode}
                  value={values.address && values.address.pinCode}
                  onChange={(e) => {
                    if (e.target.value.length < 7) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
              <div>
                <label>{strings.address.state} *</label>
                <Select
                  name="address.state"
                  error={errors.address && errors.address.state}
                  value={values.address && values.address.state}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      address: {
                        ...values.address,
                        state: e.target.value,
                          city:""
                      },
                    });
                    setActiveState(Number(e.target.value));
                  }}
                >
                  <option value="">{strings.label.choose_option}</option>
                  {states?.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="flex_input">
              <div>
                <label>{strings.address.city} *</label>
                <Select
                  name={"address.city"}
                  error={errors.address && errors.address.city}
                  value={values.address && values.address.city}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {citiesList?.map((i, ix) => (
                    <option value={i.id} key={ix}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div></div>
            </div>
            <button className="primary_button my-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
