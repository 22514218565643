import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import ToggelButton from "../../../../styledComponent/Button/ToggelButton";
import LoadingBox from "../../../DialogBox/LoadingBox";
import Input from "../../../../styledComponent/Inputs/Input";
import { FieldArray, Formik } from "formik";
import { RiDeleteBin6Fill } from "react-icons/ri";
import * as Yup from "yup";
import { removeEmptyKeys } from "../../../../utils/helpers";
import { toast } from "react-toastify";
import { saveOtherAssetsAction, updateOtherAssetsAction } from "../../../../redux/itr/action";

export default function AddOtherAssets({ handleCallback,editForm }) {

  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);

  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData} = useSelector((s) => s.itr);
  const dispatch = useDispatch()

  const validationSchema = Yup.object().shape({
    dateOfSale: Yup.string().required(strings.validation_message.required),
    dateOfPurchase: Yup.string().required(strings.validation_message.required),
    totalSalePrice: Yup.string().required(strings.validation_message.required),
    descriptionOfAssets: Yup.string().required(strings.validation_message.required),
    totalPurchasePrice: Yup.string().required(
      strings.validation_message.required
    ),
    improvementCost: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required(strings.validation_message.required),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
   
  });

  const handleSubmitForm = (values) => {
    if (values.id) {
      setLoader(true)
      dispatch(
        updateOtherAssetsAction(removeEmptyKeys(values), (e) => {
          if (e.status === 200) {
            toast.success('Successfully update data')
            setLoader(false)
            handleCallback(e.data);
          }else{
            setLoader(false)
          }
        })
      );
    } else {
      setLoader(true)
      let { id, ...newData } = values;
      dispatch(
        saveOtherAssetsAction(removeEmptyKeys(newData), (e) => {
          console.log(e);
          if (e.status === 200) {
            toast.success('Successfully upload data')
            setLoader(false)
            handleCallback(e.data);
          }else{
            setLoader(false)
          }
        })
      );
    }
  };

  
  useEffect(() => {
    setLoader(true);
    setFormData({
      itrId: ItrData.id,
      id: editForm?.id || "",
      dateOfSale: editForm?.dateOfSale?.split("T")[0] || "",
      dateOfPurchase: editForm?.dateOfPurchase?.split("T")[0] || "",
      descriptionOfAssets: editForm?.descriptionOfAssets || "",
      totalSalePrice: editForm?.totalSalePrice || "",
      transferExpenses: editForm?.transferExpenses || "",
      includeImprovementCost:editForm?.includeImprovementCost || false,
      totalPurchasePrice: editForm?.totalPurchasePrice || "",
      improvementCost: editForm?.improvementCost || [],
    });
    setLoader(false);
  }, [editForm]);

  return (
    <div>
     <LoadingBox open={loader} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
        handleFormUpdate
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setValues,
          setFieldValue,
        }) => (
          <div>
            <StyleFormBox>
              <h4>{strings.income.add_other_assets}</h4>
              <hr />

              <div className="flex_input my-2">
                <label>{strings.income.date_of_sale} *</label>
                <Input
                  inputType={"date"}
                  name={"dateOfSale"}
                  value={values.dateOfSale}
                  error={errors.dateOfSale}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.date_of_purchase} *</label>
                <Input
                  inputType={"date"}
                  name={"dateOfPurchase"}
                  value={values.dateOfPurchase}
                  error={errors.dateOfPurchase}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

            <StyleFormBox>
              <h4>{strings.income.add_details_asset}</h4>
              <hr />
              <div className="flex_input my-2">
                <label>{strings.income.description_of_asset_sold} *</label>
                <Input
                    name={"descriptionOfAssets"}
                    value={values.descriptionOfAssets}
                    error={errors.descriptionOfAssets}
                    onChange={handleChange}
                  />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.total_sale_price} *</label>
                <Input
                    inputType={"currency"}
                    name={"totalSalePrice"}
                    value={values.totalSalePrice}
                    error={errors.totalSalePrice}
                    onChange={handleChange}
                  />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.transfer_expenses}</label>
                <Input
                    inputType={"currency"}
                    name={"transferExpenses"}
                    value={values.transferExpenses}
                    error={errors.transferExpenses}
                    onChange={handleChange}
                  />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.total_purchase_price} *</label>
                <Input
                    inputType={"currency"}
                    name={"totalPurchasePrice"}
                    value={values.totalPurchasePrice}
                    error={errors.totalPurchasePrice}
                    onChange={handleChange}
                  />
              </div>
            </StyleFormBox>

         
            <StyleFormBox>
              <div className="flex_input my-2 justify-between">
                <h4>
                  {strings.income.do_you_want_add_cose_improvment_details}
                </h4>
                <ToggelButton
                  value={values.includeImprovementCost}
                  handleClick={() =>
                    setFieldValue(
                      "includeImprovementCost",
                      !values.includeImprovementCost
                    )
                  }
                />
              </div>
              <p>
                {strings.income.expenses_incurred_increasing_value_of_property}
              </p>

              {values.includeImprovementCost === true ? (
                <FieldArray name="improvementCost">
                  {({ insert, remove, push }) => (
                    <>
                      {values?.improvementCost?.map((item, index) => (
                        <StyleFormBox>
                          <div className="text-right">
                            <button
                              className="delete_button mb-2"
                              onClick={(e) => {
                                e.preventDefault();
                                remove(index);
                              }}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                          <div className="flex_input my-2">
                            <label>{strings.label.description} </label>
                            <Input
                              inputType={"message"}
                              name={`improvementCost.${index}.description`}
                              value={item.description}
                              error={
                                errors?.improvementCost &&
                                errors?.improvementCost[index]?.description
                              }
                              onChange={handleChange}
                            />
                          </div>

                          <div className="flex_input my-2">
                            <label>{strings.label.description} </label>
                            <Input
                              inputType={"currency"}
                              name={`improvementCost.${index}.amount`}
                              value={item.amount}
                              error={
                                errors?.improvementCost &&
                                errors?.improvementCost[index]?.amount
                              }
                              onChange={handleChange}
                            />
                          </div>
                        </StyleFormBox>
                      ))}

                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({
                              description: "",
                              amount: "",
                            });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </>
                  )}
                </FieldArray>
              ) : (
                ""
              )}
            </StyleFormBox>


            <button className="primary_button mt-4" onClick={handleSubmit}>
              {strings.button.submit}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
