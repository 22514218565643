import React, { useEffect, useState } from "react";
import SalaryIncome from "../../CommonScreens/GrossIncome/SalaryIncome";
import { useDispatch, useSelector } from "react-redux";
import {
  getGrossIncomeAction,
  getProfessionalBusiness,
} from "../../../redux/itr/action";
import { toast } from "react-toastify";
import { addOpacity } from "../../../utils/helpers";
import CapitalGainIncome from "../../CommonScreens/GrossIncome/CapitalGainIncome";

import OtherSourceIncome from "../../CommonScreens/GrossIncome/OtherSourceIncome";
import HousePropertyIncome from "../../CommonScreens/GrossIncome/HousePropertyIncome";
import styled from "styled-components";
import CryptoIncome from "../../CommonScreens/GrossIncome/CryptoIncome";
import CompLoadingBox from "../../DialogBox/CompLoadingBox";
import LoadingBox from "../../DialogBox/LoadingBox";

export default function GrossIncomeNew({ handleNext }) {
  const [optionData, setOptionData] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const [incomeData, setIncomeData] = useState({});
  const { theme } = useSelector((state) => state.platformSetting);

  const { strings, categories } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const getProfessionalBusinessApi = async () => {
    setLoadingData(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness(parm, (e) => {
        if (e.status == 200) {
          setOptionData(e.data);
          setLoadingData(false);
        } else {
          toast.error(e.message);
          setLoadingData(false);
        }
      })
    );
  };

  const getGrossIncomeApi = () => {
    setLoadingData(true);
    dispatch(
      getGrossIncomeAction(ItrData.id, (data) => {
        if (data.status == 200) {
          setIncomeData(data.data.grossIncomeDetails);
        }
        console.log("==========================");
        setLoadingData(false);
      })
    );
  };

  useEffect(() => {
    getGrossIncomeApi();
    // getProfessionalBusinessApi();
  }, []);

  return (
    <Root theme={theme}>
      <LoadingBox open={loadingData} />
      <SalaryIncome
        getData={incomeData?.grossSalary}
        handleNext={() => handleNext()}
        handleClick={() => {}}
        handleRefresh={() => getGrossIncomeApi()}
      />

      {incomeData && incomeData?.grossSalary && (
        <div className="box_heading">
          <h4 style={{ color: theme.primary_txt }}> Gross Salary</h4>
          <div>
            <h4
              style={{ color: theme.primary_txt }}
            >{`${strings.label.rupee} ${incomeData?.grossSalary}`}</h4>
          </div>
        </div>
      )}

      <HousePropertyIncome data={incomeData?.housePropertyIncome} />
      <CapitalGainIncome />
      <CryptoIncome />
      <OtherSourceIncome
        getData={incomeData?.otherIncomeSource}
        handleRefresh={() => getGrossIncomeApi()}
      />

      <button
        type="submit"
        className="primary_button mt-4"
        onClick={handleNext}
      >
        {strings.button.next}
      </button>
    </Root>
  );
}

const Root = styled.div`
  .box_heading {
    display: flex;
    width: 100%;
    background-color: ${(p) => p.theme.secandary_bg};
    gap: 18px;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    cursor: pointer;
    button {
      background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.5)};
      border-radius: 50%;
      padding: 6px;
      border: none;
      font-size: 16px;
      transition: all 0.5s;
      &.active {
        transform: rotate(180deg);
      }
    }
  }
`;
