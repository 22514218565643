import React, { useEffect, useState } from "react";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import Input from "../../../../styledComponent/Inputs/Input";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../styledComponent/Inputs/Select";
import * as Yup from "yup";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import { toast } from "react-toastify";
import { saveOwnerAndShareHolderAction } from "../../../../redux/itr/action";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { removeEmptyKeys } from "../../../../utils/helpers";

export default function CompanyOwner({ data, handleCallback }) {
  const [formData, setFormData] = useState({});
  const [citiesList, setCitiesList] = useState([]);
  const [activeState, setActiveState] = useState(1);
  const [loader, setLoader] = useState(true);
  const { strings, states } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();
  // API : Get citi by state id
  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };

  const handleFormSubmit = (value) => {
    setLoader(true);
    dispatch(
      saveOwnerAndShareHolderAction(value, (e) => {
        if (e.status == 200) {
          handleCallback(value.id ? "edit" : "add", e.data);
          toast.success(
            `Data has been successfully ${value.id ? "updated" : "added"}`
          );
        } else {
          toast.error(e.message);
        }
        setLoader(false);
      })
    );
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    percentageShare: Yup.number().required("Percentage Share is required"),
    pan: Yup.string().required("PAN is required")
      .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, strings.validation_message.invalid_pan),
    isOwner: Yup.boolean().required("Ownership status is required"),
    address: Yup.object().shape({
      flatDoorNo: Yup.string().required("Flat/Door number is required"),
      street: Yup.string().required("Street is required"),
      area: Yup.string().required("Area is required"),
      city: Yup.string().required("City is required"),
      state: Yup.number().required("State is required"),
      pinCode: Yup.string().required("Pin code is required").matches(
        /^[1-9][0-9]{5}$/, strings.validation_message.invalid_pin
      ),
    }),
  });

  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    setLoader(true);
    setFormData({
      itrId: ItrData.id,
      id: data?.id || "",
      fullName: data?.fullName || "",
      percentageShare: data?.percentageShare || "",
      pan: data?.pan || "",
      isOwner: true,
      address: {
        flatDoorNo: data?.address?.flatDoorNo || "",
        premiseName: data?.address?.premiseName || "",
        street: data?.address?.street || "",
        area: data?.address?.area || "",
        city: data?.address?.city || "",
        state: data?.address?.state || 1,
        country: "101",
        pinCode: data?.address?.pinCode || "",
      },
    });
    setLoader(false);
  }, [data]);
  return (
    <div>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleFormSubmit(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues, setFieldValue }) => (
          <div>
            <p className="mt-2">
              In case of unlisted company, particulars of natural persons who
              were the ultimate beneficial owners, directly or indirectly, of
              shares holding not less than 10% of the voting power at any time
              of the previous year.
            </p>
            <AccordianBox title={"Basic Information"}>
              <div className="flex_input">
                <div>
                  <label>{strings.label.full_name}  *</label>
                  <Input
                    name={"fullName"}
                    placeholder={strings.label.full_name}
                    error={errors.fullName}
                    value={values.fullName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.address.percentage_stage} *</label>
                  <Input
                    inputType={"percentage"}
                    name={`percentageShare`}
                    value={values.percentageShare}
                    error={errors?.percentageShare}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex_input">
                <div>
                  <label> PAN *</label>
                  <Input
                    name={"pan"}
                    className={"uppercase"}
                    placeholder={strings.placeholder.enter_10_digit}
                    error={errors.pan}
                    value={values.pan}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setFieldValue("pan", e.target.value.toUpperCase());
                      }
                    }}
                  />
                </div>
                <div></div>
              </div>
            </AccordianBox>

            <AccordianBox title={"Shareholder Address"}>
              <div className="flex_input">
                <div>
                  <label>
                    {strings.address.flat} / {strings.address.door_no} *
                  </label>
                  <Input
                    name={"address.flatDoorNo"}
                    placeholder={`${strings.address.flat} / ${strings.address.door_no}`}
                    error={errors.address && errors.address.flatDoorNo}
                    value={values.address && values.address.flatDoorNo}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.address.premise_name}</label>
                  <Input
                    name={"address.premiseName"}
                    placeholder={strings.address.premise_name}
                    error={errors.address && errors.address.premiseName}
                    value={values.address && values.address.premiseName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>
                    {strings.address.road} / {strings.address.street}
                  </label>
                  <Input
                    name={"address.street"}
                    placeholder={`${strings.address.road} / ${strings.address.street}`}
                    error={errors.address && errors.address.street}
                    value={values.address && values.address.street}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.address.area_locality} *</label>
                  <Input
                    name={"address.area"}
                    placeholder={strings.address.area_locality}
                    error={errors.address && errors.address.area}
                    value={values.address && values.address.area}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.address.pincode} *</label>
                  <Input
                    name={"address.pinCode"}
                    type={"number"}
                    placeholder={strings.address.pincode}
                    error={errors.address && errors.address.pinCode}
                    value={values.address && values.address.pinCode}
                    onChange={(e) => {
                      if (e.target.value.length < 7) {
                        handleChange(e);
                      }
                    }}
                  />
                </div>
                <div>
                  <label>{strings.address.state} *</label>
                  <Select
                    name="address.state"
                    error={errors.address && errors.address.state}
                    value={values.address && values.address.state}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        address: {
                          ...values.address,
                          state: e.target.value,
                          city: "",
                        },
                      });
                      setActiveState(Number(e.target.value));
                    }}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {states?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.address.city} *</label>
                  <Select
                    name={"address.city"}
                    error={errors.address && errors.address.city}
                    value={values.address && values.address.city}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>
                    {citiesList?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div></div>
              </div>
            </AccordianBox>

            <button className="primary_button my-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
